import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useSelectedAzienda } from "../../SelectedAzienda"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import Layout from "../../components/Layout/Layout"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import { VisiteNoPaginationState, VisiteState } from "../../state/visite"
import { useRunRj } from "react-rocketjump"
import "react-big-calendar/lib/css/react-big-calendar.css"
import ModalFormVisita from "../../components/Visite/ModalFormVisita"
import { ClienteBigState } from "../../state/clienti"
import { UtenteState } from "../../state/utenti"
import { useHistory } from "react-router"
import { MenuItem, Select, Card, FormControl, Grid, InputLabel, CircularProgress } from "@material-ui/core"

const eventsColorsMappingList = [
  {'color': "#64b5f6", "textColor": "#000000"},{'color': "#ffd54f", "textColor": "#000000"}, //blue, amber
  {'color': "#ef9a9a", "textColor": "#000000"},{'color': "#ce93d8", "textColor": "#000000"}, //red, purple
  {'color': "#e6ee9c", "textColor": "#000000"},{'color': "#9fa8da", "textColor": "#000000"}, //lime, indigo
] //To get users color index, user_id % len(colorsList)

const localizer = momentLocalizer(moment)

export default function Calendario() {  
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()
  

  const history = useHistory()

  const filters = useMemo(() => {
    return { azienda: selectedAzienda.id }
  }, [selectedAzienda])

  const [userID, setUserID] = React.useState('-1');

  const visitFilters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id}
  }, [debQueryParams, selectedAzienda])

  const [modalCreate, actionsModalCreate] = useModalTrigger()

  const [{ list }, { addVisita } ] = useRunRj(
    VisiteNoPaginationState,
    [visitFilters],
    false
  )

  const [{ list: clienti }] = useRunRj(ClienteBigState, [filters])
  
  const visitsEventList = useMemo(() => {
    if (!list || list.length === 0) {
      return []
    }
    return list.map((d) => ({
      start: new Date(d.data + "T" + d.ora_inizio),
      end: moment(d.data + "T" + d.ora_inizio)
        .add(d.durata, "m")
        .toDate(),
      id: d.id,
      title: d.cliente_data.nome + " " + d.cliente_data.cognome,
      user_id: ((d.user_data != null) ? d.user_data.id : -1) //If user is present provide user id otherwise return a defualt value
    }))
  }, [list])

  const today = new Date()

  const eventStyleGetter = (event) => {
    let backgroundColor = "#004256" //Default color
    let textColor = "#ffff"
    if (event.user_id >= 0){
      let color_id = event.user_id % eventsColorsMappingList.length
      backgroundColor = eventsColorsMappingList[color_id].color
      textColor = eventsColorsMappingList[color_id].textColor
    }
    return { style: { 
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        color: textColor
      } 
    }
  }

  //Retrieve users
  const [
    { list: usersList, loading, pagination, allCount },
    { addUtente, updateUtente, removeUtente, run: fetchUtenti },
  ] = useRunRj(UtenteState, [filters])

  
  const handleFilterUserChange = (event) => {
    setUserID(event.target.value);

    if (event.target.value >= 0){ //Valid user id 
      console.log("Setting query params")
      setDebQueryParams({
        user: event.target.value,
      })
    }else{
      //We want all users' company
      setQueryParams(() => {})
    }
  };

  return (
    <Layout title="Calendario">
      <div>
      {/* {visitsLoading && (
          <div className="visits-loading-popup">
            <CircularProgress color="primary" />
          </div>
      )} */}
      <Card className="card-filters">
        <InputLabel>Utente</InputLabel>
        <Select label="User" value={userID} onChange={handleFilterUserChange}>
          {usersList === null && loading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="primary" />
              </div>
          )}
          {usersList && 
            usersList.map((utente) => (
              <MenuItem value={utente.id}><span class="dot" style={{backgroundColor: eventsColorsMappingList[utente.id % eventsColorsMappingList.length].color}}></span> &nbsp; {utente.name}</MenuItem>
            ))
          }
          <MenuItem value={-1}><span class="dot" style={{backgroundColor: 'black'}}></span> &nbsp; {'Tutti'}</MenuItem>
        </Select>
      </Card>
      </div>
      <Calendar
        views={["month", "day", "week"]}
        localizer={localizer}
        events={visitsEventList}
        onSelectEvent={(e) => {
          history.push(`/visite/${e.id}`)
        }}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
        }
        // end time 5:00pm
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23)
        }
        eventPropGetter={eventStyleGetter}
        popup={true}
        startAccessor="start"
        messages={{
          next: "Successivo",
          previous: "Precedente",
          today: "Oggi",
          month: "Mese",
          week: "Settimana",
          day: "Giorno",
          showMore: (number) => "+ " + number + " visite",
        }}
        slot={15}
        defaultView={"week"}
        selectable={true}
        onSelectSlot={(e) => {
          actionsModalCreate.open(e)
        }}
        endAccessor="end"
        style={{ height: 600 }}
      />
      <ModalFormVisita
        save={(visita) => {
          return addVisita
            .onSuccess((visita) => {
              actionsModalCreate.close()
            })
            .asPromise(visita)
        }}
        slotSelected={modalCreate.value}
        isOpen={modalCreate.isOpen}
        clienti={clienti}
        toggle={actionsModalCreate.toggle}
        onExited={actionsModalCreate.onClosed}
      />
    </Layout>
  )
}
