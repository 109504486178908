import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"

const INITIAL_VALUES = {
  linea: "",
  categoria: "",
  prodotto: "",
  quantita: "",
  giacenza: "",
  costo: "",
  rivendita: "",
  in_uso: false,
}

const ProdottoSchema = Yup.object().shape({
  prodotto: Yup.string().required("Prodotto è obbligatorio"),
})

export default function ModalFormProdotto({
  prodotto,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {prodotto ? "Modifica" : "Inserimento"} prodotto
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={fixInitialValuesNull(prodotto) ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Field label={"Linea"} name="linea" component={InputField} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label={"Categoria"}
                    name="categoria"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Prodotto"
                    type="text"
                    name="prodotto"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Formato"
                    type="text"
                    name="quantita"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Giacenza"
                    type="text"
                    name="giacenza"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Costo"
                    type="text"
                    name="costo"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Prezzo Rivendita"
                    type="text"
                    name="rivendita"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="In Uso"
                    component={SwitchCheckboxField}
                    name="in_uso"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {prodotto ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
