import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import * as Yup from "yup";
import { Save } from "@material-ui/icons";
import { Field, Formik } from "formik";
import {
  InputField,
  SwitchCheckboxField,
  transformErrorForForm,
} from "../../components/Form";
import { PianiTariffariState } from "../../state/pianiTariffari";
import { useRj, useRunRj } from "react-rocketjump";
import { RegistrazioneAziendaState } from "../../state/registrazioneAzienda";
import { getStripe } from "../../utils";
import { useAuthActions } from "use-eazy-auth";
import SceltaPianoTariffario from "../../components/SceltaPianoTariffario";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const INITIAL_VALUES = {
  azienda: {
    p_iva: "",
    rag_sociale: "",
    city: "",
    provincia: "",
    civico: "",
    stripe_price_id: "",
    indirizzo: "",
    cap: "",
    pec: "",
    codice_fiscale: "",
    codice_fatturazione: "",
    codice_invito: "",
  },
  user: {
    name: "",
    password: "",
    conferma_password: "",
    email: "",
  },
  accetto_condizioni: false,
};

const AziendaRegisterSchema = Yup.object().shape({
  azienda: Yup.object().shape({
    p_iva: Yup.string().required("Campo obbligatorio"),
    rag_sociale: Yup.string().required("Campo obbligatorio"),
    city: Yup.string().required("Campo obbligatorio"),
    indirizzo: Yup.string().required("Campo obbligatorio"),
    cap: Yup.string().required("Campo obbligatorio"),
    provincia: Yup.string().required("Campo obbligatorio"),
    codice_fiscale: Yup.string().required("Campo obbligatorio"),
    civico: Yup.string().required("Campo obbligatorio"),
    stripe_price_id: Yup.string().required("Campo obbligatorio"),
    pec: Yup.string().test(function (value) {
      const { codice_fatturazione } = this.parent;
      if (!codice_fatturazione) return value != null;
      return true;
    }),
    codice_fatturazione: Yup.string().test(function (value) {
      const { pec } = this.parent;
      if (!pec) return value != null;
      return true;
    }),
  }),
  user: Yup.object().shape({
    name: Yup.string().required("Campo obbligatorio"),
    email: Yup.string().required("Campo obbligatorio"),
    password: Yup.string().required("Password obbligatoria"),
    conferma_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Le due password devono essere identiche"
    ),
  }),
});

export default function RegistrazioneAzienda() {
  const { setTokens } = useAuthActions();
  const [{ data: piani }] = useRunRj(PianiTariffariState);
  const [loader, setLoader] = useState(false);

  const [, { run: registerAzienda }] = useRj(RegistrazioneAziendaState);

  return (
    <>
      {loader && (
        <Dialog fullWidth open={loader}>
          <DialogTitle>Reindirizzamento al Pagamento</DialogTitle>
          <DialogContent>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
            <div style={{ marginTop: 10 }}>
              Vi stiamo reindirizzando al pagamento per l'applicativo
              Salonify...
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
      {!isMobile && (
        <img
          src="/registrazione-sfondo.png"
          alt="Registrazioen"
          className="registrazione-sfondo"
        />
      )}
      <Card
        style={{
          margin: isMobile ? 0 : "auto",
          position: !isMobile ? "absolute" : undefined,
          top: !isMobile ? "5%" : undefined,
          left: !isMobile ? 0 : undefined,
          right: !isMobile ? 0 : undefined,
          width: isMobile ? "100%" : 850,
          borderRadius: isMobile ? 0 : 4,
          display: "flex",
          height: isMobile ? "100%" : undefined,
          flexDirection: "column",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <CardHeader
          className="card-login"
          title={
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img
                  height={isMobile ? 30 : 40}
                  src="/salonify.png"
                  alt="Salonify"
                />
              </Link>
            </div>
          }
        />
        <CardContent>
          <Formik
            validationSchema={AziendaRegisterSchema}
            initialValues={INITIAL_VALUES}
            onSubmit={(values, actions) => {
              setLoader(true);
              return registerAzienda
                .onSuccess((response) => {
                  setTokens({
                    accessToken: response.access,
                    refreshToken: response.refresh,
                  });
                  getStripe().then((stripe) => {
                    stripe.redirectToCheckout({
                      sessionId: response.checkout_session,
                    });
                  });
                })
                .onFailure(() => setLoader(false))
                .asPromise(values)
                .catch((err) => {
                  actions.setErrors(transformErrorForForm(err));
                });
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              setFieldValue,
              handleReset,
              errors,
              touched,
              isValid,
            }) => (
              <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                <Card style={{ paddingBottom: 0 }}>
                  <CardHeader
                    className="card-login"
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    title="Registrazione Azienda"
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Partita Iva"}
                          name="azienda.p_iva"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Ragione sociale"}
                          name="azienda.rag_sociale"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Codice fiscale"}
                          name="azienda.codice_fiscale"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Indirizzo"}
                          name="azienda.indirizzo"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={1} xs={12}>
                        <Field
                          label={"N°"}
                          name="azienda.civico"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Field
                          label={"Città"}
                          name="azienda.city"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Field
                          label={"Cap"}
                          name="azienda.cap"
                          noHelperText
                          size="small"
                          component={InputField}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Field
                          label={"Provincia"}
                          name="azienda.provincia"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Pec"}
                          name="azienda.pec"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Codice fatturazione"}
                          name="azienda.codice_fatturazione"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          label={"Codice invito"}
                          name="azienda.codice_invito"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Card style={{ paddingBottom: 0 }}>
                  <CardHeader
                    className="card-login"
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    title="Dati Personali"
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={"Nome e Cognome"}
                          name="user.name"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={"Email"}
                          name="user.email"
                          noHelperText
                          size="small"
                          component={InputField}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={"Password"}
                          type="password"
                          name="user.password"
                          size="small"
                          component={InputField}
                          noHelperText
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={"Conferma Password"}
                          type="password"
                          name="user.conferma_password"
                          size="small"
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    className="card-login"
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    title="Scegli Abbonamento"
                  />
                  <CardContent style={{ paddingBottom: 0 }}>
                    <Field
                      name={"azienda.stripe_price_id"}
                      component={SceltaPianoTariffario}
                      piani={piani}
                    />
                  </CardContent>
                </Card>
                <div
                  style={{
                    display: isMobile ? undefined : "flex",
                    justifyContent: isMobile ? undefined : "center",
                  }}
                >
                  <Grid item md={12} xs={12}>
                    <Field
                      label={
                        <span>
                          {"Registrandomi, accetto le "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://platform.salonify.org/termini-e-condizioni"
                          >
                            condizioni d'uso
                          </a>
                          {" e l'"}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://platform.salonify.org/privacy-terms"
                          >
                            informativa sulla privacy
                          </a>
                          .
                        </span>
                      }
                      component={SwitchCheckboxField}
                      name="accetto_condizioni"
                    />
                  </Grid>
                  <Button
                    style={{
                      marginTop: isMobile ? 15 : undefined,
                      textAlign: isMobile ? "center" : undefined,
                      width: isMobile ? "100%" : undefined,
                    }}
                    size="large"
                    disabled={isSubmitting || !values.accetto_condizioni}
                    type="submit"
                    color="primary"
                    startIcon={<Save />}
                    variant="contained"
                  >
                    Registrati
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
}
