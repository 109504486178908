import { scaleLinear } from "d3-scale"
import { area, curveNatural, line } from "d3-shape"
import { range } from "lodash-es"
import React, { useCallback, useMemo, useState } from "react"
import defaultTheme from "../theme"
import { useResizeDetector } from "react-resize-detector"
import { orderBy } from "lodash"

const labels = "GEN,FEB,MAR,APR,MAG,GIU,LUG,AGO,SET,OTT,NOV,DIC".split(",")
const longLabels = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
]

const padding = {
  top: 20,
  left: 58,
  right: 48,
  bottom: 20,
}

function getTypeLabel(value, max) {
  if (max > 3000) {
    return (value / 1000).toFixed(1) + "k"
  } else return value.toFixed(0) + " €"
}

const height = 250

function ChartRicavoAnnualeImpl({ data: rawData }) {
  const dataOrdered = rawData && orderBy(rawData,'data__month')
  const data = useMemo(() => {
    return dataOrdered.map((datum, i) => ({
      x: i,
      label: labels[parseInt(datum.data__month, 10) - 1],
      longLabel: longLabels[parseInt(datum.data__month, 10) - 1],
      incasso: datum.tot,
      visite: datum.num ? datum.num : 0,
    }))
  }, [dataOrdered])

  const { width, ref } = useResizeDetector()

  const chartViewportWidth = width - padding.left - padding.right
  const chartViewportHeight = height - padding.top - padding.bottom

  const xScale = scaleLinear()
    .domain([
      Math.min(...data.map((item) => item.x)),
      Math.max(...data.map((item) => item.x)),
    ])
    .range([padding.left, chartViewportWidth + padding.left])

  const earningScale = scaleLinear()
    .domain([0, Math.max(...data.map((item) => item.incasso))])
    .nice(6)
    .range([chartViewportHeight + padding.top, padding.top])

  const visitScale = scaleLinear()
    .domain([0, Math.max(...data.map((item) => item.visite ?? 0))])
    .nice(6)
    .range([chartViewportHeight + padding.top, padding.top])

  const valueLineGenerator = line()
    .x((d) => xScale(d.x))
    .y((d) => earningScale(d.incasso))
    .curve(curveNatural)
  const valueLine = valueLineGenerator(data)

  const valueAreaGenerator = area()
    .x((d) => xScale(d.x))
    .y0(() => chartViewportHeight + padding.top)
    .y1((d) => earningScale(d.incasso))
    .curve(curveNatural)
  const valueArea = valueAreaGenerator(data)

  const [tooltip, setTooltip] = useState({
    point: -1,
    visible: false,
    x: 0,
    y: 0,
  })

  const onMouseMove = useCallback(
    (e) => {
      const x = e.nativeEvent.offsetX
      const y = e.nativeEvent.offsetY
      const point = xScale.invert(x)
      if (point >= 0 && point < data.length - 0.5) {
        setTooltip({
          visible: true,
          point: Math.round(point),
          x,
          y,
        })
      } else {
        setTooltip({
          visible: false,
          point: -1,
          x,
          y,
        })
      }
    },
    [xScale, data]
  )

  const onMouseLeave = useCallback((e) => {
    if (e.target.nodeName.toUpperCase() === "SVG") {
      setTooltip({
        point: -1,
        visible: false,
        x: 0,
        y: 0,
      })
    }
  }, [])

  const tooltipFormatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  })

  const maxIncasso = Math.max(...data.map((item) => item.incasso))

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <svg
        width={width}
        height={height}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseLeave}
      >
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0"
              style={{
                stopColor: defaultTheme.palette.primary.main,
                stopOpacity: 0.4,
              }}
            />
            <stop
              offset="1"
              style={{
                stopColor: defaultTheme.palette.primary.main,
                stopOpacity: 0.1,
              }}
            />
          </linearGradient>
        </defs>
        {range(7).map((n) => {
          const value = (earningScale.domain()[1] / 6) * n
          return (
            <React.Fragment key={value}>
              <text
                x={16}
                y={earningScale(value)}
                fill={"#333333"}
                textAnchor="start"
                alignmentBaseline="central"
              >
                {getTypeLabel(value, maxIncasso)}
              </text>
              <line
                x1={padding.left}
                x2={padding.left + chartViewportWidth}
                y1={earningScale(value)}
                y2={earningScale(value)}
                stroke={"#EEEEEE"}
                strokeWidth={1}
              />
            </React.Fragment>
          )
        })}
        {range(7).map((n) => {
          const value = (visitScale.domain()[1] / 6) * n
          return (
            <React.Fragment key={value}>
              <text
                x={padding.left + chartViewportWidth + padding.right - 16}
                y={visitScale(value)}
                fill={"#333333"}
                textAnchor="end"
                alignmentBaseline="central"
              >
                {value.toFixed(1)}
              </text>
            </React.Fragment>
          )
        })}
        {data.map((datum, i) => (
          <text
            key={i}
            x={xScale(i)}
            y={padding.top + chartViewportHeight + 7}
            fill={"#333333"}
            textAnchor="middle"
            alignmentBaseline="hanging"
          >
            {datum.label}
          </text>
        ))}
        <path d={valueArea} fill="url(#grad)" />
        <path
          d={valueLine}
          stroke={defaultTheme.palette.secondary.main}
          strokeWidth={2}
          fill="transparent"
        />
        {data
          .filter((item) => item.visite)
          .map((datum) => (
            <circle
              key={datum.x}
              cx={xScale(datum.x)}
              cy={visitScale(datum.visite)}
              r={3}
              fill={defaultTheme.palette.primary.main}
            />
          ))}
      </svg>
      <div
        style={{
          position: "absolute",
          top: tooltip.y < height / 2 ? tooltip.y + 10 : tooltip.y - 90,
          left: tooltip.x < width / 2 ? tooltip.x + 10 : tooltip.x - 150,
          display: tooltip.visible ? "block" : "none",
          background: "white",
          border: "1px solid #cccccc",
          padding: 10,
          pointerEvents: "none",
          transition: "top 100ms, left 100ms",
        }}
      >
        {tooltip.point >= 0 && (
          <>
            <b>{data[tooltip.point].longLabel}</b>
            <br />
            <span>Visite: </span>
            <span>{data[tooltip.point].visite}</span>
            <br />
            <span>Incasso: </span>
            <span>{tooltipFormatter.format(data[tooltip.point].incasso)}</span>
            <br />
          </>
        )}
      </div>
    </div>
  )
}

export default function ChartRicavoAnnuale({ data, ...props }) {
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        Non hai abbastanza dati per visualizzare il grafico.
      </div>
    )
  } else {
    return <ChartRicavoAnnualeImpl data={data} {...props} />
  }
}
