
import { rj, useRj } from "react-rocketjump"
import api from "../api"

const PasswordRecoverRj = rj({
  effect: email => {
    return api
      .post("/api/recover-password/", { email })
  }
})

const PasswordResetRj = rj({
  effect: (token, password) => {
    return api
      .post("/api/reset-password/"+token, { password })
  }
})

export function usePasswordRecover() {
  return useRj(PasswordRecoverRj)
}

export function usePasswordReset() {
  return useRj(PasswordResetRj)
}