import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const AziendaState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeAzienda: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/aziende/${entity.id}`),
        updater: 'deleteItem',
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addAzienda: {
        effect: (t) => (entity) => api.auth(t).post('/api/aziende', entity),
        updater: 'insertItem',
      },
      updateAzienda: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/aziende/${entity.id}/`, entity),
        updater: 'updateItem',
      },
    },
    name: 'AziendaState',
    effectCaller: 'configured',
    effect: (t) => (params = {}) => api.auth(t).get('/api/aziende', params),
    computed: {
      pagination: 'getPagination',
      list: 'getList',
      loading: 'isLoading',
    },
  }
)

export const AziendaAllState = rj(rjPlainList(), {
  mutations: {
    removeAzienda: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/aziende/${entity.id}`),
      updater: 'deleteItem',
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addAzienda: {
      effect: (t) => (entity) => api.auth(t).post('/api/aziende', entity),
      updater: 'insertItem',
    },
    updateAzienda: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/aziende/${entity.id}/`, entity),
      updater: 'updateItem',
    },
  },
  name: 'AziendaAllState',
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get('/api/aziende/all'),
  computed: {
    list: 'getList',
    loading: 'isLoading',
  },
})

export const AziendaStatisticaState = rj({
  name: 'Detail',
  effectCaller: 'configured',
  mutations: {
    // updateVisita: {
    //   effect: (t) => (id, entity) => api.auth(t).put(`/api/aziende/${id}/statistiche`, entity),
    //   updater: "updateData",
    // },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/aziende/${id}/statistiche`),
})

export const AziendaMyCalState = rj({
  name: 'AziendaMyCal',
  effectCaller: 'configured',
  effect: (t) => (id) => api.auth(t).get(`/api/aziende/${id}/mycal`),
})
