import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE } from "../consts"

export const MessaggiEmailState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeMessaggio: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/messaggi-emails/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addMessaggio: {
        effect: (t) => (entity) => api.auth(t).post("/api/messaggi-emails", entity),
        updater: "insertItem",
      },
    },
    name: "MessaggiEmailState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/messaggi-emails", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const NewsletterDetailState = rj({
    name: "Detail",
    effectCaller: "configured",
    mutations: {
      updateNewsletter: {
        effect: (t) => (id, entity) => api.auth(t).put(`/api/messaggi-emails/${id}`, entity),
        updater: "updateData",
      },
    },
    effect: (t) => (id) => api.auth(t).get(`/api/messaggi-emails/${id}`),
  })

  export const SendNewsletter = rj({
    name: 'SendNewsletter',
    effectCaller: 'configured',
    effect: (t) => (id,entity) => api.auth(t).post(`/api/messaggi-emails/${id}/invia_newsletter`,entity),
  })
