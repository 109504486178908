import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { AziendaState } from "../../state/aziende"
import Layout from "../../components/Layout/Layout"
import qs from "query-string"
import ModalFormAzienda from "../../components/Aziende/ModalFormAzienda"
import ListAziende from "../../components/Aziende/ListAziende"
import Button from "@material-ui/core/Button"
import ModalDelete from "../../components/Modals/ModalDelete"
import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { Cancel, Delete, GetApp, RotateLeft } from "@material-ui/icons"
import { useRunRj } from "react-rocketjump"
import { useExportToken } from "../../state/exportToken"
import moment from "moment"
import { isMobile } from "react-device-detect"

export default function Aziende() {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteAzienda } = modalDelete

  const onUpdate = (azienda) => actionsModalUpdate.open(azienda)
  const onDelete = (azienda) => actionsModalDelete.open(azienda)

  const [
    { list, loading, pagination },
    { addAzienda, updateAzienda, removeAzienda },
  ] = useRunRj(AziendaState, [debQueryParams], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const isProductionEnv = process.env.NODE_ENV === "production"
  const host = isProductionEnv ? window.location.host : "localhost:8000"
  const protocol = window.location.protocol

  const exportToken = useExportToken()

  const linkCsv = useMemo(() => {
    return `${protocol}//${host}/api/aziende-export/csv?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
    })}`
  }, [debQueryParams, exportToken, host, protocol])

  const linkPdf = useMemo(() => {
    return `${protocol}//${host}/api/aziende-export/pdf?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
    })}`
  }, [debQueryParams, exportToken, host, protocol])

  return (
    <Layout title={"Aziende"}>
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  style={{ width: isMobile ? '100%' : undefined}}
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel color={!queryParams.search ? undefined  : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
                {/* <Button
                  variant="contained"
                  style={{ marginLeft: isMobile ? 0 : 10 }}
                  startIcon={<RotateLeft />}
                  color="secondary"
                  onClick={() => setQueryParams(() => {})}
                >
                  Reset
                </Button> */}
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div
                className={
                  isMobile ? "d-flex justify-content-center pt-5" : "text-right"
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetApp />}
                  href={linkCsv}
                >
                  Excel
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  startIcon={<GetApp />}
                  href={linkPdf}
                >
                  Pdf
                </Button>
              </div>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Azienda
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListAziende
            pagination={pagination}
            columnProps={columnProps}
            aziende={list}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        <ModalFormAzienda
          save={(azienda) => {
            return addAzienda
              .onSuccess(() => actionsModalCreate.close())
              .asPromise({
                ...azienda,
                scadenza_licenza: moment(azienda.scadenza_licenza).toDate(),
              })
          }}
          isOpen={modalCreate.isOpen}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormAzienda
          save={(azienda) => {
            return updateAzienda
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({
                ...azienda,
                scadenza_licenza: moment(azienda.scadenza_licenza).toDate(),
              })
          }}
          azienda={modalUpdate.value}
          isOpen={modalUpdate.isOpen}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={
            deleteAzienda ? `Rimuovere ${deleteAzienda.rag_sociale}?` : null
          }
          text={
            deleteAzienda
              ? `Sei sicuro di voler eliminare l'azienda ${deleteAzienda.rag_sociale}?`
              : null
          }
          onDelete={() => {
            return removeAzienda
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
