import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import Layout from "../../components/Layout/Layout"
import ListNewsletters from "../../components/Newsletters/ListNewsletters"
import Button from "@material-ui/core/Button"
import ModalDelete from "../../components/Modals/ModalDelete"
import { Card, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core"
import { RotateLeft, AddCircle, Cancel } from "@material-ui/icons"
import { useRunRj } from "react-rocketjump"
import { MessaggiEmailState } from "../../state/newsletters"
import ModalFormNewsletter from "../../components/Newsletters/ModalFormNewsletter"
import { useHistory } from "react-router-dom"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { TemplatesNewsletterState } from "../../state/templatesNewsletter"
import { isMobile } from "react-device-detect"

export default function Newsletters() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteMessaggio } = modalDelete

  const onDelete = (azienda) => actionsModalDelete.open(azienda)

  const history = useHistory()

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id }
  }, [debQueryParams, selectedAzienda.id])

  const [
    { list, loading, pagination },
    { removeMessaggio, addMessaggio },
  ] = useRunRj(MessaggiEmailState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: templates }] = useRunRj(TemplatesNewsletterState)

  return (
    <Layout title="Newsletters">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid xs={12} md={12}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? '100%' : undefined}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Newsletter
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListNewsletters
            pagination={pagination}
            columnProps={columnProps}
            newsletters={list}
            onDelete={onDelete}
          />
        )}
        {templates && (
          <ModalFormNewsletter
            save={(newsletter) => {
              return addMessaggio
                .onSuccess((newsletter) => {
                  actionsModalCreate.close()
                  history.push(`/newsletters/${newsletter.id}`)
                })
                .asPromise({ ...newsletter, azienda: selectedAzienda.id })
            }}
            isOpen={modalCreate.isOpen}
            templates={templates}
            toggle={actionsModalCreate.toggle}
            onExited={actionsModalCreate.onClosed}
          />
        )}
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={
            deleteMessaggio ? `Rimuovere ${deleteMessaggio.titolo}?` : null
          }
          text={
            deleteMessaggio
              ? `Sei sicuro di voler eliminare l'azienda ${deleteMessaggio.titolo}?`
              : null
          }
          onDelete={() => {
            return removeMessaggio
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
