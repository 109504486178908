import {
  Card,
  Button,
  TextField,
  CardHeader,
  CardContent,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { Alert } from "@material-ui/lab"
import { Link, useParams } from "react-router-dom"
import { usePasswordReset } from "../../state/passwordRecover"
import { isMobile } from "react-device-detect"
import { Save } from "@material-ui/icons"

export default function Login() {
  const [{ pending }, { run: resetPassword }] = usePasswordReset()

  const [password, setPassword] = useState("")
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailure, setAlertFailure] = useState(false)

  const { token } = useParams()

  function handleSubmit(e) {
    e.preventDefault()
    if (!pending) {
      resetPassword
        .onSuccess(() => {
          setAlertSuccess(true)
        })
        .onFailure(() => {
          setAlertFailure(true)
        })
        .run(token, password)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card
        style={{
          margin: isMobile ? 0 : "10% auto",
          width: isMobile ? "100%" : 600,
          borderRadius: isMobile ? 0 : 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardHeader
          className="card-login"
          title={
            <div style={{ textAlign: "center" }}>
              <img
                height={isMobile ? 30 : 40}
                src="/salonify.png"
                alt="Salonify"
              />
            </div>
          }
        />
        <CardContent>
          <div style={{ marginTop: 10 }}>
            <h3>Reset Password</h3>
            Inserisci la nuova password per il tuo account.
            <TextField
              style={{ marginTop: 5 }}
              variant="outlined"
              placeholder="Nuova password"
              fullWidth
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </div>
          <div style={{ marginTop: 10, textAlign: "right" }}>
            <Button
              startIcon={<Save />}
              variant="contained"
              size="large"
              type="submit"
              color="primary"
            >
              Reset
            </Button>
          </div>
          {alertFailure && (
            <Alert variant="filled" color="error" style={{ marginTop: 20 }}>
              Errore nel cambio password.
            </Alert>
          )}
          {alertSuccess && (
            <Alert variant="filled" color="success" style={{ marginTop: 20 }}>
              Passowrd cambiata con successo. Vai al{" "}
              <Link to="/login">Login</Link>
            </Alert>
          )}
        </CardContent>
      </Card>
    </form>
  )
}
