import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  transformErrorForForm,
  PhoneNumberField,
  ReminderMinutesFields
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  MenuItem,
} from "@material-ui/core"
import { KeyboardBackspace, Save } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import moment from "moment"
// import moment from 'moment'

const ClienteSchema = Yup.object().shape({
  nome: Yup.string().required("Nome è obbligatorio"),
  cognome: Yup.string().required("Cognome è obbligatorio"),
  sesso: Yup.string().required("Sesso è obbligatorio"),
  data_inserimento: Yup.date()
    .typeError("Data inserita nel formato sbagliato")
    .required("Data di inserimento è obbligatoria"),
})

const INITIAL_VALUES = {
  nome: "",
  cognome: "",
  sesso: "",
  email: "",
  data_nascita: null,
  data_inserimento: moment().format("YYYY-MM-DD"),
  is_abituale: false,
  invio_sms: false,
  minuti_invio: null,
}

export default function ModalFormCliente({
  cliente,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {cliente ? "Modifica" : "Inserimento"} cliente
      </DialogTitle>
      <Formik
        validationSchema={ClienteSchema}
        initialValues={fixInitialValuesNull(cliente) ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(
            setNullOnEmptyValues({
              ...values,
              data_nascita:
                values.data_nascita === "Invalid date"
                  ? null
                  : values.data_nascita,
            })
          ).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Field label={"Nome"} name="nome" component={InputField} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label={"Cognome"}
                    name="cognome"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field label="E-mail" name="email" component={InputField} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Data di nascita"
                    name="data_nascita"
                    component={DateField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Sesso"
                    select={true}
                    component={InputField}
                    name="sesso"
                  >
                    <MenuItem value="">---</MenuItem>
                    <MenuItem value="m">Maschio</MenuItem>
                    <MenuItem value="f">Femmina</MenuItem>
                  </Field>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Field
                    label="Data inserimento"
                    name="data_inserimento"
                    component={DateField}
                    //InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field 
                    label="Num telefono"
                    component={PhoneNumberField}
                    name="num_telefono"
                  />

                </Grid>
                <Grid item md={12} xs={12}>
                  <Field 
                    label="Minuti notifica"
                    component={ReminderMinutesFields}
                    name="minuti_notifica"
                  />

                </Grid>
                {!cliente && (
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Abituale"
                      component={SwitchCheckboxField}
                      name="is_abituale"
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
                startIcon={<Save />}
              >
                {cliente ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
