import { AuthRoute, GuestRoute, MaybeAuthRoute } from "use-eazy-auth/routes"
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom"
import Auth from "./Auth"
import Login from "./pages/Login"
import { ThemeProvider } from "@material-ui/core"
import theme from "./theme"
import Aziende from "./pages/Aziende/Aziende"
import Clienti from "./pages/Clienti"
import Home from "./pages/Home/Home"
import Prodotti from "./pages/Prodotti"
import Prestazioni from "./pages/Prestazioni"
import Visite from "./pages/Visite"
import VisitaDettaglio from "./pages/VisitaDettaglio"
import Utenti from "./pages/Utenti"
import SchedaCliente from "./pages/SchedaCliente"
import Rivendite from "./pages/Rivendite"
import Tags from "./pages/Tags"
import Newsletters from "./pages/Newsletters"
import MessaggioDettaglio from "./pages/MessaggioDettaglio"
import ResetPassword from "./pages/ResetPassword"
import Profilo from "./pages/Profilo"
import RegistrazioneAzienda from "./pages/RegistrazioneAzienda/RegistrazioneAzienda"
import RegistrazioneResult from "./pages/RegistrazioneResult"
import SelectedAzienda, { ValidAzienda } from "./SelectedAzienda"
import TemplatesNewsletter from "./pages/TemplatesNewsletter/TemplatesNewsletter"
import Calendario from "./pages/Calendario"
import Reports from "./pages/Reports"

function isNotEmployee(user) {
  if (user.profilo.permessi === "employee") {
    return "/"
  }
}

function isNotAdmin(user) {
  if (
    user.profilo.permessi === "manager" ||
    user.profilo.permessi === "employee"
  ) {
    return "/"
  }
}

function isAdmin(user) {
  if (user.profilo.permessi !== "admin") {
    return "/"
  }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Auth>
        <Router>
          <Switch>
            <GuestRoute path="/login">
              <Login />
            </GuestRoute>
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route
              path="/registrazione-azienda"
              component={RegistrazioneAzienda}
            />
            <MaybeAuthRoute
              path="/registrazione-result/:org_id"
              component={RegistrazioneResult}
            />
            <AuthRoute redirectTest={isNotAdmin} path="/aziende" exact>
              <Aziende />
            </AuthRoute>
            <AuthRoute path="/">
              <SelectedAzienda>
                <Switch>
                  <AuthRoute path="/profilo" exact>
                    <Profilo />
                  </AuthRoute>
                  <Route paht="/">
                    <ValidAzienda>
                      <Switch>
                        <Route path="/" exact>
                          <Home />
                        </Route>
                        <Route path="/clienti" exact>
                          <Clienti />
                        </Route>
                        <Route path="/prodotti" exact>
                          <Prodotti />
                        </Route>
                        <AuthRoute
                          redirectTest={isNotEmployee}
                          path="/servizi"
                          exact
                        >
                          <Prestazioni />
                        </AuthRoute>
                        <Route path="/visite" exact>
                          <Visite />
                        </Route>
                        <AuthRoute redirectTest={isNotEmployee} path="/reports" exact>
                          <Reports />
                        </AuthRoute>
                        <Route path="/calendario" exact>
                          <Calendario />
                        </Route>
                        <Route path="/visite/:id" exact>
                          <VisitaDettaglio />
                        </Route>
                        <AuthRoute
                          redirectTest={isNotEmployee}
                          path="/utenti"
                          exact
                        >
                          <Utenti />
                        </AuthRoute>
                        <Route path="/scheda-cliente/:id" exact>
                          <SchedaCliente />
                        </Route>
                        <Route path="/rivendite" exact>
                          <Rivendite />
                        </Route>
                        <Route path="/tags" exact>
                          <Tags />
                        </Route>
                        <AuthRoute
                          redirectTest={isNotEmployee}
                          path="/newsletters"
                          exact
                        >
                          <Newsletters />
                        </AuthRoute>
                        <AuthRoute
                          redirectTest={isAdmin}
                          path="/templates-newsletters"
                          exact
                        >
                          <TemplatesNewsletter />
                        </AuthRoute>
                        <AuthRoute
                          redirectTest={isNotEmployee}
                          path="/newsletters/:id"
                          exact
                        >
                          <MessaggioDettaglio />
                        </AuthRoute>
                        <Redirect to="/" />
                      </Switch>
                    </ValidAzienda>
                  </Route>
                </Switch>
              </SelectedAzienda>
            </AuthRoute>
          </Switch>
        </Router>
      </Auth>
    </ThemeProvider>
  )
}

export default App
