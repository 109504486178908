import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Card,
  TableContainer,
  Paper,
  Chip,
  CardHeader,
  CardContent,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import Column from "../Column/Column"
import { Link } from "react-router-dom"
import { isMobile } from "react-device-detect"

export default function ListTags({
  tags,
  onDelete,
  onUpdate,
  pagination,
  columnProps,
}) {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()
  return (
    <Card>
      <CardHeader title="Tags" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="nome">
                  Nome
                </Column>
                {!isMobile && (
                  <>
                    <TableCell>Numero clienti</TableCell>
                    <Column {...columnProps} field="nome">
                      Preview
                    </Column>
                  </>
                )}
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags &&
                tags.map((tag) => (
                  <TableRow key={tag.id}>
                    <TableCell>{tag.nome}</TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>
                          <Link
                            title={`Lista clienti filtrati per ${tag.nome}`}
                            to={`/clienti/?tag=${tag.id}`}
                          >
                            {tag.num_clienti === undefined
                              ? `0 clienti`
                              : tag.num_clienti === 1
                              ? `1 cliente`
                              : `${tag.num_clienti} clienti`}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Chip
                            style={{
                              background: tag.colore,
                              color: "#FFF",
                            }}
                            size="small"
                            label={tag.nome}
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <IconButton
                        onClick={() => onUpdate(tag)}
                        aria-label="modifica"
                        title="Modifica tag"
                      >
                        <Edit style={{ color: "orange" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => onDelete(tag)}
                        aria-label="elimina"
                        title="Elimina tag"
                      >
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
