import {
  AppBar,
  Drawer,
  List,
  makeStyles,
  Toolbar,
  Hidden,
  Typography,
  IconButton,
} from "@material-ui/core"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"
import PerfectScrollbar from "react-perfect-scrollbar"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import NavMenuItem from "../NavMenuItem"
import {
  AccountCircle,
  CalendarToday,
  Casino,
  ContactMail,
  ExitToApp,
  Home,
  LocalOffer,
  Mail,
  People,
  PeopleAlt,
  PeopleOutline,
  Menu,
  Store,
  Work,
  CalendarViewDay,
  Assessment
} from "@material-ui/icons"
import SidebarHeader from "./SidebarHeader"
import ModalLogout from "../ModalLogout"
import defaultTheme from "../../theme"
import { isMobile } from "react-device-detect"

const drawerWidth = 304

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    appBar: {
      // left: drawerWidth,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    drawer: {
      // width: drawerWidth,
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: defaultTheme.palette.primary.main,
      color: "#FFF",
    },
    container: {
      flex: 1,
      padding: isMobile ? 10 : 40,
    },
    offset: theme.mixins.toolbar,
    logout: {
      position: "absolute",
      right: 20,
      display: "flex",
      alignItems: "center",
      top: 20,
      cursor: "pointer",
    },
    menuButton: {
      marginRight: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    scrollbarRoot: {
      height: "100vh"
    },
    navMenuItem: {
      padding: "0 16px 0 0",
      position: "relative",
      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        paddingLeft: 16,
      },
    },
    navMenuLink: {
      display: "flex",
      alignItems: "center",
      padding: "7px 16px 9px 32px",
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      color: theme.palette.sidebar.textColor,
      "&:hover, &:focus": {
        color: theme.palette.sidebar.textDarkColor,
        backgroundColor: theme.palette.sidebar.navHoverBgColor,
        "& .Cmt-icon-root, & .Cmt-nav-text": {
          color: theme.palette.sidebar.textDarkColor,
        },
      },
      "&.active": {
        color: theme.palette.sidebar.textActiveColor,
        backgroundColor: theme.palette.sidebar.navActiveBgColor,
        "& .Cmt-icon-root, & .Cmt-nav-text": {
          color: theme.palette.sidebar.textActiveColor,
        },
        "&:hover, &:focus": {
          "& .Cmt-nav-text, & .Cmt-icon-root": {
            color: theme.palette.sidebar.textActiveColor,
          },
        },
      },
      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        justifyContent: "center",
        padding: 0,
        height: 40,
        width: 40,
        borderRadius: "50%",
        marginLeft: 4,
        marginRight: 4,
      },
    },
    navText: {
      flex: 1,
      fontSize: 14,
      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        display: "none",
      },
    },
    iconRoot: {
      marginRight: 16,
      fontSize: 20,
      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        marginRight: 0,
      },
    },
  }
})

const DrawerContent = ({ user }) => {
  const classes = useStyles()
  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <SidebarHeader />

      <List style={{ marginTop: 15 }}>
        <NavMenuItem exact name="Home" link="/" icon={<Home />} />
      </List>
      {user.profilo && user.profilo.permessi === "admin" && (
        <List>
          <NavMenuItem
            name="Aziende"
            link="/aziende"
            icon={<PeopleOutline />}
          />
        </List>
      )}
      <List>
        <NavMenuItem name="Clienti" link="/clienti" icon={<People />} />
      </List>
      <List>
        <NavMenuItem name="Magazzino" link="/prodotti" icon={<Store />} />
      </List>
      {user.profilo && user.profilo.permessi !== "employee" && (
        <List>
          <NavMenuItem name="Listino servizi" link="/servizi" icon={<Work />} />
        </List>
      )}
      <List>
        <NavMenuItem name="Calendario" link="/calendario" icon={<CalendarViewDay />} />
      </List>
      <List>
        <NavMenuItem name="Visite" link="/visite" icon={<CalendarToday />} />
      </List>
      <List>
        <NavMenuItem name="Reports" link="/reports" icon={<Assessment />} />
      </List>
      <List>
        <NavMenuItem name="Rivendite" link="/rivendite" icon={<Casino />} />
      </List>
      {user.profilo && user.profilo.permessi !== "employee" && (
        <List>
          <NavMenuItem name="Tags" link="/tags" icon={<LocalOffer />} />
        </List>
      )}
      {user.profilo && user.profilo.permessi !== "employee" && (
        <List>
          <NavMenuItem
            name="Newsletters"
            link="/newsletters"
            icon={<ContactMail />}
          />
        </List>
      )}
      {user.profilo && user.profilo.permessi === "admin" && (
        <List>
          <NavMenuItem
            name="Templates Newsletters"
            link="/templates-newsletters"
            icon={<Mail />}
          />
        </List>
      )}
      {user.profilo && user.profilo.permessi !== "employee" && (
        <List>
          <NavMenuItem name="Utenti" link="/utenti" icon={<PeopleAlt />} />
        </List>
      )}
      <List>
        <NavMenuItem name="Profilo" link="/profilo" icon={<AccountCircle />} />
      </List>
    </PerfectScrollbar>
  )
}

export default function Layout({ children, title }) {
  const { user } = useAuthUser()
  const [modalLogout, actionsLogout] = useModalTrigger()

  const container =
    window !== undefined ? () => window.document.body : undefined

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const classes = useStyles()

  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <div className={classes.root}>
        <AppBar color={"primary"} position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              style={{ color: "#FFF" }}
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
            <Link to={"/"} style={{ color: "#FFF" }}>
              <img
                height={isMobile ? 30 : 40}
                src="/salonify.png"
                alt="Salonify"
              />
            </Link>
            {title && !isMobile && (
              <>
                <Typography variant="h1" style={{ marginLeft: 10 }} noWrap>
                  /
                </Typography>
                <Typography
                  variant="h3"
                  style={{ marginLeft: 10, marginTop: 3 }}
                >
                  {title}
                </Typography>
              </>
            )}
          </Toolbar>
          <div onClick={() => actionsLogout.open()} className={classes.logout}>
            <ExitToApp style={{ marginRight: 5 }} />{" "}
            {!isMobile && <Typography variant="h3">Logout</Typography>}
          </div>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={"left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DrawerContent user={user} />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              anchor="left"
            >
              <DrawerContent user={user} />
            </Drawer>
          </Hidden>
        </nav>

        <div className={classes.container}>
          <div className={classes.offset} />
          {children}
        </div>
        <ModalLogout
          isOpen={modalLogout.isOpen}
          toggle={actionsLogout.toggle}
          onExited={() => {
            actionsLogout.onClosed()
          }}
        />
      </div>
    </PerfectScrollbar>
  )
}
