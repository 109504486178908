import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  InputField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core"
import ButtonSave from "../ButtonSave"
import { KeyboardBackspace } from "@material-ui/icons"

const ServizioSchema = Yup.object().shape({
  titolo: Yup.string().required("Titolo è obbligatorio"),
  prezzo_base: Yup.string().required("Prezzo base è obbligatorio"),
})

const INITIAL_VALUES = {
  titolo: "",
  descrizione: "",
  prezzo_base: "",
}

export default function ModalFormPrestazione({
  servizio,
  save,
  isOpen,
  toggle,
  onExited,
  aziende,
}) {

  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {servizio ? "Modifica" : "Inserimento"} servizio
      </DialogTitle>
      <Formik
        validationSchema={ServizioSchema}
        initialValues={fixInitialValuesNull(servizio) ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Field
                    label={"Titolo"}
                    name="titolo"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Descrizione"
                    type="text"
                    name="descrizione"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Prezzo base"
                    type="text"
                    name="prezzo_base"
                    component={InputField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {servizio ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
