import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { chain, sumBy } from "lodash"
import { useRunRj } from "react-rocketjump"
import Layout from "../../components/Layout/Layout"
import { Card, CardContent, CardHeader, CircularProgress, Grid, InputLabel, MenuItem, Select} from "@material-ui/core"
import defaultTheme from "../../theme"
import { useAuthUser } from "use-eazy-auth"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { AziendaStatisticaState } from "../../state/aziende"
import ChartPagamenti from "../../components/ChartPagamenti"
import { PrestazioneChart } from "./PrestazioneChart"


export default function Reports() {    
    const { user } = useAuthUser()

    const [{ selectedAzienda }] = useSelectedAzienda()

    const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

    const [{ data: aziendaStat }] = useRunRj(AziendaStatisticaState, [selectedAzienda.id,])
    
    const today = new Date()
    const [year, month] = [today.getFullYear(), today.getMonth()]
    
    
      const monthMapping = (month) => {
        const month_mapping = {
            1: 'Gen',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'Mag',
            6: 'Giu',
            7: 'Lug',
            8: 'Ago',
            9: 'Set',
            10: 'Ott',
            11: 'Nov',
            12: 'Dic'
        }

        return month_mapping[month]
    }
    const [selectedMonth, setSelectedMonth] = React.useState(month + '-' + year);
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    }

    const return_filtered_pagamenti_array = (payments, selectedMonth) => {
      let selectedPayments = []
      payments.map((record) => {
        if (record.data__month + '-' + record.data__year == selectedMonth){
          selectedPayments.push(record)
        }
      })
      return selectedPayments;
    }

    const return_filtered_prestazioni_array = (prestazioni, selectedMonth, valueType) => {
      let selectedPayments = []
      prestazioni.map((record) => {
        if (record.data__month + '-' + record.data__year == selectedMonth){
          selectedPayments.push({'item_period_key': record.item_period_key, 'data__year': record.data__year, 'data__month': record.data__month, 'prestazione': record.prestazione, 'value': record[valueType]})
        }
      })

      //just for testing purposes
      return selectedPayments;
    }


    return (
        <Layout title="Visite">
        <div>
            <Card className="card-filters">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <div>
                            <h2>Salonify Reports</h2>
                        </div>
                    </Grid>
                </Grid>
            </Card>

            <Card className="card-filters">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <div>
                            <h2>Filtri</h2>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Mese</InputLabel>
                        <Select label="Mese" value={selectedMonth} onChange={handleMonthChange}>
                        {aziendaStat === null && (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress color="primary" />
                        </div>
                        )}
                        {aziendaStat && 
                            aziendaStat?.totali_mese_visite.map((record) => {
                                return <MenuItem value={record.data__month + '-' + record.data__year}>{record.data__year + ' - ' + monthMapping(record.data__month)}</MenuItem>
                            })
                        }
                        </Select>
                    </Grid>
                </Grid>
            </Card>

            <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
            <Card xs={6} md={4}>
            <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Ricavi totali"}
                        />
                <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
                        >
                {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                )}
                {selectedAzienda && aziendaStat  && aziendaStat?.totali_mese_visite.map((record) => {
                    if (record.data__month + '-' + record.data__year == selectedMonth){
                        let delta_tot = record.tot - record.tot_avg
                        let delta_perc = (delta_tot / record.tot_avg)*100
                        return (
                        <>
                            <div><h2>{record.tot} €</h2></div>
                        <small
                            style={{
                              color:
                                delta_tot > 0
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {delta_perc.toFixed(2) + " % (media " + record.tot_avg.toFixed(2) + "€ )"}
                        </small>
                        </>
                        );
                    }
                })}
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={6} md={4}>
            <Card xs={6} md={4}>
            <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Totale servizi"}
                        />
                <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
                        >
                {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                )}
                {selectedAzienda && aziendaStat && aziendaStat?.totali_mese_visite.map((record) => {
                    if (record.data__month + '-' + record.data__year == selectedMonth){
                        let delta = record.tot_services - record.tot_services_avg
                        let delta_perc = (delta / record.tot_services_avg)*100
                        return (
                        <>
                            <div><h2>{record.tot_services} €</h2></div>
                        <small
                            style={{
                              color:
                                delta > 0
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {delta_perc.toFixed(2) + " % (media " + record.tot_services_avg.toFixed(2) + "€ )"}
                        </small>
                        </>
                        );                    
                    }
                })}
                </CardContent> 
            </Card>
            </Grid>
            <Grid item xs={6} md={4}>
            <Card xs={6} md={4}>
            <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Totale rivendite"}
                        />
                <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
                        >
                {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                )}
                {selectedAzienda && aziendaStat && aziendaStat?.totali_mese_visite.map((record) => {
                    if (record.data__month + '-' + record.data__year == selectedMonth){
                        let delta = record.tot_rivendite - record.tot_rivendite_avg
                        let delta_perc = (delta / record.tot_rivendite_avg)*100
                        return (
                        <>
                            <div><h2>{record.tot_rivendite} €</h2></div>
                        <small
                            style={{
                              color:
                                delta > 0
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {delta_perc.toFixed(2) + " % (media " + record.tot_rivendite_avg.toFixed(2) + "€ )"}
                        </small>
                        </>
                        );                    
                    }
                })}
                </CardContent> 
            </Card>
            </Grid>
            </Grid>
        <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
        <Card xs={6} md={6}>
        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Numero visite"}
                        />
                <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
                        >
                {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                )}
                {selectedAzienda && aziendaStat && aziendaStat?.totali_mese_visite.map((record) => {
                    if (record.data__month + '-' + record.data__year == selectedMonth){
                        let delta = record.num - record.num_avg
                        let delta_perc = (delta / record.num_avg)*100
                        return (
                        <>
                            <div><h2>{record.num}</h2></div>
                        <small
                            style={{
                              color:
                                delta > 0
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {delta_perc.toFixed(2) + " % (media " + record.num_avg.toFixed(2) + ")"}
                        </small>
                        </>
                        );                    
                    }
                })}
                </CardContent> 
            </Card>
            </Grid>
        <Grid item xs={6} md={6}>
        <Card xs={6} md={6}>
        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Numero rivendite"}
                        />
                <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
                        >
                {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                )}
                {selectedAzienda && aziendaStat && aziendaStat?.totali_mese_visite.map((record) => {
                    if (record.data__month + '-' + record.data__year == selectedMonth){
                        let delta = record.num_rivendite - record.num_rivendite_avg
                        let delta_perc = (delta / record.num_rivendite)*100
                        return (
                        <>
                            <div><h2>{record.num_rivendite}</h2></div>
                        <small
                            style={{
                              color:
                                delta > 0
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {delta_perc.toFixed(2) + " % (media " + record.num_rivendite_avg.toFixed(2) + ")"}
                        </small>
                        </>
                        );                    
                    }
                })}
                </CardContent> 
            </Card>
            </Grid>
        </Grid>

        <Grid container spacing={2}>
        <Grid item xs={6} md={12}>
        <Card xs={6} md={12}>
          <CardHeader
                            style={{
                              backgroundColor:
                                defaultTheme.palette.secondary.main,
                              padding: 5,
                              textAlign: "center",
                              color: "#FFF",
                            }}
                            title={"Ricavi per tipologia pagamenti"}
          />

          <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
          >
            {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
            )}
            {selectedAzienda && aziendaStat && aziendaStat?.payment_type_monthly_aggregate && (
              <ChartPagamenti
                data={return_filtered_pagamenti_array(aziendaStat?.payment_type_monthly_aggregate, selectedMonth)}
              />
            )}

          </CardContent>

        </Card>
        </Grid>
        </Grid>


        <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
        {/* style={{maxHeight: "50%"}} */}
        <Card  xs={6} md={6}>
          <CardHeader
                            style={{
                              backgroundColor:
                                defaultTheme.palette.secondary.main,
                              padding: 5,
                              textAlign: "center",
                              color: "#FFF",
                            }}
                            title={"Ricavi per tipologia servizio (EUR)"}
          />

          <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
          > 
            {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
            )}
            {selectedAzienda && aziendaStat && aziendaStat?.prestazioni_monthly_aggregate && (
              <PrestazioneChart
                data={return_filtered_prestazioni_array(aziendaStat?.prestazioni_monthly_aggregate, selectedMonth, 'tot')}
                
              />
            )}
          </CardContent>

        </Card>
        </Grid>


        <Grid item xs={6} md={6}>
        {/* style={{maxHeight: "50%"}} */}
        <Card  xs={6} md={6}>
          <CardHeader
                            style={{
                              backgroundColor:
                                defaultTheme.palette.secondary.main,
                              padding: 5,
                              textAlign: "center",
                              color: "#FFF",
                            }}
                            title={"Numero visite per tipologia servizio"}
          />

          <CardContent
                          style={{ textAlign: "center", fontSize: 20, minHeight: 110 }}
          > 
            {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
            )}
            {selectedAzienda && aziendaStat && aziendaStat?.prestazioni_monthly_aggregate && (
              <PrestazioneChart
                data={return_filtered_prestazioni_array(aziendaStat?.prestazioni_monthly_aggregate, selectedMonth, 'num')}
                
              />
            )}
          </CardContent>

        </Card>
        </Grid>

        </Grid>
        </div>
        </Layout>
    )
}