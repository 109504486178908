import EazyAuth, { useAuthActions } from "use-eazy-auth"
import { ConfigureRj } from "react-rocketjump"
import api from "./api"

const loginCall = (params) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh,
    }))
    .post("/api/token", params)
const meCall = (token) => api.auth(token).get("/api/me")

const refreshTokenCall = (refreshToken) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh || refreshToken,
    }))
    .post("/api/token/refresh/", { refresh: refreshToken })

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  return (
    <EazyAuth
      loginCall={loginCall}
      onLogout={() => {
        // Session storoge fucked up on safari anonymous window.
        try {
          window.sessionStorage.removeItem("selectedAzienda")
        } catch (e) {}
      }}
      meCall={meCall}
      refreshTokenCall={refreshTokenCall}
    >
      <InnerAuth>{children}</InnerAuth>
    </EazyAuth>
  )
}

export default Auth
