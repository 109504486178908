import React from "react"
import { TableCell } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"

function parseFilterValue(rawValue) {
  let field = rawValue
  let direction = null

  if (rawValue === "") {
    return { field, direction }
  }

  direction = rawValue && rawValue.charAt(0) === "-" ? "down" : "up"
  field = direction === "down" ? field.substr(1) : field
  return { field, direction }
}

export default function Column({
  children,
  value,
  onChange,
  field,
  noWrap = false,
  className = "",
}) {
  const { field: filterField, direction } = parseFilterValue(value)

  return (
    <TableCell
      style={{ whiteSpace: "nowrap", cursor: "pointer" }}
      onClick={() => {
        if (!field) {
          return
        }
        if (filterField !== field) {
          onChange(`${field}`)
        } else if (direction === "up") {
          onChange(`-${field}`)
        } else {
          onChange("", false)
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{children}</span>
        {field && field === filterField && direction === "up" && (
          <ArrowUpward
            style={{ marginLeft: 2 }}
            fontSize="small"
            color="primary"
          />
        )}
        {field && field === filterField && direction === "down" && (
          <ArrowDownward
            style={{ marginLeft: 2 }}
            fontSize="small"
            color="primary"
          />
        )}
        {field !== filterField && (
          <ArrowDownward
            style={{ marginLeft: 2, color: "transparent" }}
            fontSize="small"
          />
        )}
      </div>
    </TableCell>
  )
}
