import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { Delete, KeyboardBackspace } from "@material-ui/icons"
import React from "react"
import ButtonDelete from "../ButtonDelete"

export default function ModalDelete({
  isOpen,
  title,
  text,
  toggle,
  onExited,
  onDelete,
}) {
  return (
    <Dialog maxWidth={false} open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{text}</DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
        <Button startIcon={<KeyboardBackspace />} variant="outlined" onClick={toggle}>
          Chiudi
        </Button>
        <ButtonDelete startIcon={<Delete />} type="submit" onClick={onDelete} variant="outlined">
          Cancella
        </ButtonDelete>
      </DialogActions>
    </Dialog>
  )
}
