import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const TemplatesNewsletterState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeTemplate: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/templates-emails/${entity.id}`),
        updater: 'deleteItem',
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addTemplate: {
        effect: (t) => (entity) => {
          const fd = new FormData()
          fd.append('titolo', entity.titolo)
          fd.append('testo', entity.testo)
          fd.append('immagine', entity.immagine)
          return api.auth(t).post('/api/templates-emails', fd)
        },
        updater: 'insertItem',
      },
      updateTemplate: {
        effect: (t) => (entity) => {
          const fd = new FormData()
          fd.append('titolo', entity.titolo)
          fd.append('testo', entity.testo)
          if (entity.immagine && entity.immagine instanceof File) {
            fd.append('immagine', entity.immagine)
          }
          return api.auth(t).put(`/api/templates-emails/${entity.id}/`, fd)
        },
        updater: 'updateItem',
      },
    },
    name: 'TemplatesNewsletterState',
    effectCaller: 'configured',
    effect: (t) => (params = {}) =>
      api.auth(t).get('/api/templates-emails', params),
    computed: {
      pagination: 'getPagination',
      list: 'getList',
      loading: 'isLoading',
    },
  }
)
