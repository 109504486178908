import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  InputField,
  setNullOnEmptyValues,
  fixInitialValuesNull,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Button,
  Grid,
  TextField,
} from "@material-ui/core"
import ButtonSave from "../ButtonSave"
import { KeyboardBackspace } from "@material-ui/icons"
import moment from "moment"
import { Autocomplete } from "@material-ui/lab"

export default function ModalFormRivendita({
  save,
  isOpen,
  toggle,
  cliente = null,
  rivendita,
  onExited,
  prodotti,
  clienti,
  inputScheda = false,
}) {
  const INITIAL_VALUES = {
    data: moment().format("YYYY-MM-DD"),
    prodotto: "",
    prezzo: "",
    percentuale_sconto: 0,
    tipo_pagamento: "",
    prezzo_singolo: "",
    quantita: "",
    cliente: "",
  }

  const RivenditaSchema = Yup.object().shape({
    data: Yup.date()
      .typeError("Data inserita nel formato sbagliato")
      .required("La data è obbligatoria"),
    prodotto: rivendita
      ? undefined
      : Yup.object().nullable().required("Il prodotto è obbligatorio"),
    cliente:
      inputScheda || rivendita
        ? undefined
        : Yup.object().nullable().required("Il cliente è obbligatorio"),
    prezzo: Yup.string().required("Il totale è obbligatorio"),
    prezzo_singolo: Yup.string().required("Il prezzo è obbligatorio"),
    quantita: Yup.number().required("La quantità è obbligatoria"),
  })

  const clientiOptions = useMemo(() => {
    if (clienti) {
      return clienti.map((cliente) => ({
        id: cliente.id,
        nome: cliente.nome + " " + cliente.cognome,
      }))
    }
    return []
  }, [clienti])

  const prodottiOptions = useMemo(() => {
    if (prodotti) {
      return prodotti.map((prodotto) => ({
        id: prodotto.id,
        nome: prodotto.prodotto,
        prezzo: prodotto.rivendita,
      }))
    }
    return []
  }, [prodotti])

  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {rivendita ? "Modifica" : "Nuova"} rivendita
      </DialogTitle>
      <Formik
        validationSchema={RivenditaSchema}
        initialValues={
          rivendita
            ? fixInitialValuesNull(rivendita)
            : {
                ...INITIAL_VALUES,
                cliente: cliente ? cliente.id : "",
              }
        }
        onSubmit={(values, actions) =>
          save(
            setNullOnEmptyValues({
              ...values,
              cliente: values.cliente.id,
              prodotto: values.prodotto.id,
            })
          ).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                {!inputScheda && (
                  <Grid item xs={12}>
                    {rivendita ? (
                      <TextField
                        label={"Cliente"}
                        disabled
                        variant={"outlined"}
                        fullWidth
                        value={
                          rivendita.cliente_data
                            ? rivendita.cliente_data.nome +
                              " " +
                              rivendita.cliente_data.cognome
                            : ""
                        }
                      />
                    ) : (
                      <Autocomplete
                        noOptionsText={"Nessun cliente trovato"}
                        id="size-small-standard"
                        name="cliente"
                        onChange={(e, value) => setFieldValue("cliente", value)}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        options={clientiOptions}
                        value={values.cliente}
                        getOptionLabel={(option) => (option ? option.nome : "")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors["cliente"]}
                            helperText={
                              !!errors["cliente"] || touched["cliente"]
                                ? errors["cliente"]
                                : undefined
                            }
                            variant="outlined"
                            label="Cliente"
                          />
                        )}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {rivendita ? (
                    <TextField
                      label={"Prodotto"}
                      disabled
                      variant={"outlined"}
                      fullWidth
                      value={rivendita.prodotto_data.prodotto}
                    />
                  ) : (
                    <Autocomplete
                      noOptionsText={"Nessun prodotto trovato"}
                      id="size-small-standard-prod"
                      name="prodotto"
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue("prodotto", value)
                          if (value.prezzo) {
                            setFieldValue("prezzo_singolo", value.prezzo)
                          }
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      options={prodottiOptions}
                      value={values.prodotto}
                      getOptionLabel={(option) => (option ? option.nome : "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors["prodotto"]}
                          helperText={
                            !!errors["prodotto"] || touched["prodotto"]
                              ? errors["prodotto"]
                              : undefined
                          }
                          variant="outlined"
                          label="Prodotto"
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Prezzo"
                    type="text"
                    onChange={(e) => {
                      setFieldValue("prezzo_singolo", e.target.value)
                      if (values.quantita !== "") {
                        setFieldValue(
                          "prezzo",
                          values.quantita * e.target.value.replace(",", ".")
                        )
                      } else {
                        setFieldValue("prezzo", 0)
                      }
                    }}
                    name="prezzo_singolo"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Quantità"
                    disabled={!!rivendita}
                    type="text"
                    onChange={(e) => {
                      setFieldValue("quantita", e.target.value)
                      if (values.prezzo_singolo !== "") {
                        setFieldValue(
                          "prezzo",
                          values.prezzo_singolo.replace(",", ".") *
                            e.target.value
                        )
                      } else {
                        setFieldValue("prezzo", 0)
                      }
                    }}
                    name="quantita"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field label="Data" name="data" component={DateField} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Totale"
                    type="text"
                    disabled
                    name="prezzo"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Tipo pagamento"
                    name="tipo_pagamento"
                    select={true}
                    component={InputField}
                  >
                    <MenuItem value="">---</MenuItem>
                    <MenuItem value="cash">Contanti</MenuItem>
                    <MenuItem value="bancomat">Bancomat</MenuItem>
                    <MenuItem value="credit_card">Carta credito</MenuItem>
                    <MenuItem value="debit_card">Carta debito</MenuItem>
                    <MenuItem value="other">Altro</MenuItem>
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {rivendita ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
