import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import { ClienteState } from "../../state/clienti"
import Layout from "../../components/Layout/Layout"
import ListClienti from "../../components/Clienti/ListClienti"
import ModalFormCliente from "../../components/Clienti/ModalFormCliente"
import ModalFormAssociaTags from "../../components/Clienti/ModalFormAssociaTags"
import ModalDelete from "../../components/Modals/ModalDelete"
import qs from "query-string"
import {
  TextField,
  Button,
  Card,
  CircularProgress,
  FormControl,
  MenuItem,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { AddCircle, GetApp, Cancel } from "@material-ui/icons"
import { TagState } from "../../state/tags"
import { useExportToken } from "../../state/exportToken"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { isMobile } from "react-device-detect"
import { useAuthUser } from "use-eazy-auth"

export default function Clienti() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const [modalAssociaTags, actionsModalAssociaTags] = useModalTrigger()
  const { value: deleteCliente } = modalDelete

  const onUpdate = (cliente) => actionsModalUpdate.open(cliente)
  const onAssocia = (cliente) => actionsModalAssociaTags.open(cliente)
  const onDelete = (cliente) => actionsModalDelete.open(cliente)

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id }
  }, [debQueryParams, selectedAzienda.id])

  const filtersTags = useMemo(() => {
    return {
      azienda: selectedAzienda.id,
    }
  }, [selectedAzienda.id])

  const [
    { list, loading, pagination },
    { addCliente, updateCliente, removeCliente, updateTagsCliente },
  ] = useRunRj(ClienteState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: tags }] = useRunRj(TagState, [filtersTags])

  const isProductionEnv = process.env.NODE_ENV === "production"
  const host = isProductionEnv ? window.location.host : "localhost:8000"
  const protocol = window.location.protocol

  const exportToken = useExportToken()

  const { user } = useAuthUser()

  const linkCsv = useMemo(() => {
    return `${protocol}//${host}/api/clienti-export/csv?${qs.stringify({
      ...filters,
      export_token: exportToken,
    })}`
  }, [exportToken, filters, host, protocol])

  const linkPdf = useMemo(() => {
    return `${protocol}//${host}/api/clienti-export/pdf?${qs.stringify({
      ...filters,
      export_token: exportToken,
    })}`
  }, [exportToken, filters, host, protocol])

  const isNotEmployee = useMemo(() => {
    if (user.profilo && user.profilo.permessi !== "employee") {
      return true
    }
  }, [user.profilo])

  return (
    <Layout title="Clienti">
      <div>
        <Card className="card-filters">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  style={{ width: isMobile ? "100%" : undefined }}
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <TextField
                  variant="outlined"
                  select
                  style={{ width: 320, marginTop: isMobile ? 5 : 0 }}
                  size="small"
                  label="Filtra Tag"
                  value={queryParams.tag ?? ""}
                  name="tag"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.tag}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="start"
                        >
                          <Cancel
                            color={!queryParams.tag ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) =>
                    setDebQueryParams({ tag: event.target.value })
                  }
                >
                  <MenuItem value=""></MenuItem>
                  {tags &&
                    tags.map((tag) => (
                      <MenuItem key={tag.id} value={tag.id}>
                        {tag.nome}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
            {isNotEmployee && (
              <Grid item md={4} xs={12}>
                <div
                  className={
                    isMobile
                      ? "d-flex justify-content-center pt-5"
                      : "text-right"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetApp />}
                    href={linkCsv}
                  >
                    Excel
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    startIcon={<GetApp />}
                    href={linkPdf}
                  >
                    Pdf
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Cliente
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListClienti
            pagination={pagination}
            columnProps={columnProps}
            clienti={list}
            onUpdate={onUpdate}
            onAssocia={onAssocia}
            onDelete={onDelete}
          />
        )}
        <ModalFormCliente
          save={(cliente) => {
            return addCliente
              .onSuccess(() => actionsModalCreate.close())
              .asPromise({ ...cliente, azienda: selectedAzienda.id })
          }}
          isOpen={modalCreate.isOpen}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormAssociaTags
          save={(tags) => {
            return updateTagsCliente
              .onSuccess(() => {
                actionsModalAssociaTags.close()
              })
              .asPromise(modalAssociaTags.value.id, tags)
          }}
          isOpen={modalAssociaTags.isOpen}
          tags={tags && tags.length > 0 && tags.filter((t) => t.azienda)}
          cliente={modalAssociaTags.value}
          toggle={actionsModalAssociaTags.toggle}
          onExited={() => {
            actionsModalAssociaTags.onClosed()
          }}
        />
        <ModalFormCliente
          save={(cliente) => {
            return updateCliente
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({ ...cliente, azienda: selectedAzienda.id })
          }}
          cliente={modalUpdate.value}
          isOpen={modalUpdate.isOpen}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={
            deleteCliente
              ? `Rimuovere ${deleteCliente.nome} ${deleteCliente.cognome}?`
              : null
          }
          text={
            deleteCliente
              ? `Sei sicuro di voler eliminare il cliente ${deleteCliente.nome} ${deleteCliente.cognome}?`
              : null
          }
          onDelete={() => {
            return removeCliente
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
