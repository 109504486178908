import {
  Card,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core"
import { KeyboardBackspace, Save } from "@material-ui/icons"
import React, { useCallback, useEffect, useState } from "react"
import * as Yup from "yup"
import { Alert } from "@material-ui/lab"
import { Formik, Field } from "formik"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import ButtonSave from "../../components/ButtonSave"
import { InputField } from "../../components/Form"
import { usePasswordRecover } from "../../state/passwordRecover"
import { Link, useHistory } from "react-router-dom"
import { isMobile } from "react-device-detect"
import defaultTheme from "../../theme"

const RecoverSchema = Yup.object().shape({
  email: Yup.string().required("Inserisci un indirizzo -email"),
})

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [modalRecoverPassword, setModalRecoverPassword] = useState(false)
  const [{ pending }, { run: recoverPassword }] = usePasswordRecover()
  const [alertRecover, setAlertRecover] = useState(false)
  const history = useHistory()

  const toggleModalRecover = useCallback(() => {
    setModalRecoverPassword((v) => !v)
    setAlertRecover(false)
  }, [])

  return (
    <div
      style={{
        backgroundImage: "url(/sfondo-salonify.png",
        height: '100vh',
        //width: '100%',
        backgroundSize: 'cover',
        backgroundPositionX: '',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <form
        className="row mt-5 p-2"
        onSubmit={(e) => {
          e.preventDefault()
          if (email !== "" && password !== "") {
            login({ email, password })
          }
        }}
      >
        <Card
          style={{
            borderRadius: isMobile ? 0 : 4,
            margin: isMobile ? 0 : "auto",
            position: 'absolute',
            top: '10%',
            left: 0,
            right: 0,
            width: isMobile ? "100%" : 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CardHeader
            className="card-login"
            title={
              <div style={{ textAlign: "center" }}>
                <img
                  height={isMobile ? 30 : 40}
                  src="/salonify.png"
                  alt="Salonify"
                />
              </div>
            }
          />
          <CardContent>
            <div className="form-group" style={{ paddingTop: 20 }}>
              <TextField
                placeholder="@email"
                variant="outlined"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => {
                  clearLoginError()
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                variant="outlined"
                placeholder="password"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => {
                  clearLoginError()
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="pointer link-primary"
                style={{ marginTop: 20 }}
                onClick={toggleModalRecover}
              >
                Password dimenticata?{" "}
              </div>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                type="submit"
                color="primary"
                disabled={loginLoading}
              >
                {!loginLoading ? "Accedi" : "Logged in..."}
              </Button>
            </div>
            {loginError && (
              <Alert variant="filled" color="error" style={{ marginTop: 20 }}>
                Bad combination of email and password.
              </Alert>
            )}
          </CardContent>
          <CardContent
            style={{
              borderTop: "1px solid #CCC",
              textAlign: "center",
              height: isMobile ? "100vh" : undefined,
            }}
          >
            <div style={{ textAlign: "center" }}>
              Non hai ancora un account per la tua azienda?
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                color="secondary"
                onClick={() => history.push("/registrazione-azienda")}
                variant="contained"
              >
                Registrati
              </Button>
            </div>
          </CardContent>
        </Card>
        <Dialog open={modalRecoverPassword} onClose={toggleModalRecover}>
          <DialogTitle onClose={toggleModalRecover}>
            Recover Password
          </DialogTitle>

          <Formik
            validationSchema={RecoverSchema}
            initialValues={{ email: "" }}
            onSubmit={(values, actions) => {
              if (!pending) {
                recoverPassword
                  .onSuccess(() => setAlertRecover(true))
                  .onFailure(() => setAlertRecover(true))
                  .run(values.email)
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <div style={{ marginBottom: 10 }}>
                    Inserisci la tua e-mail per resettare la tua password.
                  </div>
                  {alertRecover && (
                    <Alert
                      variant="filled"
                      color="success"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Controlla la tua e-mail. E clicca sul link di ripristino
                      password.
                    </Alert>
                  )}
                  <Field
                    disabled={alertRecover}
                    label={"E-mail"}
                    name="email"
                    component={InputField}
                  />
                </DialogContent>
                <DialogActions
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    size="small"
                    startIcon={<KeyboardBackspace />}
                    variant="outlined"
                    onClick={toggleModalRecover}
                  >
                    Chiudi
                  </Button>
                  {!alertRecover && (
                    <ButtonSave
                      size="small"
                      disabled={isSubmitting}
                      type="submit"
                      startIcon={<Save />}
                      variant="outlined"
                    >
                      {isSubmitting ? "Ripristinando..." : "Ripristina"}
                    </ButtonSave>
                  )}
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </form>
    </div>
  )
}
