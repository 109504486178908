import { useMemo } from 'react'
import { rj, useRj } from 'react-rocketjump'
import { useAuthActions } from 'use-eazy-auth'
import api from '../api'

const UpdateUserProfileState = rj({
  name: 'UserProfile',
  effectCaller: 'configured',
  effect: (t) => (userData) => api.auth(t).put(`/api/me`, userData),
})

const ChangePasswordState = rj({
  name: 'ChangePassword',
  effectCaller: 'configured',
  effect: (t) => (userData) =>
    api.auth(t).put(`/api/change-password`, userData),
})

export function useUpdateUser() {
  const { updateUser } = useAuthActions()
  const [state, { run }] = useRj(UpdateUserProfileState)

  const updateUserRun = useMemo(
    () =>
      run
        .onSuccess((user) => {
          updateUser(user)
        })
        .curry(),
    [run, updateUser]
  )

  return [updateUserRun, state]
}

export function useChangePassword() {
  const [state, { run }] = useRj(ChangePasswordState)

  return [run, state]
}
