import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import { InputField, setNullOnEmptyValues, transformErrorForForm } from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Grid,
  InputAdornment,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { useAuthUser } from "use-eazy-auth"

const ServizioSchema = Yup.object().shape({
  prestazione: Yup.string().required("La prestazione è obbligatoria"),
  prezzo: Yup.string().required("Il prezzo è obbligatorio"),
  user: Yup.number().required("Utente è obbligatorio"),
})

export default function ModalFormServizio({
  servizio,
  save,
  isOpen,
  toggle,
  onExited,
  prestazioni,
  utenti,
}) {
  const { user } = useAuthUser()

  const INITIAL_VALUES = {
    prestazione: "",
    prezzo: "",
    percentuale_sconto: "0",
    user: user.profilo.permessi !== "admin" ? user.id : "",
  }

  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {servizio ? "Modifica" : "Inserimento"} servizio
      </DialogTitle>
      <Formik
        validationSchema={ServizioSchema}
        initialValues={servizio ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    label="Prestazione"
                    name="prestazione"
                    component={InputField}
                    onChange={(e) => {
                      setFieldValue("prestazione", e.target.value)
                      setFieldValue(
                        "prezzo",
                        prestazioni.filter((p) => p.id === e.target.value)[0]
                          .prezzo_base
                      )
                    }}
                    select={true}
                  >
                    <MenuItem value="">---</MenuItem>
                    {prestazioni &&
                      prestazioni.map((prestazione) => (
                        <MenuItem key={prestazione.id} value={prestazione.id}>
                          {prestazione.titolo}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Prezzo"
                    type="text"
                    name="prezzo"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Sconto"
                    type="text"
                    name="percentuale_sconto"
                    component={InputField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Utente"
                    margin="normal"
                    select={true}
                    component={InputField}
                    name="user"
                  >
                    <MenuItem value="">---</MenuItem>
                    {utenti &&
                      utenti.map((utente) => (
                        <MenuItem value={utente.id}>{utente.name}</MenuItem>
                      ))}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Note"
                    component={InputField}
                    multiline
                    name="note"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {servizio ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
