import React, { useEffect, useState } from "react"
import { getIn } from "formik"
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@material-ui/core"
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from "moment"
import "moment/locale/it"
import MomentUtils from "@date-io/moment"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "quill-mention"
import "quill-mention/dist/quill.mention.css"

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  noHelperText = false,
  onChange,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value: formValue, ...passField } = field
  let value
  if (relaxed && (formValue === undefined || formValue === null)) {
    value = ""
  } else {
    value = formValue
  }

  return (
    <TextField
      value={value}
      label={label ? label : undefined}
      variant="outlined"
      fullWidth
      {...passField}
      {...props}
      onChange={(e) => {
        if (onChange) {
          const shouldChange = onChange(e)
          if (shouldChange === false) {
            e.preventDefault()
            return
          }
        }
        passField.onChange(e)
      }}
      error={!!(error && touch)}
      helperText={noHelperText ? "" : error && touch ? error : undefined}
    />
  )
}

export const PhoneNumberField = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const value = field.value

  return (
    <MuiPhoneNumber
        defaultCountry={'it'}
        variant='outlined'
        fullWidth
        regions={['europe']}
        value={value}
        label={label ? label : undefined}
        onChange={(value) => {
          if (value) {
            form.setFieldValue(field.name, value.replace(/\s/g, ''))
          } else {
            form.setFieldValue(value, null)
          }
        }}
        {...props}
      />
  )
}

export const ReminderMinutesFields = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const value = field.value

  return (
    <FormControl fullWidth>
      <InputLabel style={{paddingLeft: '15px'}}>Minuti anticipo SMS</InputLabel>
      <Select
        variant="outlined"
        value={value}
        label={label ? label : undefined}
        onChange={(value) => {
          if (value){
            form.setFieldValue(field.name, value.target.value)
          }else{
            form.setFieldValue(field.name, null)
          }
        }}
        {...props}
      >
        <MenuItem value={null}> Nessuna notifica </MenuItem>
        <MenuItem value={5}>5 mins</MenuItem>  
        <MenuItem value={15}>15 mins</MenuItem>  
        <MenuItem value={30}>30 mins</MenuItem>
        <MenuItem value={45}>45 mins</MenuItem>
        <MenuItem value={60}>1 ora</MenuItem>  
        <MenuItem value={120}>2 ore</MenuItem>
        <MenuItem value={360}>6 ore</MenuItem>  
        <MenuItem value={720}>12 ore</MenuItem>  
        <MenuItem value={1440}>24 ore</MenuItem>  
      </Select>
    </FormControl>
    // <MuiPhoneNumber
    //     defaultCountry={'it'}
    //     variant='outlined'
    //     fullWidth
    //     regions={['europe']}
    //     value={value}
    //     label={label ? label : undefined}
    //     onChange={(value) => {
    //       if (value) {
    //         form.setFieldValue(field.name, value.replace(/\s/g, ''))
    //       } else {
    //         form.setFieldValue(value, null)
    //       }
    //     }}
    //     {...props}
    //   />
  )
}

export const SwitchCheckboxField = ({
  field,
  label,
  form,
  checked,
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={field.value}
          onChange={form.handleChange}
          name={field.name}
        />
      }
      label={label}
    />
  )
}

export const DateField = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        cancelLabel={"Annulla"}
        format="DD/MM/YYYY"
        inputVariant="outlined"
        name={field.name}
        InputLabelProps={{ shrink: true }}
        error={!!(error && touch)}
        helperText={error && touch ? error : undefined}
        id={field.name}
        label={label}
        value={field.value || null}
        onChange={(date) =>
          form.setFieldValue(field.name, moment(date).format("YYYY-MM-DD"))
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export const TimeField = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const timeValue = field.value ? moment(field.value, "HH:mm") : null

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        ampm={false}
        disableToolbar
        variant="dialog"
        cancelLabel={"Annulla"}
        inputVariant="outlined"
        name={field.name}
        error={!!error}
        InputLabelProps={{ shrink: true }}
        helperText={error || touch ? error : undefined}
        id={field.name}
        label={label}
        value={timeValue || null}
        onChange={(time) => {
          if (time) {
            form.setFieldValue(field.name, time.format("HH:mm"))
          } else {
            form.setFieldValue(field.name, null)
          }
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

const quillModules = {
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function (searchTerm, renderList, mentionChar) {
      const values = [
        {
          id: "nome",
          value: "nome",
        },
        {
          id: "cognome",
          value: "cognome",
        },
        {
          id: "mese_nascita",
          value: "mese nascita",
        },
        {
          id: "anno_nascita",
          value: "anno nascita",
        },
        {
          id: "giorno_nascita",
          value: "giorno nascita",
        },
        {
          id: "anno_corrente",
          value: "anno corrente",
        },
        {
          id: "mese_corrente",
          value: "mese corrente",
        },
      ]
      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
  },
}

export const RichTextNewsletterField = ({ field, ...props }) => {
  return (
    <ReactQuill
      {...props}
      modules={quillModules}
      value={field.value}
      onChange={field.onChange(field.name)}
    />
  )
}

export const FileField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  noHelperText = false,
  onChange,
  accept,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field

  return (
    <TextField
      {...passField}
      {...props}
      variant="outlined"
      value={undefined}
      label={label ? label : undefined}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        // Is this the real life? Is this just fantasy?
        inputProps: {
          accept,
        },
      }}
      type="file"
      onChange={(e) => {
        setFieldValue(field.name, e.target.files[0] ?? null)
      }}
      error={!!(error && touch)}
      helperText={noHelperText ? "" : error && touch ? error : undefined}
    />
  )
}

export const ImageField = ({ field, ...props }) => {
  const { value } = field

  return (
    <>
      <FileField accept={"image/*"} field={field} {...props} />
      <ImagePreview fileOrImage={value} />
    </>
  )
}

function useObjectURL(file) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (file) {
      const createdUrl = URL.createObjectURL(file)
      setUrl(createdUrl)
      return () => {
        URL.revokeObjectURL(createdUrl)
      }
    } else {
      setUrl(null)
    }
  }, [file])

  return url
}

function ImagePreview({ fileOrImage }) {
  const url = useObjectURL(typeof fileOrImage === "string" ? null : fileOrImage)

  if (!fileOrImage) {
    return null
  }

  const previewUrl = typeof fileOrImage === "string" ? fileOrImage : url

  return (
    <div style={{ border: "1px solid #CCC", padding: 10, marginTop: 10 }}>
      <img
        // width="250"
        style={{ maxHeight: 220, maxWidth: "100%" }}
        src={previewUrl}
        alt="preview"
      />
    </div>
  )
}
