import React from "react"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core"
import { KeyboardBackspace, Mail } from "@material-ui/icons"
import moment from "moment"

export default function ModalInviaNewsletter({
  isOpen,
  toggle,
  onExited,
  inviaNewsletter,
  newsletter,
}) {
  return (
    <Dialog open={isOpen} fullWidth={true} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>Invia Newsletter</DialogTitle>
      <DialogContent dividers>
        Sei sicuro di voler inviare la newsletter?
        {newsletter.data && (
          <div>
            Questa newsletter era programmata per essere inviata in data{" "}
            <strong>{moment(newsletter.data).format("DD/MM/YYYY")}.</strong>{" "}
            <br />
            L'invio immediato annullerà la programmazione di invio.
          </div>
        )}
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          startIcon={<KeyboardBackspace />}
          variant="outlined"
          onClick={toggle}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          startIcon={<Mail />}
          title="Invia Newsletter"
          color="primary"
          size="small"
          variant="contained"
          onClick={inviaNewsletter}
        >
          Invia Newsletter
        </Button>
      </DialogActions>
    </Dialog>
  )
}
