import React from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { green } from "@material-ui/core/colors"
import { Save } from "@material-ui/icons"
import defaultTheme from "../theme"

const ColorButton = withStyles((theme) => ({
  root: {
    borderColor: defaultTheme.palette.success.main,
    color: defaultTheme.palette.success.dark,
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

export default function ButtonSave({ children, noSubmit = false, ...props }) {
  const classes = useStyles()

  return (
    <ColorButton
      variant="outlined"
      type={noSubmit ? "button" : "submit"}
      {...props}
      startIcon={<Save />}
    >
      {children}
    </ColorButton>
  )
}
