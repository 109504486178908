import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import Layout from "../../components/Layout/Layout"
import { NewsletterDetailState, SendNewsletter } from "../../state/newsletters"
import { useRj, useRunRj } from "react-rocketjump"
import { useParams } from "react-router-dom"
import { Field, Formik } from "formik"
import {
  DateField,
  ImageField,
  InputField,
  RichTextNewsletterField,
} from "../../components/Form"
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  Tab,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  AppBar,
  Tabs,
  Button,
  Box,
} from "@material-ui/core"
import find from "lodash/find"
import { Close, ExpandMore, Mail, Save } from "@material-ui/icons"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { Alert, Autocomplete } from "@material-ui/lab"
import { ClienteBigState } from "../../state/clienti"
import ReactHtmlParser from "react-html-parser"
import { TagState } from "../../state/tags"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { TemplatesNewsletterState } from "../../state/templatesNewsletter"
import moment from "moment"
import "./MessaggioDettaglio.css"
import ModalInviaNewsletter from "../../components/ModalInviaNewsletter"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function useObjectURL(file) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (file) {
      const createdUrl = URL.createObjectURL(file)
      setUrl(createdUrl)
      return () => {
        URL.revokeObjectURL(createdUrl)
      }
    } else {
      setUrl(null)
    }
  }, [file])

  return url
}

function ImagePreview({ fileOrImage }) {
  const url = useObjectURL(typeof fileOrImage === "string" ? null : fileOrImage)

  if (!fileOrImage) {
    return null
  }

  const previewUrl = typeof fileOrImage === "string" ? fileOrImage : url

  return <img style={{ maxWidth: "100%" }} src={previewUrl} alt="preview" />
}

function isEmptyHtml(html) {
  const div = document.createElement("div")
  div.innerHTML = html
  return div.innerText.trim() === ""
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const VisitaSchema = Yup.object().shape({
  titolo: Yup.string().required("Titolo è obbligatorio"),
  template: Yup.string(),
  // data: Yup.date().when("programmazione_invio", {
  //   is: 0, // alternatively: (val) => val == true
  //   then: Yup.date().required().typeError("Data obbligatoria"),
  // }),
})

export default function MessaggioDettaglio() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const { id } = useParams()
  const [
    { data: newsletter },
    { updateNewsletter, run: refresh },
  ] = useRunRj(NewsletterDetailState, [id])

  const [{}, { run: inviaNewsletter }] = useRj(SendNewsletter, [id])

  const [alertSuccess, setAlertSuccess] = useState()

  const [contenutoNewsletter, setShowContenutoNewsletter] = useState(false)

  const [modalInviaNewsletter, actionsInviaNewsletter] = useModalTrigger()

  const toggleShowContenutoNewsletter = () =>
    setShowContenutoNewsletter(!contenutoNewsletter)

  const filters = useMemo(() => {
    return { azienda: selectedAzienda.id }
  }, [selectedAzienda.id])

  const filtersTags = useMemo(() => {
    return { azienda: selectedAzienda.id, show_invisibili: true }
  }, [selectedAzienda.id])

  const [{ list: tags }] = useRunRj(TagState, [filtersTags])
  const [{ list: clienti }] = useRunRj(ClienteBigState, [filters])
  const [{ list: templates }] = useRunRj(TemplatesNewsletterState)

  const clientiOptions = useMemo(() => {
    if (clienti) {
      return clienti.map((cliente) => ({
        id: cliente.id,
        nome: cliente.nome + " " + cliente.cognome,
      }))
    }
    return []
  }, [clienti])

  const clientiInitials = useMemo(() => {
    if (
      newsletter &&
      newsletter.clienti_data &&
      newsletter.clienti_data.length > 0
    ) {
      return newsletter.clienti_data.map((cliente) => ({
        id: cliente.id,
        nome: cliente.nome + " " + cliente.cognome,
      }))
    }
    return []
  }, [newsletter])

  const tagsInitials = useMemo(() => {
    if (newsletter && newsletter.tags_data && newsletter.tags_data.length > 0) {
      return newsletter.tags_data.map((tag) => ({
        id: tag.id,
        nome: tag.nome,
        colore: tag.colore,
      }))
    }
    return []
  }, [newsletter])

  const tagsOptions = useMemo(() => {
    if (tags) {
      return tags.map((tag) => ({
        id: tag.id,
        nome: tag.nome,
        colore: tag.colore,
      }))
    }
    return []
  }, [tags])

  const isInviata = newsletter && newsletter.inviato_il ? true : false

  const initialValues = useMemo(() => {
    if (!newsletter) {
      return null
    }

    let toValues = {
      ...newsletter,
      programmazione_invio: 0,
      template: newsletter.template ?? "",
      clienti: clientiInitials,
      tags: tagsInitials,
      tutti_i_clienti: newsletter.tutti_i_clienti ? "all" : "filtered",
    }

    // Set first templates only when template and testo null (never saved)
    if (templates && templates.length > 0 && newsletter.testo === null) {
      toValues = {
        ...toValues,
        template: newsletter.template ?? templates[0].id,
        immagine: newsletter.immagine ?? templates[0].immagine,
        testo: newsletter.testo ?? templates[0].testo,
        template_immagine: newsletter.template ? "" : templates[0].id,
      }
    }

    return toValues
  }, [clientiInitials, newsletter, tagsInitials, templates])

  return (
    <Layout title={`Newsletter - ${newsletter && newsletter.titolo}`}>
      <div>
        {newsletter && templates && (
          <div className="mt-4">
            <Formik
              validationSchema={VisitaSchema}
              initialValues={initialValues}
              onSubmit={(entity) => {
                if (entity.programmazione_invio === 0) {
                  const fd = new FormData()
                  fd.append("azienda", entity.azienda)
                  fd.append("titolo", entity.titolo)
                  fd.append("template", entity.template)
                  fd.append("data", entity.data || "")
                  fd.append("testo", entity.testo)
                  fd.append(
                    "tutti_i_clienti",
                    entity.tutti_i_clienti === "all" ? true : false
                  )
                  if (entity.immagine && entity.immagine instanceof File) {
                    fd.append("immagine", entity.immagine)
                  } else if (entity.template_immagine) {
                    fd.append("template_immagine", entity.template_immagine)
                  }
                  if (
                    entity.clienti.length > 0 &&
                    entity.tutti_i_clienti === "filtered"
                  ) {
                    entity.clienti.forEach((cliente) => {
                      fd.append("clienti", cliente.id)
                    })
                  }
                  if (
                    entity.tags.length > 0 &&
                    entity.tutti_i_clienti === "filtered"
                  ) {
                    entity.tags.forEach((tag) => {
                      fd.append("tags", tag.id)
                    })
                  }
                  return updateNewsletter
                    .onSuccess(() => {
                      setAlertSuccess(true)
                    })
                    .asPromise(id, fd)
                } else {
                  const fd = new FormData()
                  fd.append("azienda", entity.azienda)
                  fd.append("titolo", entity.titolo)
                  fd.append("template", entity.template)
                  fd.append("data", entity.data || "")
                  fd.append("testo", entity.testo)
                  fd.append(
                    "tutti_i_clienti",
                    entity.tutti_i_clienti === "all" ? true : false
                  )
                  if (entity.immagine && entity.immagine instanceof File) {
                    fd.append("immagine", entity.immagine)
                  } else if (entity.template_immagine) {
                    fd.append("template_immagine", entity.template_immagine)
                  }
                  if (
                    entity.clienti.length > 0 &&
                    entity.tutti_i_clienti === "filtered"
                  ) {
                    entity.clienti.forEach((cliente) => {
                      fd.append("clienti", cliente.id)
                    })
                  }
                  if (
                    entity.tags.length > 0 &&
                    entity.tutti_i_clienti === "filtered"
                  ) {
                    entity.tags.forEach((tag) => {
                      fd.append("tags", tag.id)
                    })
                  }
                  return updateNewsletter
                    .onSuccess(() => {
                      return inviaNewsletter
                        .onSuccess(() => {
                          refresh(id)
                          window.scrollTo(0, 0)
                        })
                        .asPromise(id, fd)
                    })
                    .asPromise(id, fd)
                }
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                setFieldValue,
                submitForm,
              }) => (
                <>
                  <Card>
                    <CardHeader
                      title={
                        isInviata
                          ? "Dettaglio Newsletter"
                          : `Aggiorna Newsletter`
                      }
                    />
                    <CardContent>
                      <div className="container-fluid mt-2">
                        {newsletter.inviato_il && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <Chip
                              variant="default"
                              size="medium"
                              color="primary"
                              label={`Newsletter inviata in data: ${moment(
                                newsletter.inviato_il
                              ).format("DD-MM-YYYY HH:mm")}`}
                            />
                          </div>
                        )}
                        <>
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <Field
                                  label={"Titolo"}
                                  name="titolo"
                                  disabled={isInviata}
                                  component={InputField}
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Field
                                  label="Template"
                                  disabled={isInviata}
                                  name="template"
                                  component={InputField}
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      setShowContenutoNewsletter(true)
                                    }
                                    const tplId = e.target.value
                                    const tpl = find(templates, { id: tplId })
                                    if (tpl) {
                                      let showConfirm = false
                                      // TODO: Ok c'è ancora un problema
                                      // l'html può diffire per cose come spazi
                                      // quindi utilizzare === non è proprio il
                                      // massimo
                                      if (!isEmptyHtml(values.testo)) {
                                        // Controlliamo se mostrare conferma sole
                                        // se abbiamo del testo ceh potenzialemnte verrà
                                        // sovrascritto
                                        if (values.template) {
                                          // Abbiamo un template settato
                                          const actualTpl = find(templates, {
                                            id: values.template,
                                          })
                                          if (
                                            actualTpl &&
                                            values.testo === actualTpl.testo
                                          ) {
                                            // In questo caso il valore del testo corrente
                                            // è uguale al template settato quindi non
                                            // si "perdono" modifiche
                                            showConfirm = false
                                          } else {
                                            showConfirm = showConfirm =
                                              tpl.testo !== values.testo
                                          }
                                        } else {
                                          showConfirm =
                                            tpl.testo !== values.testo
                                        }
                                      }
                                      if (showConfirm) {
                                        const update = window.confirm(
                                          "Attenzione, tutte le modifiche al testo andranno perse proseguire?"
                                        )
                                        if (!update) {
                                          return false
                                        }
                                      }
                                      setFieldValue("testo", tpl.testo)
                                      if (tpl.immagine) {
                                        setFieldValue("immagine", tpl.immagine)
                                        setFieldValue(
                                          "template_immagine",
                                          tpl.id
                                        )
                                      } else {
                                        setFieldValue("template_immagine", null)
                                      }
                                    }
                                  }}
                                  select={true}
                                >
                                  <MenuItem value="">Nessun Template</MenuItem>
                                  {templates &&
                                    templates.map((tpl) => (
                                      <MenuItem key={tpl.id} value={tpl.id}>
                                        {tpl.titolo}
                                      </MenuItem>
                                    ))}
                                </Field>
                              </Grid>
                              <Grid container>
                                <Accordion
                                  expanded={contenutoNewsletter}
                                  onChange={toggleShowContenutoNewsletter}
                                  style={{
                                    width: "100%",
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginTop: 10,
                                    marginBottom: 20,
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography style={{ paddingLeft: 10 }}>
                                      Contenuto Newsletter
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={3}>
                                      <Grid item md={8} xs={12}>
                                        <Field
                                          name="testo"
                                          className="MessaggioRighText"
                                          readOnly={isInviata}
                                          component={RichTextNewsletterField}
                                        />
                                      </Grid>
                                      <Grid item md={4} xs={12}>
                                        <Field
                                          label={"Immagine"}
                                          disabled={isInviata}
                                          name="immagine"
                                          component={ImageField}
                                        />
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                              <Card
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  width: "100%",
                                }}
                              >
                                <CardHeader title="Preview Newsletter" />
                                <CardContent>
                                  <div
                                    style={{
                                      backgroundColor: "rgb(2, 66, 87,0.5)",
                                      textAlign: "center",
                                      padding: 20,
                                    }}
                                  >
                                    <table style={{ width: "100%" }}>
                                      <tr>
                                        <td width="5%"></td>
                                        <td
                                          style={{
                                            backgroundColor: "#FFF",
                                            padding: 20,
                                          }}
                                        >
                                          <ImagePreview
                                            fileOrImage={values.immagine}
                                          />
                                          <div
                                            style={{
                                              textAlign: "justify",
                                              padding: 20,
                                              backgroundColor: "white",
                                            }}
                                          >
                                            {ReactHtmlParser(values.testo)}
                                          </div>
                                          <hr />
                                          <div>
                                            <small>
                                              <b>Mail sent from Salonify for {selectedAzienda.rag_sociale} </b>
                                              <br />
                                            </small>
                                          </div>
                                        </td>
                                        <td width="5%"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </CardContent>
                              </Card>
                              <Card
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  width: "100%",
                                }}
                              >
                                <CardHeader title="Clienti" />
                                <CardContent>
                                  <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        row
                                        aria-label="Clienti"
                                        name="tutti_i_clienti"
                                        value={values.tutti_i_clienti}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "tutti_i_clienti",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          disabled={isInviata}
                                          value="all"
                                          control={<Radio />}
                                          label="Tutti i clienti"
                                        />
                                        <FormControlLabel
                                          value="filtered"
                                          disabled={isInviata}
                                          control={<Radio />}
                                          label="Filtra i Clienti"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {values.tutti_i_clienti === "filtered" && (
                                      <Autocomplete
                                        multiple
                                        disabled={isInviata}
                                        noOptionsText={"Nessun tag trovato"}
                                        value={values.tags}
                                        style={{ marginTop: 10 }}
                                        id="size-small-standard-multi-2"
                                        size="small"
                                        name="tags"
                                        onChange={(e, value) =>
                                          setFieldValue("tags", value)
                                        }
                                        getOptionSelected={(option, value) =>
                                          option.id === value.id
                                        }
                                        options={tagsOptions}
                                        getOptionLabel={(option) => option.nome}
                                        renderTags={(value, getTagProps) =>
                                          value.map((option, index) => (
                                            <Chip
                                              size="small"
                                              style={{
                                                background: option.colore,
                                                color: "#FFF",
                                              }}
                                              label={option.nome}
                                              {...getTagProps({ index })}
                                            />
                                          ))
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Tags"
                                            placeholder="Aggiungi Clienti in base ai Tags"
                                          />
                                        )}
                                      />
                                    )}
                                    {clienti &&
                                      values.tutti_i_clienti === "filtered" && (
                                        <Autocomplete
                                          multiple
                                          disabled={isInviata}
                                          style={{ marginTop: 20 }}
                                          noOptionsText={
                                            "Nessun cliente trovato"
                                          }
                                          value={values.clienti}
                                          id="size-small-standard-multi"
                                          size="small"
                                          name="clienti"
                                          onChange={(e, value) =>
                                            setFieldValue("clienti", value)
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.id === value.id
                                          }
                                          options={clientiOptions}
                                          getOptionLabel={(option) =>
                                            option.nome
                                          }
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                size="small"
                                                label={option.nome}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              label="Clienti"
                                              placeholder="Aggiungi Clienti"
                                            />
                                          )}
                                        />
                                      )}
                                  </Grid>
                                </CardContent>
                              </Card>
                              {!newsletter.inviato_il && (
                                <Card style={{ margin: 10, width: "100%" }}>
                                  <CardHeader title="Programmazione invio" />
                                  <CardContent>
                                    <AppBar
                                      variant="outlined"
                                      position="static"
                                    >
                                      <Tabs
                                        value={values.programmazione_invio}
                                        onChange={(e, newValue) =>
                                          setFieldValue(
                                            "programmazione_invio",
                                            newValue
                                          )
                                        }
                                        aria-label="Programmazione Invio"
                                      >
                                        <Tab
                                          label="Invio posticipato"
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label="Invio immediato"
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>
                                    </AppBar>
                                    <TabPanel
                                      value={values.programmazione_invio}
                                      index={0}
                                    >
                                      {alertSuccess && (
                                        <Alert
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setAlertSuccess(false)
                                              }}
                                            >
                                              <Close fontSize="inherit" />
                                            </IconButton>
                                          }
                                          style={{ marginBottom: 20 }}
                                          color="success"
                                        >
                                          Newsletter salvata con successo.
                                        </Alert>
                                      )}
                                      Seleziona una data di invio per la
                                      newsletter.
                                      <Grid>
                                      <Field
                                        style={{ marginTop: 15 }}
                                        label={"Data"}
                                        name="data"
                                        disabled={isInviata}
                                        component={DateField}
                                      />
                                      </Grid>
                                      <Button
                                        size="large"
                                        startIcon={<Save />}
                                        style={{ marginTop: 20 }}
                                        disabled={isSubmitting}
                                        type="submit"
                                        color="primary"
                                        variant="outlined"
                                      >
                                        Salva
                                      </Button>
                                    </TabPanel>
                                    <TabPanel
                                      value={values.programmazione_invio}
                                      index={1}
                                    >
                                      <div>
                                        Vuoi procedere all'invio della
                                        newsletter?
                                      </div>
                                      <Button
                                        size="large"
                                        disabled={
                                          (values.clienti.length === 0 &&
                                            values.tags.length === 0 &&
                                            values.tutti_i_clienti !== "all") ||
                                          isSubmitting
                                        }
                                        startIcon={<Mail />}
                                        style={{ marginTop: 20 }}
                                        onClick={() =>
                                          actionsInviaNewsletter.open()
                                        }
                                        color="primary"
                                        variant="outlined"
                                      >
                                        Invia Newsletter
                                      </Button>
                                    </TabPanel>
                                  </CardContent>
                                </Card>
                              )}
                            </Grid>
                            <ModalInviaNewsletter
                              isOpen={modalInviaNewsletter.isOpen}
                              toggle={actionsInviaNewsletter.toggle}
                              newsletter={newsletter}
                              inviaNewsletter={() => {
                                submitForm(values)
                                actionsInviaNewsletter.close()
                              }}
                              onExited={() => {
                                actionsInviaNewsletter.onClosed()
                              }}
                            />
                          </form>
                        </>
                      </div>
                    </CardContent>
                  </Card>
                </>
              )}
            </Formik>
          </div>
        )}
      </div>
    </Layout>
  )
}
