import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import React from "react"
import { isMobile } from "react-device-detect"
import Column from "../Column/Column"

export default function ListServizi({
  servizi,
  onUpdate,
  onDelete,
  onDoubleClick,
  columnProps,
  totals,
}) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Column {...columnProps} field="prestazione__titolo">
              Prestazione
            </Column>
            <Column {...columnProps} field="prezzo">
              Prezzo
            </Column>
            {!isMobile && (
              <>
                <Column {...columnProps} field="percentuale_sconto">
                  Sconto
                </Column>
                <Column {...columnProps} field="note">
                  Note
                </Column>
                <Column {...columnProps} field="user.name">
                  Utente
                </Column>
              </>
            )}
            <TableCell>Azioni</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {servizi &&
            servizi.map((servizio) => (
              <TableRow key={servizio.id} onDoubleClick={() => onDoubleClick(servizio)} isExcluded={""+servizio.isExcluded}>
                <TableCell>{servizio.prestazione_data.titolo}</TableCell>
                <TableCell>
                  {servizio.prezzo ? `${servizio.prezzo} €` : ""}
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell>
                      {servizio.percentuale_sconto
                        ? `${servizio.percentuale_sconto} %`
                        : ""}
                    </TableCell>
                    <TableCell>{servizio.note}</TableCell>
                    <TableCell>
                      {servizio.utente_data ? servizio.utente_data.name : ""}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <IconButton
                    onClick={() => onUpdate(servizio)}
                    aria-label="modifica"
                    title="Modifica servizio"
                  >
                    <Edit style={{ color: "orange" }} />
                  </IconButton>
                  <IconButton
                    onClick={() => onDelete(servizio)}
                    aria-label="elimina"
                    title="Elimina servizio"
                  >
                    <Delete style={{ color: "red" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
