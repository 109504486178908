import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  lighten,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import { isMobile } from "react-device-detect"

export default function ListPrestazioni({
  servizi,
  onUpdate,
  onDelete,
  columnProps,
  pagination,
}) {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()
  return (
    <Card>
      <CardHeader title="Servizi" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="titolo">
                  Titolo
                </Column>
                {!isMobile && (
                  <Column {...columnProps} field="descrizione">
                    Descrizione
                  </Column>
                )}
                <Column {...columnProps} field="prezzo_base">
                  Prezzo
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servizi &&
                servizi.map((servizio) => (
                  <TableRow key={servizio.id}>
                    <TableCell>{servizio.titolo}</TableCell>
                    {!isMobile && <TableCell>{servizio.descrizione}</TableCell>}
                    <TableCell>{servizio.prezzo_base} &euro;</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onUpdate(servizio)}
                        aria-label="modifica"
                        title="Gestisci"
                      >
                        <Edit style={{ color: "orange" }} />
                      </IconButton>
                      {(servizio.num_servizi === 0 ||
                        !servizio.num_servizi) && (
                        <IconButton
                          onClick={() => onDelete(servizio)}
                          aria-label="elimina"
                          title="Cancella"
                        >
                          <Delete style={{ color: "red" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
