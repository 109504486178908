import React, { useMemo, useState } from "react"
import Layout from "../../components/Layout/Layout"
import * as Yup from "yup"
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardActions,
} from "@material-ui/core"
import ButtonSave from "../../components/ButtonSave"
import { Delete, RotateLeft, Save } from "@material-ui/icons"
import { InputField, transformErrorForForm } from "../../components/Form"
import { Field, Formik } from "formik"
import { PianiTariffariState } from "../../state/pianiTariffari"
import { useChangePassword, useUpdateUser } from "../../state/profilo"
import { OrdiniState } from "../../state/ordini"
import { useRunRj } from "react-rocketjump"
import { useAuthUser } from "use-eazy-auth"
import { Alert } from "@material-ui/lab"
import moment from "moment"
import {
  getStripe,
  onResetHackReinitializeToKeepDirtyUpdate,
} from "../../utils"
import { useSelectedAzienda } from "../../SelectedAzienda"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import ModalCancelPiano from "../../components/ModalCancelPiano"
import SceltaPianoTariffario from "../../components/SceltaPianoTariffario"
import defaultTheme from "../../theme"
import ModalCambioPiano from "../../components/ModalCambioPiano"
import { Prompt } from "react-router"

const UtenteSchema = Yup.object().shape({
  email: Yup.string().required("Campo obbligatorio"),
  name: Yup.string().required("Campo obbligatorio"),
})

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password obbligatoria"),
  conferma_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Le due password devono essere identiche"
  ),
})

const AziendaSchema = Yup.object().shape({
  p_iva: Yup.string().required("Campo obbligatorio"),
  rag_sociale: Yup.string().required("Campo obbligatorio"),
  city: Yup.string().required("Campo obbligatorio"),
  indirizzo: Yup.string().required("Campo obbligatorio"),
  cap: Yup.string().required("Campo obbligatorio"),
  provincia: Yup.string().required("Campo obbligatorio"),
  codice_fiscale: Yup.string().required("Campo obbligatorio"),
  civico: Yup.string().required("Campo obbligatorio"),
  pec: Yup.string().test(function (value) {
    const { codice_fatturazione } = this.parent
    if (!codice_fatturazione) return value != null
    return true
  }),
  codice_fatturazione: Yup.string().test(function (value) {
    const { pec } = this.parent
    if (!pec) return value != null
    return true
  }),
})

function PendingOrders({ isAdmin }) {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [{ data: orders }] = useRunRj(OrdiniState, [selectedAzienda.id])
  return (
    <Card>
      <CardHeader title="Pagamenti in sospeso" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Prodotto</TableCell>
                <TableCell>Prezzo</TableCell>
                <TableCell>Data</TableCell>
                {!isAdmin && <TableCell>Azioni</TableCell>}
              </TableRow>
            </TableHead>
            {orders && (
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.product_name}</TableCell>
                    <TableCell>{order.price}</TableCell>
                    <TableCell>
                      {moment(order.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    {!isAdmin && (
                      <TableCell>
                        <Button
                          color={order.invoice_url ? "secondary" : "primary"}
                          variant="contained"
                          size={"small"}
                          onClick={() => {
                            if (order.invoice_url) {
                              window.location.href = order.invoice_url
                            } else {
                              getStripe().then((stripe) => {
                                stripe.redirectToCheckout({
                                  sessionId: order.checkout_session_id,
                                })
                              })
                            }
                          }}
                          aria-label="paga"
                          title="Paga"
                        >
                          {order.invoice_url ? "Fattura" : "Paga Ora"}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default function Profilo() {
  const [{ data: piani }] = useRunRj(PianiTariffariState)
  const [alertSuccessUser, setAlertSuccessUser] = useState(false)
  const [alertSuccessAzienda, setAlertSuccessAzienda] = useState(false)
  const [alertSuccessPassword, setAlertSuccessPassword] = useState(false)
  const [
    alertSuccessPianoTariffario,
    setAlertSuccessPianoTariffario,
  ] = useState(false)

  const { user } = useAuthUser()

  const [{ selectedAzienda }, { updateAzienda }] = useSelectedAzienda()
  const [updateUser] = useUpdateUser()
  const [changePassword] = useChangePassword()

  const [modalCancelPiano, actionsCancelPiano] = useModalTrigger()
  const [modalCambioPiano, actionsCambioPiano] = useModalTrigger()

  const isDipendente = useMemo(() => {
    return user.profilo.permessi === "employee"
  }, [user.profilo.permessi])

  const isAdmin = useMemo(() => {
    return user.profilo.permessi === "admin"
  }, [user.profilo.permessi])

  const isManager = useMemo(() => {
    return user.profilo.permessi === "manager"
  }, [user.profilo.permessi])

  return (
    <Layout title="Profilo">
      {!isDipendente && (
        <Card>
          <CardHeader title="Scadenza licenza" />
          {selectedAzienda && (
            <CardContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selectedAzienda.is_valida
                    ? defaultTheme.palette.success.main
                    : defaultTheme.palette.error.main,
                }}
              >
                {selectedAzienda.scadenza_licenza
                  ? moment(selectedAzienda.scadenza_licenza).format(
                      "DD-MM-YYYY HH:ss"
                    )
                  : "Data scadenza non presente"}
              </div>
              {selectedAzienda.stripe_price_id &&
                isManager &&
                selectedAzienda.is_valida && (
                  <div>
                    <Button
                      startIcon={<Delete />}
                      style={{ background: "#cc0000", color: "white" }}
                      onClick={() => actionsCancelPiano.open()}
                      variant="contained"
                    >
                      Cancella Abbonamento
                    </Button>
                  </div>
                )}
              <Chip
                label={
                  selectedAzienda.is_valida
                    ? "Licenza attiva"
                    : "Licenza non attiva"
                }
                style={{
                  background: selectedAzienda.is_valida
                    ? defaultTheme.palette.success.main
                    : defaultTheme.palette.error.main,
                  color: "#FFF",
                }}
              />
            </CardContent>
          )}
          <CardActions style={{ borderTop: "1px solid #CCC" }}>
            {selectedAzienda.stripe_price_id && selectedAzienda.is_valida && (
              <div style={{ margin: 10 }}>
                Il tuo abbonamento verrà automaticamente rinnovato in data:{" "}
                <strong>
                  {selectedAzienda.scadenza_licenza
                    ? moment(selectedAzienda.scadenza_licenza).format(
                        "DD-MM-YYYY HH:ss"
                      )
                    : "Data scadenza non presente"}
                </strong>
              </div>
            )}{" "}
            {!selectedAzienda.stripe_price_id &&
              selectedAzienda.in_scadenza && (
                <div style={{ margin: 10 }}>
                  Non hai un abbonamento selezionato. Procedi alla selezione e
                  al pagamento del tuo abbonamento.
                </div>
              )}
          </CardActions>
        </Card>
      )}
      {isManager && (
        <Card>
          <CardHeader title={`Gestisci Abbonamento`} />
          <CardContent>
            <Formik
              key={selectedAzienda.id}
              validationSchema={AziendaSchema}
              onReset={onResetHackReinitializeToKeepDirtyUpdate}
              enableReinitialize={true}
              initialValues={selectedAzienda}
              onSubmit={(values, actions) => {
                return updateAzienda
                  .onSuccess(() => setAlertSuccessPianoTariffario(true))
                  .asPromise(values)
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                setFieldValue,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  {alertSuccessPianoTariffario && (
                    <Alert
                      onClose={() => setAlertSuccessPianoTariffario(false)}
                      color="success"
                      style={{ marginBottom: 20 }}
                    >
                      Abbonamento salvato con successo.
                    </Alert>
                  )}
                  <Grid container spacing={3}>
                    <Field
                      name={"stripe_price_id"}
                      component={SceltaPianoTariffario}
                      piani={piani}
                    />
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      size="small"
                      startIcon={<RotateLeft />}
                      disabled={
                        selectedAzienda.stripe_price_id ===
                        values.stripe_price_id
                      }
                      variant="outlined"
                      onClick={() =>
                        setFieldValue(
                          "stripe_price_id",
                          selectedAzienda.stripe_price_id
                        )
                      }
                    >
                      Reset
                    </Button>
                    <ButtonSave
                      size="small"
                      noSubmit={true}
                      disabled={
                        selectedAzienda.stripe_price_id ===
                          values.stripe_price_id || isSubmitting
                      }
                      //type="submit"
                      onClick={() => actionsCambioPiano.open()}
                      startIcon={<Save />}
                      variant="outlined"
                    >
                      Aggiorna Abbonamento
                    </ButtonSave>
                  </div>
                  <ModalCambioPiano
                    isOpen={modalCambioPiano.isOpen}
                    toggle={actionsCambioPiano.toggle}
                    update={() => {
                      return updateAzienda
                        .onSuccess(() => {
                          setAlertSuccessPianoTariffario(true)
                          actionsCambioPiano.close()
                        })
                        .asPromise(values)
                    }}
                    onExited={() => {
                      actionsCancelPiano.onClosed()
                    }}
                  />
                  <Prompt
                    when={dirty}
                    message={(location) =>
                      `Hai modificato l'abbonamento senza salvare. Sei sicuro di voler uscire?`
                    }
                  />
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      )}
      {!isDipendente && <PendingOrders isAdmin={isAdmin} />}
      <Card>
        <CardHeader title={`Utente`} />
        <CardContent>
          <Formik
            validationSchema={UtenteSchema}
            initialValues={user}
            onSubmit={(values, actions) => {
              setAlertSuccessUser(false)
              return updateUser
                .onSuccess(() => {
                  setAlertSuccessUser(true)
                })
                .asPromise({
                  email: values.email,
                  name: values.name,
                  ...values,
                })
                .catch((error) => {
                  actions.setErrors(transformErrorForForm(error))
                })
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                {alertSuccessUser && (
                  <Alert
                    color="success"
                    style={{ marginBottom: 20 }}
                    onClose={() => setAlertSuccessUser(false)}
                  >
                    Utente modificato con successo
                  </Alert>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Field
                      label={"E-mail"}
                      name="email"
                      noHelperText
                      size="small"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label={"Nome"}
                      noHelperText
                      size="small"
                      name="name"
                      component={InputField}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <ButtonSave
                    size="small"
                    disabled={isSubmitting}
                    type="submit"
                    startIcon={<Save />}
                    variant="outlined"
                  >
                    Aggiorna
                  </ButtonSave>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={`Cambio Password`} />
        <CardContent>
          <Formik
            validationSchema={PasswordSchema}
            initialValues={{ password: "", conferma_password: "" }}
            onSubmit={(values, actions) => {
              setAlertSuccessPassword(false)
              return changePassword
                .onSuccess(() => {
                  setAlertSuccessPassword(true)
                })
                .asPromise({
                  password: values.password,
                })
                .catch((error) => {
                  actions.setErrors(transformErrorForForm(error))
                })
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                {alertSuccessPassword && (
                  <Alert
                    color="success"
                    style={{ marginBottom: 20 }}
                    onClose={() => setAlertSuccessPassword(false)}
                  >
                    Password modificata con successo.
                  </Alert>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Field
                      label={"Password"}
                      name="password"
                      type="password"
                      size="small"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label={"Conferma Password"}
                      size="small"
                      type="password"
                      name="conferma_password"
                      component={InputField}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <ButtonSave
                    size="small"
                    disabled={isSubmitting}
                    type="submit"
                    startIcon={<Save />}
                    variant="outlined"
                  >
                    Cambia Password
                  </ButtonSave>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {!isDipendente && (
        <Card>
          <CardHeader title={`Azienda`} />
          <CardContent>
            <Formik
              key={selectedAzienda.id}
              validationSchema={AziendaSchema}
              onReset={onResetHackReinitializeToKeepDirtyUpdate}
              enableReinitialize={true}
              initialValues={selectedAzienda}
              onSubmit={(values, actions) => {
                return updateAzienda
                  .onSuccess(() => setAlertSuccessAzienda(true))
                  .asPromise(values)
              }}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue, dirty }) => (
                <form onSubmit={handleSubmit}>
                  {alertSuccessAzienda && (
                    <Alert
                      onClose={() => setAlertSuccessAzienda(false)}
                      color="success"
                      style={{ marginBottom: 20 }}
                    >
                      Azienda modificata con successo
                    </Alert>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Ragione sociale"}
                        name="rag_sociale"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Partita Iva"}
                        name="p_iva"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Codice fiscale"}
                        name="codice_fiscale"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Indirizzo"}
                        name="indirizzo"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Field
                        label={"N°"}
                        name="civico"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        label={"Città"}
                        name="city"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Field
                        label={"Cap"}
                        name="cap"
                        noHelperText
                        size="small"
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Field
                        label={"Provincia"}
                        name="provincia"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Pec"}
                        name="pec"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        label={"Codice fatturazione"}
                        name="codice_fatturazione"
                        size="small"
                        component={InputField}
                        noHelperText
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonSave
                      size="small"
                      disabled={isSubmitting}
                      type="submit"
                      startIcon={<Save />}
                      variant="outlined"
                    >
                      Aggiorna
                    </ButtonSave>
                  </div>
                  {/* <Prompt
                    when={dirty}
                    message={(location) =>
                      `Hai modificato i dati aziendali senza salvare. Sei sicuro di voler uscire?`
                    }
                  /> */}
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      )}
      <ModalCancelPiano
        isOpen={modalCancelPiano.isOpen}
        toggle={actionsCancelPiano.toggle}
        update={() => {
          return updateAzienda
            .onSuccess(() => actionsCancelPiano.close())
            .asPromise({ ...selectedAzienda, stripe_price_id: "" })
        }}
        onExited={() => {
          actionsCancelPiano.onClosed()
        }}
      />
    </Layout>
  )
}
