import React from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { red } from "@material-ui/core/colors"
import { Delete } from "@material-ui/icons"

const ColorButton = withStyles((theme) => ({
  root: {
    borderColor: red[200],
    color: red[600]
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

export default function ButtonDelete({ children, ...props }) {
  const classes = useStyles()

  return (
    <ColorButton
      variant="outlined"
      startIcon={<Delete />}
      {...props}
    >
      {children}
    </ColorButton>
  )
}
