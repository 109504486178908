import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
  withStyles
} from "@material-ui/core"
import {
  Delete,
  Edit,
  LocalOffer
} from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import moment from "moment"
import React from "react"
import { isMobile } from "react-device-detect"
import { useHistory } from "react-router-dom"
import Column from "../Column/Column"

const useStyles = makeStyles((theme) => ({
  marginButton: {
    marginLeft: 5,
  },
  marginTitle: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 40,
    marginRight: 40,
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    padding: 10,
    border: "1px solid #dadde9",
  },
}))(Tooltip)

export default function ListClienti({
  clienti,
  onUpdate,
  onDelete,
  onAssocia,
  columnProps,
  pagination,
}) {
  const classes = useStyles()
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const history = useHistory()

  return (
    <Card className={classes.card}>
      <CardHeader title="Clienti" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="nome">
                  Nome
                </Column>
                <Column {...columnProps} field="cognome">
                  Cognome
                </Column>
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="data_nascita">
                      Data Nascita
                    </Column>
                    <Column {...columnProps} field="sesso">
                      Sesso
                    </Column>
                    <Column {...columnProps} field="data_inserimento">
                      Data Ins.
                    </Column>
                    <TableCell>Tags</TableCell>
                  </>
                )}
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clienti &&
                clienti.map((cliente) => (
                  <TableRow key={cliente.id}>
                    <TableCell
                      className="pointer"
                      onClick={() =>
                        history.push(`/scheda-cliente/${cliente.id}`)
                      }
                    >
                      {cliente.nome}
                    </TableCell>
                    <TableCell
                      className="pointer"
                      onClick={() =>
                        history.push(`/scheda-cliente/${cliente.id}`)
                      }
                    >
                      {cliente.cognome}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/scheda-cliente/${cliente.id}`)
                          }
                        >
                          {cliente.data_nascita ? moment(cliente.data_nascita).format("DD/MM/YYYY") : ''}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/scheda-cliente/${cliente.id}`)
                          }
                        >
                          {cliente.sesso === "f" ? "F" : "M"}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/scheda-cliente/${cliente.id}`)
                          }
                        >
                          {moment(cliente.data_inserimento).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/scheda-cliente/${cliente.id}`)
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "no-wrap",
                                alignItems: "center",
                              }}
                            >
                              {cliente.tags.length > 0 &&
                                cliente.tags.filter(d => d.visibile).slice(0, 2).map((tag) => (
                                  <Chip
                                    key={tag.id}
                                    size="small"
                                    style={{
                                      background: tag.colore,
                                      color: "#FFF",
                                      marginTop: 5,
                                      marginRight: 5,
                                    }}
                                    label={tag.nome}
                                  />
                                ))}
                            </div>
                            {cliente.tags.filter(d => d.visibile).length > 2 && (
                              <HtmlTooltip
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {cliente.tags.length > 0 &&
                                      cliente.tags.filter(d => d.visibile).map((tag) => (
                                        <Chip
                                          key={tag.id}
                                          size="small"
                                          style={{
                                            background: tag.colore,
                                            color: "#FFF",
                                            marginTop: 5,
                                            marginRight: 5,
                                            cursor: "pointer",
                                          }}
                                          label={tag.nome}
                                        />
                                      ))}
                                  </div>
                                }
                              >
                                <span
                                  style={{ paddingTop: 10, cursor: "pointer" }}
                                >
                                  +{(cliente.tags.filter(d => d.visibile).length - 2)}
                                </span>
                              </HtmlTooltip>
                            )}
                          </div>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={() => onAssocia(cliente)}
                          aria-label="Associa tags"
                          title="Associa tags"
                          size={isMobile ? "small" : "medium"}
                        >
                          <LocalOffer color="secondary" />
                        </IconButton>
                        <IconButton
                          onClick={() => onUpdate(cliente)}
                          aria-label="modifica"
                          title="Modifica cliente"
                          size={isMobile ? "small" : "medium"}
                        >
                          <Edit style={{ color: "orange" }} />
                        </IconButton>
                        {cliente.num_visite === 0 && cliente.num_rivendite === 0 && (
                          <IconButton
                            onClick={() => onDelete(cliente)}
                            aria-label="elimina"
                            title="Elimina cliente"
                            size={isMobile ? "small" : "medium"}
                          >
                            <Delete style={{ color: "red" }} />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
