import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE } from "../consts"
import rjPlainList from 'react-rocketjump/plugins/plainList'

export const VisiteState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeVisita: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/visite/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addVisita: {
        effect: (t) => (entity) => api.auth(t).post("/api/visite", entity),
        updater: "insertItem",
      },
      updateVisita: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/visite/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "VisiteState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/visite", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const VisiteNoPaginationState = rj(
  rjPlainList(),
  {
    mutations: {
      addVisita: {
        effect: (t) => (entity) => api.auth(t).post("/api/visite-calendario", entity),
        updater: "insertItem",
      },
    },
    name: "VisiteNoPaginationState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/visite-calendario", params),
    computed: {
      list: "getList",
      loading: "isLoading",
    },
  }
)
