import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE_BIG } from "../consts"

export const TagState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE_BIG,
  }),
  {
    mutations: {
      removeTag: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/tags/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addTag: {
        effect: (t) => (entity) => api.auth(t).post("/api/tags", entity),
        updater: "insertItem",
      },
      updateTag: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/tags/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "TagState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/tags", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const AziendaStatisticaState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    // updateVisita: {
    //   effect: (t) => (id, entity) => api.auth(t).put(`/api/aziende/${id}/statistiche`, entity),
    //   updater: "updateData",
    // },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/aziende/${id}/statistiche`),
})
