import { isPlainObject } from 'lodash'
import { AjaxError } from 'rxjs/ajax'

const naiveIsObj = (a) =>
  typeof a === 'object' && a !== null && !Array.isArray(a)

const arrayze = (a) => (Array.isArray(a) ? a : [a])

export const transformErrorForForm = (error) => {
  if (error.status === 400) {
    let errorData
    if (error instanceof AjaxError && naiveIsObj(error.response)) {
      errorData = error.response
    } else if (naiveIsObj(error.response?.body)) {
      errorData = error.response.body
    }

    function makeErrors(errorObj) {
      return Object.keys(errorObj).reduce(
        (transformed, key) => ({
          ...transformed,
          [key]: naiveIsObj(errorObj[key])
            ? makeErrors(errorObj[key])
            : // TODO: Better joins of errors...
              arrayze(errorObj[key]).join(' '),
        }),
        {}
      )
    }

    if (errorData) {
      return makeErrors(errorData)
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}

export const fixInitialValuesNull = (values, fields, maxDepth = Infinity) => {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  return (fields || Object.keys(values)).reduce((fixedValues, field) => {
    let fixedValue
    if (isPlainObject(values[field])) {
      fixedValue = fixInitialValuesNull(values[field], fields, maxDepth - 1)
    } else if (Array.isArray(values[field])) {
      fixedValue = values[field].map((value) =>
        fixInitialValuesNull(value, fields, maxDepth - 1)
      )
    } else {
      fixedValue = values[field] === null ? '' : values[field]
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}

export const setNullOnEmptyValues = (values, fields, maxDepth = Infinity) => {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  return (fields || Object.keys(values)).reduce((fixedValues, field) => {
    let fixedValue
    if (isPlainObject(values[field])) {
      fixedValue = setNullOnEmptyValues(values[field], fields, maxDepth - 1)
    } else if (Array.isArray(values[field])) {
      fixedValue = values[field].map((value) =>
        setNullOnEmptyValues(value, fields, maxDepth - 1)
      )
    } else {
      fixedValue = values[field] === '' ? null : values[field]
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}
