import React, { useMemo, useState } from "react"
import { useRunRj } from "react-rocketjump"
import { useHistory, useParams } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import ListVisite from "../../components/Visite/ListVisite"
import { useExportToken } from "../../state/exportToken"
import qs from 'query-string'
import {
  ClienteDetailState,
  ClienteStatisticheState,
} from "../../state/clienti"
import { VisiteState } from "../../state/visite"
import { RivenditaState } from "../../state/rivendite"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import ModalFormVisita from "../../components/Visite/ModalFormVisita"
import ListRivendite from "../../components/Rivendite/ListRivendite"
import ModalFormRivendita from "../../components/Rivendite/ModalFormRivendita"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { ProdottoBigState } from "../../state/prodotti"
import ModalDelete from "../../components/Modals/ModalDelete"
import {
  AddCircle,
  Edit,
  LocalOffer,
} from "@material-ui/icons"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid, 
  TextField,
} from "@material-ui/core"
import ModalFormAssociaTags from "../../components/Clienti/ModalFormAssociaTags"
import { TagState } from "../../state/tags"
import { useAuthUser } from "use-eazy-auth"
import moment from "moment"
import { useSelectedAzienda } from "../../SelectedAzienda"
import defaultTheme from "../../theme"
import ModalFormCliente from "../../components/Clienti/ModalFormCliente"
import { isMobile } from "react-device-detect"

export default function SchedaCliente() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()
  const { id } = useParams()
  const [
    { data: cliente },
    { updateTagsCliente, updateCliente },
  ] = useRunRj(ClienteDetailState, [id])

  const [modalCreateVisita, actionsModalCreateVisita] = useModalTrigger()
  const [modalCreateRivendita, actionsModalCreateRivendita] = useModalTrigger()
  const [modalDeleteVisita, actionsModalDeleteVisita] = useModalTrigger()
  const { value: deleteVisita } = modalDeleteVisita
  const [modalDeleteRivendita, actionsModalDeleteRivendita] = useModalTrigger()
  const [modalUpdateCliente, actionsModalUpdateCliente] = useModalTrigger()
  const [modalUpdateRivendita, actionsModalUpdateRivendita] = useModalTrigger()
  const { value: deleteRivendita } = modalDeleteRivendita

  const [modalAssociaTags, actionsModalAssociaTags] = useModalTrigger()
  const [pageVisite, setPageVisite] = useState(1)
  const [pageRivendite, setPageRivendite] = useState(1)

  const onDeleteVisita = (visita) => actionsModalDeleteVisita.open(visita)
  const onDeleteRivendita = (rivendita) => actionsModalDeleteRivendita.open(rivendita)
  const onUpdateRivendita = (rivendita) => actionsModalUpdateRivendita.open(rivendita)

  const filterClienteVisite = useMemo(() => {
    return { cliente: id, page: pageVisite }
  }, [id, pageVisite])

  const filterClienteRivendite = useMemo(() => {
    return { cliente: id, page: pageRivendite }
  }, [id, pageRivendite])

  const filterAzienda = useMemo(() => {
    return { azienda: selectedAzienda.id }
  }, [selectedAzienda.id])

  const [
    {
      list: visiteCliente,
      loading: loadingVisite,
      pagination: paginationVisite,
    },
    { removeVisita, addVisita },
  ] = useRunRj(VisiteState, [filterClienteVisite], false)

  const [
    {
      list: rivenditeCliente,
      loading: loadingRivendite,
      pagination: paginationRivendite,
    },
    { addRivendita, removeRivendita, updateRivendita },
  ] = useRunRj(RivenditaState, [filterClienteRivendite], false)

  const onDoubleClick = (rivendita) => {
    if (rivendita.isExcluded){
      rivendita.isExcluded = false
      return updateRivendita.onSuccess(() => actionsModalUpdateRivendita.close()).asPromise({ ...rivendita })
    }else{
      rivendita.isExcluded = true
      return updateRivendita.onSuccess(() => actionsModalUpdateRivendita.close()).asPromise({ ...rivendita })
    }
  };

  const columnPropsVisite = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const columnPropsRivendite = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: prodotti }] = useRunRj(ProdottoBigState, [filterAzienda])

  const [{ data: clienteStat }] = useRunRj(ClienteStatisticheState, [id])

  const [{ list: tags }] = useRunRj(TagState, [filterAzienda])

  const isProductionEnv = process.env.NODE_ENV === "production"
  const host = isProductionEnv ? window.location.host : "localhost:8000"
  const protocol = window.location.protocol

  const exportToken = useExportToken()

  const linkCsvVisite = useMemo(() => {
    return `${protocol}//${host}/api/visite-export/csv?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
      cliente: id
    })}`
  }, [debQueryParams, exportToken, host, id, protocol])

  const linkPdfVisite = useMemo(() => {
    return `${protocol}//${host}/api/visite-export/pdf?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
      cliente: id
    })}`
  }, [debQueryParams, exportToken, host, id, protocol])

  const linkCsvRivendite = useMemo(() => {
    return `${protocol}//${host}/api/rivendite-export/csv?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
      cliente: id
    })}`
  }, [debQueryParams, exportToken, host, id, protocol])

  const linkPdfRivendite = useMemo(() => {
    return `${protocol}//${host}/api/rivendite-export/pdf?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
      cliente: id
    })}`
  }, [debQueryParams, exportToken, host, id, protocol])

  const { user } = useAuthUser()

  const isNotEmployee = useMemo(() => {
    if (user.profilo && user.profilo.permessi !== "employee") {
      return true
    }
  }, [user.profilo])

  const history = useHistory()

  return (
    <Layout
      title={
        cliente ? `Scheda Cliente - ${cliente.nome} ${cliente.cognome}` : ""
      }
    >
      <Grid
        container
        spacing={3}
      >
        <Grid xs={12} md={6} item>
          <Card>
            <CardHeader
              title={
                cliente ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {cliente.nome} {cliente.cognome}
                    </div>
                    <Button
                      startIcon={<Edit />}
                      size="small"
                      onClick={() => actionsModalUpdateCliente.open(cliente)}
                      color="primary"
                      variant="outlined"
                    >
                      Modifica
                    </Button>
                  </div>
                ) : (
                  ""
                )
              }
            />
            <CardContent>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 5 }}
              >
                <Grid container spacing={3}>
                  {cliente && cliente.data_nascita && (
                    <Grid xs={12} md={6} item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={moment(cliente.data_nascita).format(
                          "DD/MM/YYYY"
                        )}
                        label={"Data di Nascita"}
                      />
                    </Grid>
                  )}
                  {cliente && cliente.email && (
                    <Grid xs={12} md={6} item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={cliente.email}
                        label={"E-mail"}
                      />
                    </Grid>
                  )}
                  {cliente && cliente.sesso && (
                    <Grid xs={12} md={6} item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={cliente.sesso === "m" ? "Maschio" : "Femmina"}
                        label={"Sesso"}
                      />
                    </Grid>
                  )}

                  {cliente && cliente.minuti_notifica && (
                    <Grid xs={12} md={6} item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={cliente.minuti_notifica + ' mins'}
                        label={"Minuti anticipo notifica"}
                      />
                    </Grid>
                  )}

                  {cliente && cliente.num_telefono && (
                    <Grid xs={12} md={12} item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={cliente.num_telefono}
                        label={"Numero telefono"}
                      />
                    </Grid>
                  )}
                  {/* {cliente && (
                    <Grid xs={12} md={6} item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      value={cliente.is_abituale ? "Abituale" : "Non abituale"}
                      label={"Tipo cliente"}
                    />
                  </Grid>
                  )} */}
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} item>
          <Card>
            <CardHeader
              title={
                cliente ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Tags</div>
                    <Button
                      onClick={() => actionsModalAssociaTags.open(cliente)}
                      startIcon={<LocalOffer />}
                      size="small"
                      color="primary"
                      variant="outlined"
                    >
                      Associa
                    </Button>
                  </div>
                ) : (
                  ""
                )
              }
            />
            <CardContent style={{ minHeight: !isMobile ? 137 : undefined }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {cliente && (
                  <div
                    title="Associa tags"
                    onClick={() => actionsModalAssociaTags.open(cliente)}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {cliente.tags.length > 0 &&
                      cliente.tags.filter(d => d.visibile).map((tag) => (
                        <Chip
                          key={tag.id}
                          size="medium"
                          style={{
                            background: tag.colore,
                            color: "#FFF",
                            marginTop: 5,
                            marginRight: 5,
                            cursor: "pointer",
                          }}
                          label={tag.nome}
                        />
                      ))}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div>
        {user.profilo &&
          user.profilo.permessi !== "employee" &&
          clienteStat &&
          clienteStat.overall_customer_visits > 0 && (
            <>
              {clienteStat && clienteStat.overall_customer_visits !== 0 && (
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item md={2} xs={4}>
                    <Card>
                      <CardHeader
                        style={{
                          backgroundColor: defaultTheme.palette.secondary.main,
                          padding: 5,
                          fontSize: 9,
                          textAlign: "center",
                          color: "#FFF",
                        }}
                        title={"Numero Visite Totali"}
                      />
                      <CardContent
                        style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                      >
                        {clienteStat.overall_customer_visits}
                        <br />
                        <small
                          style={{
                            color:
                              Math.sign(
                                clienteStat.visits_percentage_difference
                              ) === 1
                                ? defaultTheme.palette.success.main
                                : defaultTheme.palette.error.main,
                            fontWeight: 700,
                          }}
                        >
                          {clienteStat.visits_percentage_difference
                            ? clienteStat.visits_percentage_difference.toFixed(
                                2
                              ) + " %"
                            : ""}{" "}
                        </small>
                      </CardContent>
                    </Card>
                  </Grid>
                  {clienteStat.overall_customer_income && (
                    <Grid item md={2} xs={4}>
                      <Card>
                        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Fatturato totale"}
                        />
                        <CardContent
                          style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                        >
                          {clienteStat.overall_customer_income.toFixed(2)} &euro;
                          <br />
                          <small
                            style={{
                              color:
                                Math.sign(clienteStat.percentage_difference) ===
                                1
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {clienteStat.percentage_difference
                              ? clienteStat.percentage_difference.toFixed(2) +
                                " %"
                              : ""}{" "}
                          </small>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {clienteStat.overall_customer_income_last_30_days !== 0 && (
                    <Grid item md={2} xs={4}>
                      <Card>
                        <CardHeader
                          color={"secondary"}
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Fatturato 30 gg"}
                        />
                        <CardContent
                          style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                        >
                          {clienteStat.overall_customer_income_last_30_days.toFixed(2)}{" "}
                          &euro;
                          <br />
                          <small
                            style={{
                              color:
                                Math.sign(
                                  clienteStat.percentage_difference_last_30_days
                                ) === 1
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {clienteStat.percentage_difference_last_30_days
                              ? clienteStat.percentage_difference_last_30_days.toFixed(
                                  2
                                ) + " %"
                              : ""}{" "}
                          </small>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {clienteStat.overall_customer_income_last_45_days !== 0 && (
                    <Grid item md={2} xs={4}>
                      <Card>
                        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Fatturato 45 gg"}
                        />
                        <CardContent
                          style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                        >
                          {clienteStat.overall_customer_income_last_45_days.toFixed(2)}{" "}
                          &euro;
                          <br />
                          <small
                            style={{
                              color:
                                Math.sign(
                                  clienteStat.percentage_difference_last_45_days
                                ) === 1
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {clienteStat.percentage_difference_last_45_days
                              ? clienteStat.percentage_difference_last_45_days.toFixed(
                                  2
                                ) + " %"
                              : ""}{" "}
                          </small>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {clienteStat.overall_customer_income_last_60_days !== 0 && (
                    <Grid item md={2} xs={4}>
                      <Card>
                        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Fatturato 60 gg"}
                        />
                        <CardContent
                          style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                        >
                          {clienteStat.overall_customer_income_last_60_days.toFixed(2)}{" "}
                          &euro;
                          <br />
                          <small
                            style={{
                              color:
                                Math.sign(
                                  clienteStat.percentage_difference_last_60_days
                                ) === 1
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {clienteStat.percentage_difference_last_60_days
                              ? clienteStat.percentage_difference_last_60_days.toFixed(
                                  2
                                ) + " %"
                              : ""}{" "}
                          </small>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {clienteStat.overall_customer_income_last_90_days !== 0 && (
                    <Grid item md={2} xs={4}>
                      <Card>
                        <CardHeader
                          style={{
                            backgroundColor:
                              defaultTheme.palette.secondary.main,
                            padding: 5,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                          title={"Fatturato 90 gg"}
                        />
                        <CardContent
                          style={{ textAlign: "center", fontSize: 25, minHeight: 110 }}
                        >
                          {clienteStat.overall_customer_income_last_90_days.toFixed(2)}{" "}
                          &euro;
                          <br />
                          <small
                            style={{
                              color:
                                Math.sign(
                                  clienteStat.percentage_difference_last_90_days
                                ) === 1
                                  ? defaultTheme.palette.success.main
                                  : defaultTheme.palette.error.main,
                              fontWeight: 700,
                            }}
                          >
                            {clienteStat.percentage_difference_last_90_days
                              ? clienteStat.percentage_difference_last_90_days.toFixed(
                                  2
                                ) + " %"
                              : ""}{" "}
                          </small>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}

        <Card className='card-add'>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => {
              return addVisita
                .onSuccess((visita) => {
                  actionsModalCreateVisita.close()
                  history.push(`/visite/${visita.id}`)
                })
                .asPromise({
                  data: moment().format("YYYY-MM-DD"),
                  cliente: cliente.id,
                  aggiungi_servizi: true,
                })
            }}
          >
            Aggiungi Visita
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 15 }}
            color="secondary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreateRivendita.open()}
          >
            Aggiungi Rivendita
          </Button>
        </Card>
        {visiteCliente === null && loadingVisite && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {cliente && visiteCliente && (
          <ListVisite
            columnProps={columnPropsVisite}
            visite={visiteCliente}
            linkCsv={linkCsvVisite}
            linkPdf={linkPdfVisite}
            setPageVisite={setPageVisite}
            pageVisite={pageVisite}
            detailScheda={true}
            showClienteName={false}
            isNotEmployee={isNotEmployee}
            pagination={paginationVisite}
            onDelete={onDeleteVisita}
          />
        )}
        {rivenditeCliente === null && loadingRivendite && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {cliente && rivenditeCliente && (
          <ListRivendite
            pagination={paginationRivendite}
            columnProps={columnPropsRivendite}
            linkCsv={linkCsvRivendite}
            setPageRivendite={setPageRivendite}
            pageRivendite={pageRivendite}
            detailScheda={true}
            isNotEmployee={isNotEmployee}
            linkPdf={linkPdfRivendite}
            rivendite={rivenditeCliente}
            onDelete={onDeleteRivendita}
            onDoubleClick={onDoubleClick}
            onUpdate={onUpdateRivendita}
          />
        )}
      </div>
      <ModalFormVisita
        save={(visita) => {
          return addVisita
            .onSuccess((visita) => {
              actionsModalCreateVisita.close()
              history.push(`/visite/${visita.id}`)
            })
            .asPromise(visita)
        }}
        isOpen={modalCreateVisita.isOpen}
        cliente={cliente}
        toggle={actionsModalCreateVisita.toggle}
        onExited={actionsModalCreateVisita.onClosed}
      />
      <ModalFormRivendita
        save={(rivendita) => {
          return addRivendita
            .onSuccess(() => actionsModalCreateRivendita.close())
            .asPromise({ ...rivendita, cliente: id })
        }}
        isOpen={modalCreateRivendita.isOpen}
        prodotti={prodotti}
        inputScheda={true}
        toggle={actionsModalCreateRivendita.toggle}
        onExited={actionsModalCreateRivendita.onClosed}
      />
      <ModalFormRivendita
        save={(rivendita) => {
          return addRivendita
            .onSuccess(() => actionsModalUpdateRivendita.close())
            .asPromise({ ...rivendita, cliente: id })
        }}
        isOpen={modalUpdateRivendita.isOpen}
        prodotti={prodotti}
        rivendita={modalUpdateRivendita.value}
        inputScheda={true}
        toggle={actionsModalUpdateRivendita.toggle}
        onExited={actionsModalUpdateRivendita.onClosed}
      />
      <ModalFormAssociaTags
        save={(tags) => {
          return updateTagsCliente
            .onSuccess(() => {
              actionsModalAssociaTags.close()
            })
            .asPromise(modalAssociaTags.value.id, tags)
        }}
        isOpen={modalAssociaTags.isOpen}
        tags={tags}
        cliente={modalAssociaTags.value}
        toggle={actionsModalAssociaTags.toggle}
        onExited={() => {
          actionsModalAssociaTags.onClosed()
        }}
      />
      <ModalFormCliente
        save={(cliente) => {
          return updateCliente
            .onSuccess(() => actionsModalUpdateCliente.close())
            .asPromise({ ...cliente, azienda: selectedAzienda.id })
        }}
        cliente={modalUpdateCliente.value}
        isOpen={modalUpdateCliente.isOpen}
        toggle={actionsModalUpdateCliente.toggle}
        onExited={() => {
          actionsModalUpdateCliente.onClosed()
        }}
      />
      <ModalDelete
        isOpen={modalDeleteVisita.isOpen}
        toggle={actionsModalDeleteVisita.toggle}
        title={
          deleteVisita
            ? `Rimuovere visita di ${deleteVisita.cliente_data.nome} ${deleteVisita.cliente_data.cognome}?`
            : null
        }
        text={
          deleteVisita
            ? `Sei sicuro di voler eliminare la visita del cliente ${deleteVisita.cliente_data.nome} ${deleteVisita.cliente_data.cognome}?`
            : null
        }
        onDelete={() => {
          return removeVisita
            .onSuccess(() => actionsModalDeleteVisita.close())
            .asPromise(deleteVisita)
        }}
        onExited={actionsModalDeleteVisita.onClosed}
      />
      <ModalDelete
        isOpen={modalDeleteRivendita.isOpen}
        toggle={actionsModalDeleteRivendita.toggle}
        title={
          deleteRivendita
            ? `Rimuovere la rivendita del prodotto ${deleteRivendita.prodotto_data.categoria} ${deleteRivendita.prodotto_data.prodotto}?`
            : null
        }
        text={
          deleteRivendita
            ? `Sei sicuro di voler eliminare la rivendita del prodotto ${deleteRivendita.prodotto_data.categoria} ${deleteRivendita.prodotto_data.prodotto}?`
            : null
        }
        onDelete={() => {
          return removeRivendita
            .onSuccess(() => actionsModalDeleteRivendita.close())
            .asPromise(deleteRivendita)
        }}
        onExited={actionsModalDeleteRivendita.onClosed}
      />
    </Layout>
  )
}
