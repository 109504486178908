import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"

const INITIAL_VALUES = {
  titolo: "",
}

const NewsletterSchema = Yup.object().shape({
  titolo: Yup.string().required("Titolo è obbligatorio"),
})

export default function ModalFormNewsletter({
  save,
  isOpen,
  toggle,
  onExited,
}) {

  return (
    <Dialog open={isOpen} fullWidth onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>Inserimento newsletter</DialogTitle>
      <Formik
        validationSchema={NewsletterSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    label={"Titolo"}
                    name="titolo"
                    component={InputField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                Aggiungi
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
