import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import { InputField, transformErrorForForm } from "../Form"
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Save, KeyboardBackspace } from "@material-ui/icons"
import { ChromePicker } from "react-color"
import ButtonSave from "../ButtonSave"
import { Alert } from "@material-ui/lab"

const AziendaSchema = Yup.object().shape({
  nome: Yup.string().required("Nome è obbligatorio."),
  colore: Yup.string().required("Colore è obbligatorio"),
})

const INITIAL_VALUES = {
  nome: "",
  colore: "",
}

export default function ModalFormTag({
  tag,
  save,
  aziende,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {tag ? "Modifica" : "Inserimento"} tag
      </DialogTitle>

      <Formik
        validationSchema={AziendaSchema}
        initialValues={tag ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field label={"Nome"} name="nome" component={InputField} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={"Colore"}
                    disabled
                    name="colore"
                    component={InputField}
                  />
                  <div style={{ display: "flex" }}>
                    <ChromePicker
                      disableAlpha
                      color={values.colore}
                      onChange={(e) => setFieldValue("colore", e.hex)}
                    />
                    <div style={{ marginLeft: 20 }}>
                      Tag Preview: <br />
                      <br />
                      <Chip
                        style={{
                          background: values.colore
                            ? values.colore
                            : "transparent",
                          color: "#FFF",
                        }}
                        label={values.nome ? values.nome : "Testo di esempio"}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {typeof errors.non_field_errors === "string" && (
                <Alert severity="error" style={{ marginTop: 10 }}>
                  {errors.non_field_errors}
                </Alert>
              )}
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                size="small"
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                size="small"
                disabled={isSubmitting}
                type="submit"
                startIcon={<Save />}
                variant="outlined"
              >
                {tag ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
