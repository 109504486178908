import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  InputField,
  SwitchCheckboxField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  MenuItem,
  Chip,
  TextField,
} from "@material-ui/core"
import { KeyboardBackspace, Save } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Autocomplete } from "@material-ui/lab"

const AziendaSchema = Yup.object().shape({})

export default function ModalFormAssociaTags({
  cliente,
  tags,
  save,
  isOpen,
  toggle,
  onExited,
}) {

  const tagsInitials = useMemo(() => {
    if (cliente && cliente.tags && cliente.tags.length > 0) {
      return cliente.tags.filter(d => d.visibile).map((tag) => ({
        id: tag.id,
        nome: tag.nome,
        colore: tag.colore,
      }))
    }
    return []
  }, [cliente])

  const tagsOptions = useMemo(() => {
    if (tags) {
      return tags.map((tag) => ({
        id: tag.id,
        nome: tag.nome,
        colore: tag.colore,
      }))
    }
    return []
  }, [tags])

  return (
    <Dialog open={isOpen} fullWidth={true} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        Associa Tags Cliente -{" "}
        {cliente && (
          <span>
            {cliente.nome} {cliente.cognome}
          </span>
        )}
      </DialogTitle>
      <Formik
        validationSchema={AziendaSchema}
        initialValues={{
          tags: tagsInitials,
        }}
        onSubmit={(values, actions) => {
          const valuesToGo = { tags: values.tags.map((tag) => tag.id) }
          save(valuesToGo).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    noOptionsText={"Nessun tag trovato"}
                    value={values.tags}
                    id="size-small-standard-multi"
                    size="small"
                    name="tags"
                    onChange={(e, value) => setFieldValue("tags", value)}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    options={tagsOptions}
                    getOptionLabel={(option) => option.nome}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          size="small"
                          style={{ background: option.colore, color: "#FFF" }}
                          label={option.nome}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="Aggiungi Tags"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
                startIcon={<Save />}
              >
                Salva
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
