import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Save, KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"

const INITIAL_VALUES = {
  p_iva: "",
  rag_sociale: "",
  numero_licenze: 6,
  scadenza_licenza: null,
  city: "",
  indirizzo: "",
  cap: "",
  provincia: "",
  codice_fiscale: "",
  civico: "",
  pec: "",
  codice_fatturazione: "",
  stripe_price_id: "",
  codice_invito: "",
}

const AziendaSchema = Yup.object().shape({
  p_iva: Yup.string().required("Campo obbligatorio"),
  rag_sociale: Yup.string().required("Campo obbligatorio"),
  city: Yup.string().required("Campo obbligatorio"),
  indirizzo: Yup.string().required("Campo obbligatorio"),
  cap: Yup.string().required("Campo obbligatorio"),
  provincia: Yup.string().required("Campo obbligatorio"),
  codice_fiscale: Yup.string().required("Campo obbligatorio"),
  civico: Yup.string().required("Campo obbligatorio"),
  scadenza_licenza: Yup.date()
    .typeError("Data inserita nel formato sbagliato")
    .required("La data di scadenza delle licenze è obbligatoria"),
  pec: Yup.string().test(function (value) {
    const { codice_fatturazione } = this.parent
    if (!codice_fatturazione) return value != null
    return true
  }),
  codice_fatturazione: Yup.string().test(function (value) {
    const { pec } = this.parent
    if (!pec) return value != null
    return true
  }),
})

export default function ModalFormAzienda({
  azienda,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog fullWidth open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {azienda ? "Modifica" : "Inserimento"} azienda
      </DialogTitle>

      <Formik
        validationSchema={AziendaSchema}
        initialValues={azienda ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Ragione sociale"}
                    name="rag_sociale"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Partita Iva"}
                    name="p_iva"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={"Codice fiscale"}
                    name="codice_fiscale"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Indirizzo"}
                    name="indirizzo"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    label={"N°"}
                    name="civico"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    label={"Città"}
                    name="city"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Cap"}
                    name="cap"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Provincia"}
                    name="provincia"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Pec"}
                    name="pec"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Codice fatturazione"}
                    name="codice_fatturazione"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Scadenza licenza"}
                    name="scadenza_licenza"
                    size="small"
                    component={DateField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Numero licenze"}
                    name="numero_licenze"
                    size="small"
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={"Codice invito"}
                    name="codice_invito"
                    size="small"
                    component={InputField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                size="small"
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                size="small"
                disabled={isSubmitting}
                type="submit"
                startIcon={<Save />}
                variant="outlined"
              >
                {azienda ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
