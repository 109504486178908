import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE } from "../consts"
import { rjListWithTotals } from "./common"

export const VisitaDetailState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    updateVisita: {
      effect: (t) => (id, entity) => api.auth(t).put(`/api/visite/${id}`, entity),
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/visite/${id}`),
})

export const ServizioState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  rjListWithTotals(),
  {
    mutations: {
      removeServizio: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/servizi/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addServizio: {
        effect: (t) => (entity) => api.auth(t).post("/api/servizi", entity),
        updater: "insertItem",
      },
      updateServizio: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/servizi/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "ServizioState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/servizi", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      totals: 'getTotals',
    },
  }
)
