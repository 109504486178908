import { Card, CardContent, CardHeader, Chip, Grid } from "@material-ui/core"
import { getIn } from "formik"
import React from "react"
import defaultTheme from "../theme"

export default function SceltaPianoTariffario({
  field,
  form,
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  piani,
  noHelperText = false,
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const { value: formValue, ...passField } = field
  let value
  if (relaxed && (formValue === undefined || formValue === null)) {
    value = ""
  } else {
    value = formValue
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        {piani &&
          piani.map((piano) => (
            <Grid
              key={piano.id}
              item
              xs={12}
              md={4}
              onClick={() => form.setFieldValue(field.name, piano.id)}
            >
              <Card
                style={{
                  cursor: "pointer",
                  border: !!(error && touch) ? "1px solid red" : undefined,
                }}
              >
                <CardHeader
                  style={{
                    backgroundColor:
                      value === piano.id
                        ? defaultTheme.palette.primary.main
                        : undefined,
                    color: value === piano.id ? "#FFFFFF" : undefined,
                  }}
                  title={piano.product_name}
                />
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    {value === piano.id ? (
                      <Chip label={"Il tuo abbonamento"} color={"primary"} />
                    ) : (
                      <Chip
                        style={{ cursor: "pointer" }}
                        color={"secondary"}
                        label={"Seleziona abbonamento"}
                      />
                    )}
                  </div>
                  <h2 style={{ textAlign: "center" }}>
                    {piano.unit_amount}
                    {piano.currency === "eur" ? "€" : piano.currency}
                  </h2>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}
