import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  lighten,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Clear, Delete, Mail } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import { Link, useHistory } from "react-router-dom"
import moment from "moment"
import { isMobile } from "react-device-detect"

export default function ListNewsletters({
  newsletters,
  onDelete,
  columnProps,
  pagination,
}) {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const history = useHistory()

  return (
    <Card>
      <CardHeader title="Newsletters" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ fontWeight: "bold" }}>
                <Column {...columnProps} field="titolo">
                  Titolo
                </Column>
                {!isMobile && <TableCell>Immagine</TableCell>}
                <Column {...columnProps} field="data">
                  Data
                </Column>
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="inviato_il">
                      Inviata il
                    </Column>
                    <Column {...columnProps} field="template">
                      Template
                    </Column>
                  </>
                )}
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletters &&
                newsletters.map((newsletter) => (
                  <TableRow key={newsletter.id}>
                    <TableCell
                      className="pointer"
                      onClick={() =>
                        history.push(`/newsletters/${newsletter.id}`)
                      }
                    >
                      {newsletter.titolo}
                    </TableCell>
                    {!isMobile && (
                      <TableCell
                        className="pointer"
                        onClick={() =>
                          history.push(`/newsletters/${newsletter.id}`)
                        }
                      >
                        {newsletter.immagine ? (
                          <img
                            src={newsletter.immagine}
                            height="50"
                            alt={newsletter.titolo}
                          />
                        ) : (
                          <Clear color="secondary" />
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      className="pointer"
                      onClick={() =>
                        history.push(`/newsletters/${newsletter.id}`)
                      }
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {newsletter.data
                        ? moment(newsletter.data).format("DD-MM-YYYY")
                        : ""}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/newsletters/${newsletter.id}`)
                          }
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {newsletter.inviato_il ? (
                            moment(newsletter.inviato_il).format(
                              "DD-MM-YYYY HH:mm"
                            )
                          ) : (
                            <Clear color="secondary" />
                          )}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() =>
                            history.push(`/newsletters/${newsletter.id}`)
                          }
                        >
                          {newsletter.template_data?.titolo ?? null}
                        </TableCell>
                      </>
                    )}
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <IconButton
                        onClick={() => onDelete(newsletter)}
                        aria-label="elimina"
                        title="Elimina newsletter"
                      >
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
