import React, { useEffect } from "react";
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams";
import { Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import { useAuthState } from "use-eazy-auth";
import { isMobile } from "react-device-detect";

export default function RegistrazioneResult() {
  const { authenticated } = useAuthState();
  const history = useHistory();
  const [queryParams] = useDebounceQueryParams();

  useEffect(() => {
    document.location.href = "com.cinerarius.salonifyapp://home";
  }, []);

  return (
    <Card
      style={{
        margin: isMobile ? 0 : "10% auto",
        width: isMobile ? "100%" : 600,
        borderRadius: isMobile ? 0 : 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CardHeader
        className="card-login"
        title={
          <div style={{ textAlign: "center" }}>
            <img
              height={isMobile ? 30 : 40}
              src="/salonify.png"
              alt="Salonify"
            />
          </div>
        }
      />
      <CardContent>
        {queryParams.outcome && queryParams.outcome === "success" && (
          <>
            <Alert color="success" style={{ display: "flex" }}>
              Registrazione avvenuta con successo.{""}
            </Alert>
            {authenticated && (
              <div style={{ paddingTop: 30 }} className="text-center">
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={() => history.push("/")}
                >
                  Vai a Salonify
                </Button>
              </div>
            )}
            {!authenticated && (
              <div style={{ paddingTop: 30 }} className="text-center">
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={() => history.push("/login")}
                >
                  Login
                </Button>
              </div>
            )}
          </>
        )}
        {queryParams.outcome && queryParams.outcome === "error" && (
          <Alert color="error">
            La registrazione ha riscontrato qualche problema. Vai al{" "}
            <Link to="/registrazione azienda">
              modulo di registrazione per riprovare
            </Link>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}
