import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  TimeField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Autocomplete } from "@material-ui/lab"
import moment from "moment"

const INITIAL_VALUES = {
  data: moment().format("YYYY-MM-DD"),
  cliente: "",
  temperatura: "",
  tipo_pagamento: "",
  ora_inizio: "",
  note: "",
  eseguita: false,
  aggiungi_servizi: true,
  durata: "",
}

const VisitaSchema = Yup.object().shape({
  data: Yup.date()
    .typeError("Data inserita nel formato sbagliato")
    .required("La data è obbligatoria"),
  cliente: Yup.object().nullable().required("Il cliente è obbligatorio"),
  tipo_pagamento: Yup.string().when("eseguita", {
    is: true, // alternatively: (val) => val == true
    then: Yup.string().required("Tipo pagamento obbligatorio"),
  }),
})

export default function ModalFormVisita({
  save,
  isOpen,
  toggle,
  cliente = null,
  onExited,
  clienti,
  slotSelected,
}) {
  const clientiOptions = useMemo(() => {
    if (clienti) {
      return clienti.map((cliente) => ({
        id: cliente.id,
        nome: cliente.nome + " " + cliente.cognome,
      }))
    }
    return []
  }, [clienti])

  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>Inserimento visita</DialogTitle>
      <Formik
        validationSchema={VisitaSchema}
        initialValues={{
          ...INITIAL_VALUES,
          cliente: cliente ? cliente.id : "",
          data: slotSelected ? moment(slotSelected.start).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          durata: slotSelected ? moment(slotSelected.end).diff(moment(slotSelected.start), 'minutes') : '',
          ora_inizio: slotSelected ? moment(slotSelected.start).format('HH:mm') : '',
        }}
        onSubmit={(values, actions) =>
          save(
            setNullOnEmptyValues({
              ...values,
              cliente: values.cliente.id,
              durata: values.durata ? values.durata * 60 : "",
              temperatura: values.temperatura
                ? values.temperatura.replace(",", ".")
                : "",
            })
          ).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {cliente ? (
                      <Field
                        label="Cliente"
                        name="cliente"
                        select={true}
                        component={InputField}
                      >
                        <MenuItem value={cliente.id}>
                          {cliente.nome} {cliente.cognome}
                        </MenuItem>
                      </Field>
                    ) : (
                      <Autocomplete
                        noOptionsText={"Nessun cliente trovato"}
                        id="size-small-standard"
                        name="cliente"
                        onChange={(e, value) => setFieldValue("cliente", value)}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        options={clientiOptions}
                        value={values.cliente}
                        getOptionLabel={(option) => (option ? option.nome : "")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors["cliente"] && touched["cliente"]}
                            helperText={
                              !!errors["cliente"] && touched["cliente"]
                                ? errors["cliente"]
                                : undefined
                            }
                            variant="outlined"
                            label="Cliente"
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <Field label="Data" name="data" component={DateField} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      label="Durata"
                      select={true}
                      component={InputField}
                      name="durata"
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="15">15 min</MenuItem>
                      <MenuItem value="30">30 min</MenuItem>
                      <MenuItem value="45">45 min</MenuItem>
                      <MenuItem value="60">1 ora</MenuItem>
                      <MenuItem value="75">1 ora 15 min</MenuItem>
                      <MenuItem value="90">1 ora 30 min</MenuItem>
                      <MenuItem value="105">1 ora 45 min</MenuItem>
                      <MenuItem value="120">2 ore</MenuItem>
                      <MenuItem value="150">2 ore 30 min</MenuItem>
                      <MenuItem value="180">3 ore</MenuItem>
                      <MenuItem value="240">4 ore</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      label="Ora"
                      name="ora_inizio"
                      component={TimeField}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Temperatura"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">°C</InputAdornment>
                        ),
                      }}
                      type="text"
                      name="temperatura"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Tipo pagamento"
                      name="tipo_pagamento"
                      component={InputField}
                      select={true}
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="cash">Contanti</MenuItem>
                      <MenuItem value="bancomat">Bancomat</MenuItem>
                      <MenuItem value="credit_card">Carta credito</MenuItem>
                      <MenuItem value="debit_card">Carta debito</MenuItem>
                      <MenuItem value="other">Altro</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Eseguita?"
                      component={SwitchCheckboxField}
                      name="eseguita"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Includi servizi"
                      component={SwitchCheckboxField}
                      name="aggiungi_servizi"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Note"
                      name="note"
                      multiline
                      component={InputField}
                      rows="5"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  Aggiungi
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
