import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Check, Clear, Delete, Edit } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import { isMobile } from "react-device-detect"

export default function ListProdotti({
  prodotti,
  onUpdate,
  onDelete,
  columnProps,
  pagination,
}) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  return (
    <Card>
      <CardHeader title="Magazzino" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="linea">
                      Linea
                    </Column>
                    <Column {...columnProps} field="categoria">
                      Categoria
                    </Column>
                  </>
                )}
                <Column {...columnProps} field="prodotto">
                  Prodotto
                </Column>
                {!isMobile && (
                  <Column {...columnProps} field="quantita">
                    Formato
                  </Column>
                )}
                <Column {...columnProps} field="giacenza">
                  Giacenza
                </Column>
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="costo">
                      Costo
                    </Column>
                    <Column {...columnProps} field="rivendita">
                      Prezzo Rivendita
                    </Column>
                    <Column {...columnProps} field="in_uso">
                      In Uso
                    </Column>
                  </>
                )}
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prodotti &&
                prodotti.map((prodotto) => (
                  <TableRow key={prodotto.id}>
                    {!isMobile && (
                      <>
                        <TableCell>{prodotto.linea}</TableCell>
                        <TableCell>{prodotto.categoria}</TableCell>
                      </>
                    )}
                    <TableCell>{prodotto.prodotto}</TableCell>
                    {!isMobile && <TableCell>{prodotto.quantita}</TableCell>}
                    <TableCell>{prodotto.giacenza}</TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>
                          {prodotto.costo ? `${prodotto.costo} €` : ""}
                        </TableCell>
                        <TableCell>
                          {prodotto.rivendita ? `${prodotto.rivendita} €` : ""}
                        </TableCell>
                        <TableCell>
                          {prodotto.in_uso ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="secondary" />
                          )}
                        </TableCell>
                      </>
                    )}
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <IconButton
                        onClick={() => onUpdate(prodotto)}
                        aria-label="modifica"
                        title="Modifica prodotto"
                      >
                        <Edit style={{ color: "orange" }} />
                      </IconButton>
                      {(prodotto.num_rivendite === 0 ||
                        !prodotto.num_rivendite) && (
                        <IconButton
                          onClick={() => onDelete(prodotto)}
                          aria-label="elimina"
                          title="Elimina prodotto"
                        >
                          <Delete style={{ color: "red" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
