import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import moment from "moment"
import Column from "../Column/Column"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Check, Clear, Delete, GetApp } from "@material-ui/icons"
import { useHistory } from "react-router-dom"
import { Pagination } from "@material-ui/lab"
import { getLabelPagamento } from "../../utils"
import { isMobile } from "react-device-detect"
import { getLabelDurata } from "../../utils"

export default function ListVisite({
  visite,
  onDelete,
  columnProps,
  pagination,
  showClienteName = true,
  linkCsv,
  linkPdf,
  isNotEmployee,
  setPageVisite,
  detailScheda = false,
  pageVisite,
}) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const history = useHistory()

  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Visite</div>
            {linkCsv && linkPdf && isNotEmployee && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetApp />}
                  href={linkCsv}
                >
                  Excel
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  startIcon={<GetApp />}
                  href={linkPdf}
                >
                  Pdf
                </Button>
              </div>
            )}
          </div>
        }
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="data">
                  Data
                </Column>
                <TableCell>Ora</TableCell>
                <TableCell>Durata</TableCell>
                {showClienteName && (
                  <Column {...columnProps} field="cliente__nome">
                    Cliente
                  </Column>
                )}
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="eseguita">
                      Eseguita
                    </Column>
                    <Column {...columnProps} field="tipo_pagamento">
                      Pagamento
                    </Column>
                    <Column {...columnProps} field="totale">
                      Totale
                    </Column>
                    <Column {...columnProps} field="note">
                      Note
                    </Column>
                  </>
                )}
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visite &&
                visite.map((visita) => (
                  <TableRow key={visita.id}>
                    <TableCell
                      className="pointer"
                      onClick={() => history.push(`/visite/${visita.id}`)}
                    >
                      {moment(visita.data).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell
                      className="pointer"
                      onClick={() => history.push(`/visite/${visita.id}`)}
                    >
                      {visita.ora_inizio
                        ? moment(visita.ora_inizio, "HH:mm:ss").format("HH:mm")
                        : null}
                    </TableCell>
                    <TableCell
                      className="pointer"
                      onClick={() => history.push(`/visite/${visita.id}`)}
                    >
                      {visita.durata
                        ? getLabelDurata(
                            moment.duration(visita.durata).asMinutes()
                          )
                        : ""}
                    </TableCell>
                    {showClienteName && (
                      <TableCell
                        className="pointer"
                        onClick={() => history.push(`/visite/${visita.id}`)}
                      >
                        {visita.cliente_data
                          ? `${visita.cliente_data.nome} ${visita.cliente_data.cognome}`
                          : ""}
                      </TableCell>
                    )}
                    {!isMobile && (
                      <>
                        <TableCell
                          className="pointer"
                          onClick={() => history.push(`/visite/${visita.id}`)}
                        >
                          {visita.eseguita ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="secondary" />
                          )}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() => history.push(`/visite/${visita.id}`)}
                        >
                          {getLabelPagamento(visita.tipo_pagamento)}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() => history.push(`/visite/${visita.id}`)}
                        >
                          {visita.totale_con_sconto
                            ? `${visita.totale_con_sconto} €`
                            : ""}
                        </TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() => history.push(`/visite/${visita.id}`)}
                        >
                          {visita.note}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <IconButton
                        onClick={() => onDelete(visita)}
                        aria-label="elimina"
                        title="Elimina visita"
                      >
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={detailScheda ? pageVisite : +debQueryParams.page || 1}
        onChange={(e, page) =>
          detailScheda ? setPageVisite(page) : setQueryParams({ page })
        }
      />
    </Card>
  )
}
