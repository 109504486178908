import React, { useMemo } from "react"
import { Box, MenuItem, TextField, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useAuthUser } from "use-eazy-auth"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { Email, Person } from "@material-ui/icons"
import { useHistory, useLocation, useParams } from "react-router"
import { Autocomplete } from "@material-ui/lab"

function AziendaSelector() {
  const history = useHistory()
  const [{ aziende, selectedAzienda }, { setSelectedAziendaId }] =
    useSelectedAzienda()

  const aziendeOptions = useMemo(() => {
    if (aziende) {
      return aziende.map((azienda) => ({
        id: azienda.id,
        name: azienda.rag_sociale,
      }))
    }
    return []
  }, [aziende])

  const aziendaSelected = useMemo(() => {
    if (selectedAzienda) {
      return {
        id: selectedAzienda.id,
        name: selectedAzienda.rag_sociale,
      }
    }
    return []
  }, [selectedAzienda])

  const location = useLocation()

  const { id } = useParams()

  return (
    <Autocomplete
      id="azienda-select"
      style={{
        marginTop: 10,
      }}
      fullWidth
      disabled={aziende.lenght === 1}
      disableListWrap={aziende.lenght === 1}
      options={aziendeOptions}
      disableClearable
      getOptionSelected={(option, value) => option.id === value.id}
      value={aziendaSelected}
      onChange={(e, value) => {
        setSelectedAziendaId(value.id)
        if(location.pathname === '/scheda-cliente/'+id){
          history.push('/clienti')
        }
        if(location.pathname === '/visite/'+id){
          history.push('/visite')
        }
      }}
      getOptionLabel={(option) => (option ? option.name.toString() : "")}
      //renderOption={(option) => option.name
      renderInput={(params) => (
        <TextField
          {...params}
          label="Azienda"
          size="small"
          disabled={aziende.length === 1}
          color="secondary"
          className="select-azienda"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: aziende.lenght === 1 ? "off" : "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: "10px 16px 0px 16px",
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
    //position: 'fixed',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 10000,
  },
  userInfo: {
    paddingTop: 10,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease",
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
    display: "flex",
    alignItems: "center",
  },
}))

const SidebarHeader = () => {
  const classes = useStyles()
  const { user } = useAuthUser()
  const hasSelectedAziendaCtx = useSelectedAzienda()

  return (
    <Box className={classes.root}>
      <Box className={classes.userInfo}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box mr={2}>
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              <Person style={{ marginRight: 5 }} />
              {user.name}
            </Typography>
            <Typography className={classes.userSubTitle}>
              <Email style={{ height: 14, marginRight: 5 }} /> {user.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      {hasSelectedAziendaCtx ? (
        <AziendaSelector />
      ) : (
        <div style={{ height: 50 }}></div>
      )}
    </Box>
  )
}

export default SidebarHeader
