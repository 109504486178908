import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import Layout from "../../components/Layout/Layout"
import ModalDelete from "../../components/Modals/ModalDelete"
import ListVisite from "../../components/Visite/ListVisite"
import { VisiteState } from "../../state/visite"
import ModalFormVisita from "../../components/Visite/ModalFormVisita"
import { useRunRj } from "react-rocketjump"
import { ClienteBigState } from "../../state/clienti"
import { useHistory } from "react-router-dom"
import qs from "query-string"
import {
  makeStyles,
  TextField,
  Button,
  Card,
  CircularProgress,
  FormControl,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import {
  AddCircle,
  CalendarToday,
  Cancel,
  FileCopy,
  GetApp,
  KeyboardBackspace,
  RotateLeft,
} from "@material-ui/icons"
import { useExportToken } from "../../state/exportToken"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { AziendaMyCalState } from "../../state/aziende"
import { isMobile } from "react-device-detect"
import { useAuthUser } from "use-eazy-auth"

const useStyles = makeStyles((theme) => ({
  myCal: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderStyle: "dashed",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    padding: 10,
  },
}))

function MyAziendaCal({ toggle }) {
  const classes = useStyles()
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [{ data: myCal }] = useRunRj(AziendaMyCalState, [selectedAzienda.id])

  function copyToClip() {
    if (typeof navigator.clipboard?.writeText === "function") {
      try {
        navigator.clipboard.writeText(myCal.url)
      } catch (err) {}
    }
  }

  return (
    <>
      <DialogTitle>iCal</DialogTitle>
      <DialogContent dividers>
        <div className={classes.myCal}>{myCal?.url ?? ""}</div>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          startIcon={<KeyboardBackspace />}
          variant="outlined"
          onClick={toggle}
        >
          Chiudi
        </Button>
        <Button
          startIcon={<FileCopy />}
          variant="outlined"
          onClick={copyToClip}
        >
          Copia
        </Button>
      </DialogActions>
    </>
  )
}

export default function Visite() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const history = useHistory()

  const [modalCal, modalCalActions] = useModalTrigger()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteVisita } = modalDelete

  const onDelete = (visita) => actionsModalDelete.open(visita)

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id }
  }, [debQueryParams, selectedAzienda])

  const [{ list, loading, pagination }, { removeVisita, addVisita }] = useRunRj(
    VisiteState,
    [filters],
    false
  )

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: clienti }] = useRunRj(ClienteBigState, [filters])

  const isProductionEnv = process.env.NODE_ENV === "production"
  const host = isProductionEnv ? window.location.host : "localhost:8000"
  const protocol = window.location.protocol

  const exportToken = useExportToken()

  const linkCsv = useMemo(() => {
    return `${protocol}//${host}/api/visite-export/csv?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
    })}`
  }, [debQueryParams, exportToken, host, protocol])

  const linkPdf = useMemo(() => {
    return `${protocol}//${host}/api/visite-export/pdf?${qs.stringify({
      ...debQueryParams,
      export_token: exportToken,
    })}`
  }, [debQueryParams, exportToken, host, protocol])

  const { user } = useAuthUser()

  const isNotEmployee = useMemo(() => {
    if (user.profilo && user.profilo.permessi !== "employee") {
      return true
    }
  }, [user.profilo])

  return (
    <Layout title="Visite">
      <div>
        <Card className="card-filters">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  style={{ width: isMobile ? "100%" : undefined }}
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <TextField
                  variant="outlined"
                  select
                  style={{ width: 320, marginTop: isMobile ? 5 : 0 }}
                  size="small"
                  label="Filtra visite"
                  value={queryParams.eseguita ?? ""}
                  name="eseguita"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.eseguita}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="start"
                        >
                          <Cancel
                            color={
                              !queryParams.eseguita ? undefined : "primary"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) =>
                    setDebQueryParams({ eseguita: event.target.value })
                  }
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value={1}>Eseguita</MenuItem>
                  <MenuItem value={0}>Non eseguita</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            {isNotEmployee && (
              <Grid md={4} xs={12}>
                <div
                  style={{ paddingTop: 7 }}
                  className={
                    isMobile
                      ? "d-flex justify-content-center pt-5"
                      : "text-right"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => modalCalActions.open()}
                    startIcon={<CalendarToday />}
                  >
                    iCAL
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    color="primary"
                    startIcon={<GetApp />}
                    href={linkCsv}
                  >
                    Excel
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    startIcon={<GetApp />}
                    href={linkPdf}
                  >
                    Pdf
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Visita
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListVisite
            columnProps={columnProps}
            visite={list}
            pagination={pagination}
            onDelete={onDelete}
          />
        )}
        <ModalFormVisita
          save={(visita) => {
            return addVisita
              .onSuccess((visita) => {
                actionsModalCreate.close()
                history.push(`/visite/${visita.id}`)
              })
              .asPromise(visita)
          }}
          isOpen={modalCreate.isOpen}
          clienti={clienti}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteVisita ? `Rimuovere visita?` : null}
          text={
            deleteVisita ? `Sei sicuro di voler eliminare la visita?` : null
          }
          onDelete={() => {
            return removeVisita
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(deleteVisita)
          }}
          onExited={actionsModalDelete.onClosed}
        />
        <Dialog
          maxWidth={false}
          open={modalCal.isOpen}
          onClose={modalCalActions.toggle}
          onExited={modalCalActions.onClosed}
        >
          <MyAziendaCal toggle={modalCalActions.toggle} />
        </Dialog>
      </div>
    </Layout>
  )
}
