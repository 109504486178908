import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import moment from "moment"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Card,
  TableContainer,
  Paper,
  CardHeader,
  CardContent,
  Button,
} from "@material-ui/core"
import { Delete, Edit, GetApp } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import Column from "../Column/Column"
import { getLabelPagamento } from "../../utils"
import { isMobile } from "react-device-detect"

export default function ListRivendite({
  rivendite,
  onDelete,
  onUpdate,
  pagination,
  showCliente = false,
  detailScheda = false,
  setPageRivendite,
  pageRivendite,
  columnProps,
  linkCsv,
  linkPdf,
  isNotEmployee,
  onDoubleClick
}) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Rivendite</div>
            {linkCsv && linkPdf && isNotEmployee && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetApp />}
                  href={linkCsv}
                >
                  Excel
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  startIcon={<GetApp />}
                  href={linkPdf}
                >
                  Pdf
                </Button>
              </div>
            )}
          </div>
        }
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {!isMobile && (
                  <Column {...columnProps} field="data">
                    Data
                  </Column>
                )}
                {showCliente && (
                  <Column {...columnProps} field="cliente__cognome">
                    Cliente
                  </Column>
                )}
                {!isMobile && (
                  <>
                    <Column {...columnProps} field="prezzo">
                      Totale
                    </Column>
                    <Column {...columnProps} field="quantita">
                      Quantità
                    </Column>
                    <Column {...columnProps} field="tipo_pagamento">
                      Tipo Pagamento
                    </Column>
                  </>
                )}
                <Column {...columnProps} field="prodotto__prodotto">
                  Prodotto
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rivendite &&
                rivendite.map((rivendita) => (
                  <TableRow key={rivendita.id} onDoubleClick={() => onDoubleClick(rivendita)} isExcluded={""+rivendita.isExcluded}>
                    {!isMobile && (
                      <TableCell>
                        {moment(rivendita.data).format("DD/MM/YYYY")}
                      </TableCell>
                    )}
                    {showCliente && (
                      <TableCell>
                        {rivendita.cliente_data
                          ? rivendita.cliente_data.nome +
                            " " +
                            rivendita.cliente_data.cognome
                          : ""}
                      </TableCell>
                    )}
                    {!isMobile && (
                      <>
                        <TableCell>{rivendita.prezzo} &euro;</TableCell>
                        <TableCell>{rivendita.quantita}</TableCell>
                        <TableCell>
                          {getLabelPagamento(rivendita.tipo_pagamento)}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {rivendita.prodotto_data
                        ? rivendita.prodotto_data.prodotto
                        : ""}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onUpdate(rivendita)}
                        aria-label="modifica"
                        title="Modifica cliente"
                        size={isMobile ? "small" : "medium"}
                      >
                        <Edit style={{ color: "orange" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => onDelete(rivendita)}
                        aria-label="elimina"
                        title="Elimina rivendita"
                      >
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={detailScheda ? pageRivendite : +debQueryParams.page || 1}
        onChange={(e, page) =>
          detailScheda ? setPageRivendite(page) : setQueryParams({ page })
        }
      />
    </Card>
  )
}
