import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import Layout from "../../components/Layout/Layout"
import ModalDelete from "../../components/Modals/ModalDelete"
import { useRunRj } from "react-rocketjump"
import {
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { AddCircle, Cancel, GetApp, RotateLeft } from "@material-ui/icons"
import ModalFormRivendita from "../../components/Rivendite/ModalFormRivendita"
import { ProdottoBigState } from "../../state/prodotti"
import { RivenditaState } from "../../state/rivendite"
import ListRivendite from "../../components/Rivendite/ListRivendite"
import { ClienteBigState } from "../../state/clienti"
import qs from "query-string"
import { useExportToken } from "../../state/exportToken"
import { useSelectedAzienda } from "../../SelectedAzienda"
import { isMobile } from "react-device-detect"
import { useAuthUser } from "use-eazy-auth"

export default function Rivendite() {
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteRivendita } = modalDelete

  const onDelete = (rivendita) => actionsModalDelete.open(rivendita)
  const onUpdate = (rivendita) => actionsModalUpdate.open(rivendita)

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id }
  }, [debQueryParams, selectedAzienda])

  const [
    { list, loading, pagination },
    { removeRivendita, addRivendita, updateRivendita },
  ] = useRunRj(RivenditaState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const onDoubleClick = (rivendita) => {
    if (rivendita.isExcluded){
      rivendita.isExcluded = false
      return updateRivendita.onSuccess(() => actionsModalUpdate.close()).asPromise({ ...rivendita })
    }else{
      rivendita.isExcluded = true
      return updateRivendita.onSuccess(() => actionsModalUpdate.close()).asPromise({ ...rivendita })
    }
  };


  const [{ list: prodotti }] = useRunRj(ProdottoBigState, [filters])
  const [{ list: clienti }] = useRunRj(ClienteBigState, [filters])

  const isProductionEnv = process.env.NODE_ENV === "production"
  const host = isProductionEnv ? window.location.host : "localhost:8000"
  const protocol = window.location.protocol

  const exportToken = useExportToken()

  const linkCsv = useMemo(() => {
    return `${protocol}//${host}/api/rivendite-export/csv?${qs.stringify({
      ...filters,
      export_token: exportToken,
    })}`
  }, [exportToken, filters, host, protocol])

  const linkPdf = useMemo(() => {
    return `${protocol}//${host}/api/rivendite-export/pdf?${qs.stringify({
      ...filters,
      export_token: exportToken,
    })}`
  }, [exportToken, filters, host, protocol])

  const { user } = useAuthUser()

  const isNotEmployee = useMemo(() => {
    if (user.profilo && user.profilo.permessi !== "employee") {
      return true
    }
  }, [user.profilo])

  return (
    <Layout title="Rivendite">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid xs={12} item md={6}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  style={{ width: isMobile ? "100%" : undefined }}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            {isNotEmployee && (
              <Grid item md={6} xs={12}>
                <div
                  className={
                    isMobile
                      ? "d-flex justify-content-center pt-5"
                      : "text-right"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetApp />}
                    href={linkCsv}
                  >
                    Excel
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    startIcon={<GetApp />}
                    href={linkPdf}
                  >
                    Pdf
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Rivendita
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListRivendite
            columnProps={columnProps}
            rivendite={list}
            showCliente
            pagination={pagination}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onDoubleClick={onDoubleClick}
          />
        )}
        <ModalFormRivendita
          save={(rivendita) => {
            return addRivendita
              .onSuccess(() => actionsModalCreate.close())
              .asPromise({ ...rivendita })
          }}
          isOpen={modalCreate.isOpen}
          prodotti={prodotti}
          clienti={clienti}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormRivendita
          save={(rivendita) => {
            return updateRivendita
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({ ...rivendita })
          }}
          isOpen={modalUpdate.isOpen}
          prodotti={prodotti}
          rivendita={modalUpdate.value}
          clienti={clienti}
          toggle={actionsModalUpdate.toggle}
          onExited={actionsModalUpdate.onClosed}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteRivendita ? `Rimuovere rivendita?` : null}
          text={
            deleteRivendita
              ? `Sei sicuro di voler eliminare la rivendita?`
              : null
          }
          onDelete={() => {
            return removeRivendita
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(deleteRivendita)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
