import { find } from "lodash-es"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useRj, useRunRj } from "react-rocketjump"
import { Redirect, useHistory } from "react-router"
import { useAuthUser } from "use-eazy-auth"
import Layout from "./components/Layout/Layout"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core"
import { AziendaAllState } from "./state/aziende"
import { Alert } from "@material-ui/lab"
import { Euro, People } from "@material-ui/icons"
import { getStripe } from "./utils"
import { OrdiniState } from "./state/ordini"
import { maxBy } from "lodash"

const AziendaContext = createContext(null)

export function useSelectedAzienda() {
  return useContext(AziendaContext)
}

function ButtonPagaOra({ selectedAzienda }) {
  const [{ data: orders }] = useRunRj(OrdiniState, [selectedAzienda.id])
  const history = useHistory()
  return (
    <>
      {orders && orders.length > 0 ? (
        <Button
          startIcon={<Euro />}
          variant="contained"
          onClick={() => {
            const orderToPay = maxBy(orders, "created_at")
            if (orderToPay.invoice_url) {
              window.location.href = orderToPay.invoice_url
            } else {
              getStripe().then((stripe) => {
                stripe.redirectToCheckout({
                  sessionId: orderToPay.checkout_session_id,
                })
              })
            }
          }}
          color="primary"
        >
          Paga ora
        </Button>
      ) : (
        <Button
          startIcon={<People />}
          variant="contained"
          onClick={() => {
            history.push("/profilo")
          }}
          color="primary"
        >
          Vai al profilo
        </Button>
      )}
    </>
  )
}

export default function SelectedAzienda({ children }) {
  const { user } = useAuthUser()
  const [{ list: aziende }, { run, updateAzienda }] = useRj(AziendaAllState)

  const [selectedAziendaId, setSelectedAziendaId] = useState(null)

  useEffect(() => {
    run
      .onSuccess((responseAziende) => {
        if (responseAziende.length) {
          let id = null
          // Session storoge fucked up on safari anonymous window.
          try {
            const data = window.sessionStorage.getItem("selectedAzienda")
            if (data) id = +data
          } catch (e) {}
          if (id && find(responseAziende, { id })) {
            setSelectedAziendaId(id)
          } else {
            setSelectedAziendaId(responseAziende[0].id)
          }
        }
      })
      .run()
  }, [run])

  const selectedAzienda = useMemo(() => {
    if (!selectedAziendaId) {
      return null
    }
    return find(aziende, { id: +selectedAziendaId })
  }, [aziende, selectedAziendaId])

  const state = useMemo(
    () => ({
      aziende,
      selectedAzienda,
    }),
    [selectedAzienda, aziende]
  )

  const setSelectedAziendaIdAndStore = useCallback((id) => {
    setSelectedAziendaId(id)
    // Session storoge fucked up on safari anonymous window.
    try {
      window.sessionStorage.setItem("selectedAzienda", id)
    } catch (e) {}
  }, [])

  const actions = useMemo(
    () => ({
      setSelectedAziendaId: setSelectedAziendaIdAndStore,
      updateAzienda,
    }),
    [setSelectedAziendaIdAndStore, updateAzienda]
  )

  const ctxValue = useMemo(() => [state, actions], [state, actions])

  if (user.profilo.permessi === "admin" && aziende && aziende.length === 0) {
    return <Redirect to="/aziende" />
  }

  if (selectedAzienda === null) {
    return <Layout />
  }

  return (
    <AziendaContext.Provider value={ctxValue}>
      {children}
    </AziendaContext.Provider>
  )
}

export function ValidAzienda({ children }) {
  const { user } = useAuthUser()
  const [{ selectedAzienda }] = useSelectedAzienda()

  const history = useHistory()

  if (user.profilo.permessi !== "admin" && !selectedAzienda.is_valida) {
    return (
      <Layout title="Licenza non attiva" isLicenzaScaduta={true}>
        <Card style={{ margin: 20 }}>
          <CardHeader
            title={
              user.profilo.permessi === "manager"
                ? "Pagamento licenza"
                : "Licenza Scaduta"
            }
          />
          <CardContent>
            <Alert variant="filled" color="error">
              {user.profilo.permessi === "employee" ? (
                <div>
                  La tua licenza non è attiva. Contatta il tuo manager per
                  continuare ad utilizzare l'applicazione Salonify.
                </div>
              ) : (
                <div>
                  La tua licenza non è attiva. Procedi al pagamento per
                  continuare ad utilizzare l'applicazione Salonify.
                </div>
              )}
            </Alert>
          </CardContent>
          {user.profilo.permessi === "manager" && (
            <CardActions
              style={{ display: "flex", justifyContent: "center", padding: 20 }}
            >
              {selectedAzienda.stripe_price_id ? (
                <ButtonPagaOra selectedAzienda={selectedAzienda} />
              ) : (
                <Button
                  startIcon={<Euro />}
                  onClick={() => history.push("/profilo")}
                  variant="contained"
                  color="primary"
                >
                  Seleziona Il tuo Abbonamento
                </Button>
              )}
            </CardActions>
          )}
        </Card>
      </Layout>
    )
  }

  return children
}
