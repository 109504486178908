import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import Layout from "../../components/Layout/Layout";
import { LinkContainer } from "react-router-bootstrap";
import { VisitaDetailState, ServizioState } from "../../state/visitaDettaglio";
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams";
import { useRunRj } from "react-rocketjump";
import { Prompt, useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import {
  DateField,
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  TimeField,
} from "../../components/Form";
import ListServizi from "../../components/VisitaDettaglio/ListServizi";
import useModalTrigger from "magik-react-hooks/useModalTrigger";
import { PrestazioneState } from "../../state/prestazioni";
import ModalFormServizio from "../../components/VisitaDettaglio/ModalFormServizio";
import ModalDelete from "../../components/Modals/ModalDelete";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import {
  AddCircle,
  Close,
  KeyboardBackspace,
  People,
  Save,
  Warning,
} from "@material-ui/icons";
import ButtonSave from "../../components/ButtonSave";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import defaultTheme from "../../theme";
import {
  fromDurationToNumber,
  onResetHackReinitializeToKeepDirtyUpdate,
} from "../../utils";
import { UtenteState } from "../../state/utenti";
import { useSelectedAzienda } from "../../SelectedAzienda";
import { isMobile } from "react-device-detect";

const VisitaSchema = Yup.object().shape({
  data: Yup.string().required("La data è obbligatoria"),
  tipo_pagamento: Yup.string().when("eseguita", {
    is: true, // alternatively: (val) => val == true
    then: Yup.string().required("Tipo pagamento obbligatorio"),
  }),
});

export default function VisitaDettaglio() {
  const { id } = useParams()
  const [{ selectedAzienda }] = useSelectedAzienda()
  const [queryParams, , debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const filterVisita = useMemo(() => {
    return { visita: id };
  }, [id]);

  const [{ data: visita }, { updateVisita, run: refreshVisita }] = useRunRj(
    VisitaDetailState,
    [id]
  )

  const [alertSuccess, setAlertSuccess] = useState();

  const [modalCreate, actionsModalCreate] = useModalTrigger();
  const [modalUpdate, actionsModalUpdate] = useModalTrigger();
  const [modalDelete, actionsModalDelete] = useModalTrigger();
  const { value: deleteServizio } = modalDelete;

  const onUpdate = (prodotto) => actionsModalUpdate.open(prodotto);
  const onDelete = (prodotto) => actionsModalDelete.open(prodotto);
  const onDoubleClick = (prodotto) => {
    if (prodotto.isExcluded){
      prodotto.isExcluded = false
      return updateServizio.onSuccess(() => {refreshVisita(id);}).asPromise({...prodotto, visita: id})
    }else{
      prodotto.isExcluded = true
      return updateServizio.onSuccess(() => {refreshVisita(id);}).asPromise({...prodotto, visita: id})
    }
  };
  // const [
  //   { list: servizi, totals },
  //   { page, ...filters },
  //   { addServizio, updateServizio, removeServizio, onFilterChange, refresh },
  // ] = useRunRj(ServizioState,[{ visita: id }])

  const [
    { list: servizi, totals },
    { addServizio, updateServizio, removeServizio },
  ] = useRunRj(ServizioState, [filterVisita], false);

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  };

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id, page_size: 100 };
  }, [debQueryParams, selectedAzienda]);

  const [{ list: prestazioni }] = useRunRj(PrestazioneState, [filters]);
  const [{ list: utenti }] = useRunRj(UtenteState, [filters]);

  const initialValues = useMemo(() => {
    if (!visita) {
      return null;
    }
    return fixInitialValuesNull({
      data: visita.data,
      ora_inizio: visita.ora_inizio
        ? moment(visita.ora_inizio, "HH:mm:ss").format("HH:mm")
        : "",
      temperatura: visita.temperatura
        ? String(visita.temperatura).replace(".", ",")
        : "",
      tipo_pagamento: visita.tipo_pagamento,
      eseguita: visita.eseguita,
      note: visita.note,
      durata: visita.durata
        ? fromDurationToNumber(visita.durata, "minutes").toString()
        : "",
    });
  }, [visita]);

  return (
    <Layout
      title={
        visita && visita.cliente_data
          ? `Visita - ${visita.cliente_data.nome} ${visita.cliente_data.cognome}`
          : "Visita"
      }
    >
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              className={isMobile ? "text-center" : undefined}
            >
              <LinkContainer to="/visite">
                <Button startIcon={<KeyboardBackspace />} variant="outlined">
                  Torna alle visite
                </Button>
              </LinkContainer>
              {visita && visita.cliente_data && (
                <LinkContainer
                  style={{ marginLeft: isMobile ? 0 : 10 }}
                  to={`/scheda-cliente/${visita.cliente}`}
                >
                  <Button
                    color={"secondary"}
                    startIcon={<People />}
                    variant="contained"
                  >
                    Scheda Cliente
                  </Button>
                </LinkContainer>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={isMobile ? "text-center pt-5" : "text-right"}
            >
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<AddCircle />}
                onClick={() => actionsModalCreate.open()}
              >
                Aggiungi servizio
              </Button>
            </Grid>
          </Grid>
        </Card>
        <div className="mt-4">
          {visita && (
            <Card>
              <CardHeader
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      Visita: {visita.cliente_data.nome}{" "}
                      {visita.cliente_data.cognome}
                    </div>
                    <div>{moment(visita.data).format("DD/MM/YYYY")}</div>
                  </div>
                }
              />
              <CardContent>
                <div className="container-fluid mt-2">
                  <Formik
                    validationSchema={VisitaSchema}
                    onReset={onResetHackReinitializeToKeepDirtyUpdate}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) =>
                      updateVisita
                        .onSuccess(() => {
                          setAlertSuccess(true);
                        })
                        .asPromise(
                          id,
                          setNullOnEmptyValues({
                            ...values,
                            durata: values.durata ? values.durata * 60 : "",
                            temperatura: values.temperatura
                              ? values.temperatura.replace(",", ".")
                              : "",
                          })
                        )
                    }
                  >
                    {({ handleSubmit, isSubmitting, values, dirty }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <Field
                                label={"Data"}
                                name="data"
                                style={{ width: "100%" }}
                                component={DateField}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                label={"Ora"}
                                style={{ width: "100%" }}
                                name="ora_inizio"
                                component={TimeField}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                label="Durata"
                                select={true}
                                component={InputField}
                                name="durata"
                              >
                                <MenuItem value="">---</MenuItem>
                                <MenuItem value="15">15 min</MenuItem>
                                <MenuItem value="30">30 min</MenuItem>
                                <MenuItem value="45">45 min</MenuItem>
                                <MenuItem value="60">1 ora</MenuItem>
                                <MenuItem value="75">1 ora 15 min</MenuItem>
                                <MenuItem value="90">1 ora 30 min</MenuItem>
                                <MenuItem value="105">1 ora 45 min</MenuItem>
                                <MenuItem value="120">2 ore</MenuItem>
                                <MenuItem value="150">2 ore 30 min</MenuItem>
                                <MenuItem value="180">3 ore</MenuItem>
                                <MenuItem value="240">4 ore</MenuItem>
                              </Field>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                label={"Temperatura corporea"}
                                name="temperatura"
                                component={InputField}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      °C
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                label="Tipo pagamento"
                                name="tipo_pagamento"
                                select={true}
                                component={InputField}
                              >
                                <MenuItem value="">---</MenuItem>
                                <MenuItem value="cash">Contanti</MenuItem>
                                <MenuItem value="bancomat">Bancomat</MenuItem>
                                <MenuItem value="credit_card">
                                  Carta credito
                                </MenuItem>
                                <MenuItem value="debit_card">
                                  Carta debito
                                </MenuItem>
                                <MenuItem value="other">Altro</MenuItem>
                              </Field>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                label="Eseguita?"
                                component={SwitchCheckboxField}
                                name="eseguita"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                label="Note"
                                component={InputField}
                                multiline
                                rows={3}
                                name="note"
                              />
                            </Grid>
                            <Grid xs={12} md={2}>
                              
                              {visita && (
                                <div
                                  style={{
                                    marginTop: 20,
                                    textAlign: "left",
                                    fontSize: 17,
                                    color: defaultTheme.palette.primary.main,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p style={{textAlign: "center"}}>Totale Servizi</p>
                                  {visita.totale ? (
                                    <Chip
                                      color="primary"
                                      label={
                                        <span>
                                          Totale:{" "}
                                          <strong
                                            style={{
                                              fontSize:
                                                visita.percentuale_sconto ===
                                                  0 ||
                                                !visita.percentuale_sconto
                                                  ? 15
                                                  : undefined,
                                            }}
                                          >
                                            {visita.totale} €
                                          </strong>
                                        </span>
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {visita.percentuale_sconto &&
                                  visita.percentuale_sconto !== 0 ? (
                                    <Chip
                                      style={{ marginTop: 5 }}
                                      size="medium"
                                      color="secondary"
                                      label={
                                        <span>
                                          Sconto:{" "}
                                          <strong>
                                            {visita.percentuale_sconto.toFixed(
                                              2
                                            )}{" "}
                                            %
                                          </strong>
                                        </span>
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {visita.percentuale_sconto &&
                                  visita.totale_con_sconto &&
                                  visita.percentuale_sconto !== 0 ? (
                                    <Chip
                                      style={{ marginTop: 5 }}
                                      color="primary"
                                      label={
                                        <span>
                                          Totale:{" "}
                                          <strong style={{ fontSize: 15 }}>
                                            {visita.totale_con_sconto} €
                                          </strong>
                                        </span>
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </Grid>
                            {parseFloat(visita.totale_without_excluded) !== parseFloat(visita.totale_con_sconto) && (<Grid xs={12} md={2}>
                              {visita && (
                                <div
                                  style={{
                                    marginTop: 20,
                                    textAlign: "left",
                                    fontSize: 17,
                                    color: defaultTheme.palette.primary.main,
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: 15
                                  }}
                                >
                                  <p style={{textAlign: "center"}}>Totale Imponibile</p>
                                  {visita.totale ? (
                                    <Chip
                                      style={{backgroundColor: defaultTheme.palette.warning.light}}
                                      label={
                                        <span>
                                          Totale:{" "}
                                          <strong
                                            style={{
                                              fontSize:
                                                visita.percentuale_sconto ===
                                                  0 ||
                                                !visita.percentuale_sconto
                                                  ? 15
                                                  : undefined,
                                            }}
                                          >
                                            {visita.totale_without_excluded} €
                                          </strong>
                                        </span>
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </Grid>)}
                            <Grid item md={12}>
                              {dirty && (
                                <Alert
                                  color="error"
                                  action={
                                    <ButtonSave
                                      startIcon={<Save />}
                                      disabled={isSubmitting || !dirty}
                                      type="submit"
                                      variant="outlined"
                                    >
                                      Salva
                                    </ButtonSave>
                                  }
                                  style={{
                                    marginBottom: 20,
                                    marginTop: 10,
                                  }}
                                  icon={<Warning />}
                                >
                                  <div>
                                    Hai modificato la visita. Ricordati di
                                    salvare per mantenere i cambiamenti.
                                  </div>
                                </Alert>
                              )}
                              {alertSuccess && !dirty && (
                                <Alert
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setAlertSuccess(false);
                                      }}
                                    >
                                      <Close fontSize="inherit" />
                                    </IconButton>
                                  }
                                  style={{ marginBottom: 20, marginTop: 10 }}
                                  color="success"
                                >
                                  Visita salvata con successo.
                                </Alert>
                              )}
                            </Grid>
                          </Grid>
                          <Prompt
                            when={dirty}
                            message={(location) =>
                              `Hai modificato la visita. Sei sicuro di voler uscire?`
                            }
                          />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
        <div className="mt-4">
          {visita && (
            <Card>
              <CardHeader
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      Servizi: {visita.cliente_data.nome}{" "}
                      {visita.cliente_data.cognome}
                    </div>
                    <div>{moment(visita.data).format("DD/MM/YYYY")}</div>
                  </div>
                }
              />
              <CardContent>
                <ListServizi
                  totals={totals}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  onDoubleClick={onDoubleClick}
                  columnProps={columnProps}
                  servizi={servizi}
                />
              </CardContent>
            </Card>
          )}
        </div>
        <ModalFormServizio
          utenti={utenti}
          save={(servizio) => {
            return addServizio
              .onSuccess(() => {
                actionsModalCreate.close();
                refreshVisita(id);
              })
              .asPromise({ ...servizio, visita: id });
          }}
          isOpen={modalCreate.isOpen}
          prestazioni={prestazioni}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormServizio
          utenti={utenti}
          save={(servizio) => {
            return updateServizio
              .onSuccess(() => {
                actionsModalUpdate.close();
                refreshVisita(id);
              })
              .asPromise({ ...servizio, visita: id });
          }}
          servizio={modalUpdate.value}
          isOpen={modalUpdate.isOpen}
          prestazioni={prestazioni}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed();
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={
            deleteServizio
              ? `Rimuovere ${deleteServizio.prestazione_data.titolo}?`
              : null
          }
          text={
            deleteServizio
              ? `Sei sicuro di voler eliminare il servizio ${deleteServizio.prestazione_data.titolo}?`
              : null
          }
          onDelete={() => {
            return removeServizio
              .onSuccess(() => {
                actionsModalDelete.close();
                refreshVisita(id);
              })
              .asPromise(modalDelete.value);
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  );
}
