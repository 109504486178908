import { loadStripe } from "@stripe/stripe-js"
import moment from "moment"

export function getLabelPagamento(pagamento) {
  if (pagamento === "bancomat") {
    return "Bancomat"
  } else if (pagamento === "credit_card") {
    return "Credit card"
  } else if (pagamento === "debit_card") {
    return "Debit card"
  } else if (pagamento === "cash") {
    return "Contanti"
  } else return "Altro"
}

export function getLabelDurata(durata) {
  if (durata === 15) {
    return "15 min"
  } else if (durata === 30) {
    return "30 min"
  } else if (durata === 45) {
    return "45 min"
  } else if (durata === 60) {
    return "1 ora"
  } else if (durata === 75) {
    return "1 ora 15 min"
  } else if (durata === 90) {
    return "1 ora 30 min"
  } else if (durata === 105) {
    return "1 ora 45 min"
  } else if (durata === 120) {
    return "2 ore"
  } else if (durata === 150) {
    return "2 ore 30 min"
  } else if (durata === 180) {
    return "3 ore"
  } else if (durata === 240) {
    return "4 ore"
  } else {
    return ""
  }
}

export function getLabelMese(mese) {
  if (mese === 1) {
    return "Gennaio"
  } else if (mese === 2) {
    return "Febbraio"
  } else if (mese === 3) {
    return "Marzo"
  } else if (mese === 4) {
    return "Aprile"
  } else if (mese === 5) {
    return "Maggio"
  } else if (mese === 6) {
    return "Giugno"
  } else if (mese === 7) {
    return "Luglio"
  } else if (mese === 8) {
    return "Agosto"
  } else if (mese === 9) {
    return "Settembre"
  } else if (mese === 10) {
    return "Ottobre"
  } else if (mese === 11) {
    return "Novembre"
  } else if (mese === 12) {
    return "Dicembre"
  }
}

export function getUserType(permesso) {
  if (permesso === "manager") {
    return "Manager"
  } else if (permesso === "employee") {
    return "Dipendente"
  } else if (permesso === "admin") {
    return "Admin"
  } else {
    return ""
  }
}

export function getStripe() {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY)
}

export function onResetHackReinitializeToKeepDirtyUpdate(values, methods) {
  // Force update of formik (in the cheapest way)
  methods.setFormikState((state) => ({ ...state }))
  // Prevent erasing values
  // https://github.com/formium/formik/blob/master/packages/formik/src/Formik.tsx#L438
  const noopPromise = new Promise(() => {})
  return noopPromise
}

export function fromDurationToNumber(durationStr, unit) {
  if (durationStr === null) {
    return null
  }
  if (unit === "minutes") {
    return parseInt(moment.duration(durationStr).asMinutes())
  } else if (unit === "days") {
    return parseInt(moment.duration(durationStr).asDays())
  } else {
    throw new Error(`Invalid unit ${unit}.`)
  }
}
