import React from "react"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"

export default function ModalCambioPiano({ isOpen, toggle, onExited, update }) {
  return (
    <Dialog open={isOpen} fullWidth={true} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>Salonify - Modifica Abbonamento</DialogTitle>
      <DialogContent dividers>
        Sei sicuro di voler modificare il tuo piano abbonamento a Salonify?
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          startIcon={<KeyboardBackspace />}
          variant="outlined"
          onClick={toggle}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          title='Cancella Abbonamento'
          color='secondary'
          size='small'
          variant="contained"
          onClick={() => {
            update()
          }}
        >
          Cambio Abbonamento
        </Button>
      </DialogActions>
    </Dialog>
  )
}
