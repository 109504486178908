import { useEffect } from "react"
import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const ExportTokenState = rj({
  name: "ExportToken",
  effectCaller: "configured",
  effect: (t) => () => api.auth(t).get(`/api/export-token/`),
})

export function useExportToken() {
  const [{ data }, { run }] = useRunRj(ExportTokenState)

  useEffect(() => {
    if (data) {
      const id = setInterval(run, 1000 * 60 * 5)
      return () => {
        clearInterval(id)
      }
    }
  }, [data, run])

  return data?.token ?? ''
}
