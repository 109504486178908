import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  InputField,
  transformErrorForForm,
} from "../Form"
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Save, KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Alert, Autocomplete } from "@material-ui/lab"
import { useMemo } from "react"
import { useAuthUser } from "use-eazy-auth"

const INITIAL_VALUES = {
  email: "",
  password: "",
  name: "",
  conferma_password: "",
  profilo: { permessi: "", aziende_visibili: [] },
}

const UtenteSchema = Yup.object().shape({
  email: Yup.string().required("Indirizzo e-mail obbligatorio"),
  name: Yup.string().required("Nome obbligatorio"),
  password: Yup.string().required("Password obbligatoria"),
  conferma_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Le due password devono essere identiche"
  ),
})

const UtenteSchemaEdit = Yup.object().shape({
  email: Yup.string().required("Indirizzo e-mail obbligatorio"),
  name: Yup.string().required("Nome obbligatorio"),
})

export default function ModalFormUtente({
  utente,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  const user = useAuthUser()

  return (
    <Dialog open={isOpen} onClose={toggle} onExited={onExited}>
      <DialogTitle onClose={toggle}>
        {utente ? "Modifica" : "Inserimento"} utente
      </DialogTitle>

      <Formik
        validationSchema={utente ? UtenteSchemaEdit : UtenteSchema}
        initialValues={
          fixInitialValuesNull(utente) ?? fixInitialValuesNull(INITIAL_VALUES)
        }
        onSubmit={(values, actions) => {
          save(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field label={"E-mail"} name="email" component={InputField} />
                </Grid>
                <Grid item xs={6}>
                  <Field label={"Nome"} name="name" component={InputField} />
                </Grid>
                {!utente && (
                  <>
                    <Grid item xs={6}>
                      <Field
                        label={"Password"}
                        name="password"
                        type="password"
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={"Conferma Password"}
                        name="conferma_password"
                        type="password"
                        component={InputField}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Field
                    label="Permessi"
                    name="profilo.permessi"
                    select={true}
                    component={InputField}
                  >
                    <MenuItem value="">---</MenuItem>
                    {user.profilo && user.profilo.permessi === "admin" && (
                      <MenuItem value="admin">Admin</MenuItem>
                    )}
                    <MenuItem value="employee">Dipendente</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  {/* <Field
                    margin="normal"
                    label="Aziende visibili"
                    name="profilo.aziende_visibili"
                    select={true}
                    component={InputField}
                  >
                    {aziende &&
                      aziende.map((azienda) => (
                        <MenuItem key={azienda.id} value={azienda.id}>
                          {azienda.rag_sociale}
                        </MenuItem>
                      ))}
                  </Field> */}
                </Grid>
              </Grid>
              {typeof errors.non_field_errors === 'string' && (
                <Alert severity='error' style={{ marginTop: 10 }}>
                  {errors.non_field_errors}
                </Alert>
              )}
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                size="small"
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                size="small"
                type="submit"
                startIcon={<Save />}
                variant="outlined"
              >
                {utente ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
