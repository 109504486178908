import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  lighten,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Clear, Delete, Edit } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"

export default function ListTemplatesNewsletters({
  newsletters,
  onDelete,
  onUpdate,
  columnProps,
  pagination,
}) {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()
  return (
    <Card>
      <CardHeader title="Templates Newletters" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ fontWeight: "bold" }}>
                <Column {...columnProps} field="titolo">
                  Titolo
                </Column>
                <TableCell>Immagine</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletters &&
                newsletters.map((newsletter) => (
                  <TableRow key={newsletter.id}>
                    <TableCell>{newsletter.titolo}</TableCell>
                    <TableCell>
                      {newsletter.immagine ? (
                        <img
                          src={newsletter.immagine}
                          height="50"
                          alt={newsletter.titolo}
                        />
                      ) : (
                        <Clear color="secondary" />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onUpdate(newsletter)}
                        aria-label="modifica"
                        title="Modifica template"
                      >
                        <Edit style={{ color: "orange" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => onDelete(newsletter)}
                        aria-label="elimina"
                        title="Elimina template"
                      >
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
