import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import Layout from "../../components/Layout/Layout"
import Button from "@material-ui/core/Button"
import ModalDelete from "../../components/Modals/ModalDelete"
import { Card, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core"
import { RotateLeft, AddCircle, Cancel } from "@material-ui/icons"
import { useRunRj } from "react-rocketjump"
import { TemplatesNewsletterState } from "../../state/templatesNewsletter"
import ListTemplatesNewsletters from "../../components/TemplatesNewsletter/ListTemplatesNewsletters"
import ModalFormTemplateNewsletter from "../../components/TemplatesNewsletter/ModalFormTemplateNewsletter"
import { isMobile } from "react-device-detect"

export default function TemplatesNewsletter() {
  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteTemplate } = modalDelete

  const onDelete = (template) => actionsModalDelete.open(template)
  const onUpdate = (template) => actionsModalUpdate.open(template)

  const [
    { list, loading, pagination },
    { removeTemplate, addTemplate, updateTemplate },
  ] = useRunRj(TemplatesNewsletterState, [debQueryParams], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  return (
    <Layout title="Templates Newsletters">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid xs={12} md={12}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? '100%' : undefined}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-add">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => actionsModalCreate.open()}
          >
            Aggiungi Template
          </Button>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListTemplatesNewsletters
            pagination={pagination}
            columnProps={columnProps}
            newsletters={list}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        )}
        <ModalFormTemplateNewsletter
          save={(template) => {
            return updateTemplate
              .onSuccess((template) => {
                actionsModalUpdate.close()
              })
              .asPromise(template)
          }}
          isOpen={modalUpdate.isOpen}
          template={modalUpdate.value}
          toggle={actionsModalUpdate.toggle}
          onExited={actionsModalUpdate.onClosed}
        />
        <ModalFormTemplateNewsletter
          save={(template) => {
            return addTemplate
              .onSuccess((template) => {
                actionsModalCreate.close()
              })
              .asPromise(template)
          }}
          isOpen={modalCreate.isOpen}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteTemplate ? `Rimuovere ${deleteTemplate.titolo}?` : null}
          text={
            deleteTemplate
              ? `Sei sicuro di voler eliminare l'azienda ${deleteTemplate.titolo}?`
              : null
          }
          onDelete={() => {
            return removeTemplate
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
