import { rjPlugin, SUCCESS } from 'react-rocketjump'
import get from 'lodash/get'

export function rjListWithTotals() {
    return rjPlugin({
      composeReducer: (prevState, { type, payload }) => {
        if (type === SUCCESS) {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              totals: payload.data.totals,
            }
          }
        }
        return prevState
      },
      selectors: () => ({
        getTotals: state => get(state.root, 'data.totals', null),
      }),
    })
  }