import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE, PAGE_SIZE_BIG } from "../consts"

export const ClienteDetailState = rj({
  name: "ClienteDetailState",
  effectCaller: "configured",
  mutations: {
    updateTagsCliente: {
      effect: (t) => (id, entity) =>
        api.auth(t).put(`/api/clienti/${id}/set-tags`, entity),
      updater: (state, data) => {
        return {
          ...state,
         data
        }
      },
    },
    updateCliente: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/clienti/${entity.id}/`, entity),
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/clienti/${id}`),
})

export const ClienteBigState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE_BIG,
  }),
  {
    name: "ClienteBigState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/clienti-autocomplete", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const ClienteState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeCliente: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/clienti/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addCliente: {
        effect: (t) => (entity) => api.auth(t).post("/api/clienti", entity),
        updater: "insertItem",
      },
      updateCliente: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/clienti/${entity.id}/`, entity),
        updater: "updateItem",
      },
      updateTagsCliente: {
        effect: (t) => (id, entity) =>
          api.auth(t).put(`/api/clienti/${id}/set-tags`, entity),
        updater: "updateItem",
      },
    },
    name: "ClienteState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/clienti", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const ClienteStatisticheState = rj({
  name: "ClienteStatisticheState",
  effectCaller: "configured",
  mutations: {
    // updateVisita: {
    //   effect: (t) => (id, entity) => api.auth(t).put(`/api/aziende/${id}/statistiche`, entity),
    //   updater: "updateData",
    // },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/clienti/${id}/statistiche`),
})

export const TagsClienteState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    updateTags: {
      effect: (t) => (id, tags) => api.auth(t).put(`/api/clienti/${id}/set-tags`, tags),
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/clienti/${id}`),
})
