import React, { useMemo } from "react"
import { useRunRj } from "react-rocketjump"
import {
  Card,
  Grid,
  lighten,
  makeStyles,
  CircularProgress,
  CardHeader,
  CardContent,
} from "@material-ui/core"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Layout from "../../components/Layout/Layout"
import { AziendaStatisticaState } from "../../state/aziende"
import { ClienteState } from "../../state/clienti"
import ListClienti from "../../components/Clienti/ListClienti"
import { ProdottoState } from "../../state/prodotti"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import ListProdotti from "../../components/Prodotti/ListProdotti"
import ModalFormCliente from "../../components/Clienti/ModalFormCliente"
import ModalDelete from "../../components/Modals/ModalDelete"
import ModalFormProdotto from "../../components/Prodotti/ModalFormProdotto"
import ModalFormAssociaTags from "../../components/Clienti/ModalFormAssociaTags"
import { TagState } from "../../state/tags"
import { useAuthUser } from "use-eazy-auth"
import { useSelectedAzienda } from "../../SelectedAzienda"
import ChartPagamenti from "../../components/ChartPagamenti"
import ChartRicavoAnnuale from "../../components/ChartRicavoAnnuale"
import { isMobile } from "react-device-detect"


export default function Home() {

  const [
    queryParams,
    setQueryParams,
    debQueryParams,
    setDebQueryParams,
  ] = useDebounceQueryParams()

  const { user } = useAuthUser()

  const [{ selectedAzienda }] = useSelectedAzienda()

  const [{ data: aziendaStat }] = useRunRj(AziendaStatisticaState, [
    selectedAzienda.id,
  ])

  const filters = useMemo(() => {
    return { ...debQueryParams, azienda: selectedAzienda.id }
  }, [debQueryParams, selectedAzienda.id])

  const filterAzienda = useMemo(() => {
    return { azienda: selectedAzienda.id }
  }, [selectedAzienda.id])

  const [
    { list: clienti, loading: loadingClienti, pagination },
    { updateCliente, removeCliente, updateTagsCliente },
  ] = useRunRj(ClienteState, [filters], false)

  const [modalUpdateCliente, actionsModalUpdateCliente] = useModalTrigger()
  const [modalDeleteCliente, actionsModalDeleteCliente] = useModalTrigger()
  const { value: deleteCliente } = modalDeleteCliente

  const [modalUpdateProdotto, actionsModalUpdateProdotto] = useModalTrigger()
  const [modalDeleteProdotto, actionsModalDeleteProdotto] = useModalTrigger()
  const [modalAssociaTags, actionsModalAssociaTags] = useModalTrigger()
  const { value: deleteProdotto } = modalDeleteProdotto

  const onUpdateCliente = (cliente) => actionsModalUpdateCliente.open(cliente)
  const onDeleteCliente = (cliente) => actionsModalDeleteCliente.open(cliente)
  const onAssocia = (cliente) => actionsModalAssociaTags.open(cliente)

  const onUpdateProdotto = (prodotto) =>
    actionsModalUpdateProdotto.open(prodotto)
  const onDeleteProdotto = (prodotto) =>
    actionsModalDeleteProdotto.open(prodotto)

  const [
    {
      list: prodotti,
      loading: loadingProdotti,
      pagination: paginationProdotti,
    },
    { updateProdotto, removeProdotto },
  ] = useRunRj(ProdottoState, [filters], false)

  const columnPropsCliente = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const columnPropsProdotto = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: tags }] = useRunRj(TagState, [filterAzienda])

  return (
    <Layout>
      <div>
        {user.profilo && user.profilo.permessi !== "employee" && (
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <Card style={{ minHeight: 240 }}>
                <CardHeader title="Ricavi per tipologia pagamento" />
                <CardContent>
                  {aziendaStat === null && !selectedAzienda && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </div>
                  )}
                  {selectedAzienda && aziendaStat && (
                    <ChartPagamenti
                      data={aziendaStat?.totali_tipo_pagamento_visite}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{ minHeight: 240 }}
              >
                <CardHeader title="Ricavi anno corrente" />
                <CardContent>
                  {selectedAzienda && aziendaStat && (
                    <ChartRicavoAnnuale
                    data={aziendaStat?.totali_mese_visite.filter(a => a.data__year[0] === new Date().getFullYear())}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {clienti === null && loadingClienti && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {clienti && (
          <ListClienti
            pagination={pagination}
            columnProps={columnPropsCliente}
            clienti={clienti}
            onUpdate={onUpdateCliente}
            onDelete={onDeleteCliente}
            onAssocia={onAssocia}
          />
        )}
        {prodotti === null && loadingProdotti && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {prodotti && (
          <ListProdotti
            columnProps={columnPropsProdotto}
            pagination={paginationProdotti}
            prodotti={prodotti}
            onUpdate={onUpdateProdotto}
            onDelete={onDeleteProdotto}
          />
        )}
        <ModalFormCliente
          save={(cliente) => {
            return updateCliente
              .onSuccess(() => actionsModalUpdateCliente.close())
              .asPromise(cliente)
          }}
          cliente={modalUpdateCliente.value}
          isOpen={modalUpdateCliente.isOpen}
          toggle={actionsModalUpdateCliente.toggle}
          onExited={() => {
            actionsModalUpdateCliente.onClosed()
          }}
        />
        <ModalFormAssociaTags
          save={(tags) => {
            return updateTagsCliente
              .onSuccess(() => {
                actionsModalAssociaTags.close()
              })
              .asPromise(modalAssociaTags.value.id, tags)
          }}
          isOpen={modalAssociaTags.isOpen}
          tags={tags}
          cliente={modalAssociaTags.value}
          toggle={actionsModalAssociaTags.toggle}
          onExited={() => {
            actionsModalAssociaTags.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDeleteCliente.isOpen}
          toggle={actionsModalDeleteCliente.toggle}
          title={
            deleteCliente
              ? `Rimuovere ${deleteCliente.nome} ${deleteCliente.cognome}?`
              : null
          }
          text={
            deleteCliente
              ? `Sei sicuro di voler eliminare il cliente ${deleteCliente.nome} ${deleteCliente.cognome}?`
              : null
          }
          onDelete={() => {
            return removeCliente
              .onSuccess(() => actionsModalDeleteCliente.close())
              .asPromise(modalDeleteCliente.value)
          }}
          onExited={actionsModalDeleteCliente.onClosed}
        />
        <ModalFormProdotto
          save={(prodotto) => {
            return updateProdotto
              .onSuccess(() => actionsModalUpdateProdotto.close())
              .asPromise(prodotto)
          }}
          prodotto={modalUpdateProdotto.value}
          isOpen={modalUpdateProdotto.isOpen}
          toggle={actionsModalUpdateProdotto.toggle}
          onExited={() => {
            actionsModalUpdateProdotto.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDeleteProdotto.isOpen}
          toggle={actionsModalDeleteProdotto.toggle}
          title={
            deleteProdotto ? `Rimuovere ${deleteProdotto.prodotto}?` : null
          }
          text={
            deleteProdotto
              ? `Sei sicuro di voler eliminare il prodotto ${deleteProdotto.prodotto}?`
              : null
          }
          onDelete={() => {
            return removeProdotto
              .onSuccess(() => actionsModalDeleteProdotto.close())
              .asPromise(modalDeleteProdotto.value)
          }}
          onExited={actionsModalDeleteProdotto.onClosed}
        />
      </div>
    </Layout>
  )
}
