import { scaleLinear } from "d3-scale"
import { chain, sumBy } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import defaultTheme from "../theme"
import { getLabelPagamento } from "../utils"
import { useResizeDetector } from "react-resize-detector"

const padding = {
  top: 20,
  left: 38,
  right: 38,
  bottom: 40,
}

const height = 250

const barWidthPercent = 0.5

function getTypeLabel(value, max) {
  if (max > 3000) {
    return (value / 1000).toFixed(1) + "k"
  } else return value.toFixed(0) + " €"
}

function topRoundedRect(x, y, width, height, radius) {
  return (
    "M" +
    x +
    "," +
    y +
    "v" +
    (radius - height) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    -radius +
    "h" +
    (width - 2 * radius) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    radius +
    "v" +
    (height - radius) +
    "z"
  )
}

function topRoundedRectBorder(x, y, width, height, radius) {
  return (
    "M" +
    x +
    "," +
    y +
    "v" +
    (radius - height) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    -radius +
    "h" +
    (width - 2 * radius) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    radius +
    "v" +
    (height - radius)
  )
}

function ChartPagamentiImpl({ data: rawData }) {
  const dataWithPayment = useMemo(() => {
    return chain(rawData)
      .filter((d) => d["tipo_pagamento"] && d["tipo_pagamento"] !== "other")
      .groupBy("tipo_pagamento")
      .mapValues((records) => ({
        x: records[0].tipo_pagamento,
        incasso: records.reduce((acc, curr) => acc + curr.tot, 0),
      }))
      .values()
      .value()
  }, [rawData])

  const dataOther = useMemo(() => {
    return chain(rawData)
      .filter((d) => !d["tipo_pagamento"] || d["tipo_pagamento"] === "other")
      .groupBy("tipo_pagamento")
      .mapValues((records) => ({
        x: records[0].tipo_pagamento,
        incasso: records.reduce((acc, curr) => acc + curr.tot, 0),
      }))
      .values()
      .value()
  }, [rawData])

  const otherValue = sumBy(dataOther, "incasso")

  const data = otherValue !== 0 ? dataWithPayment.concat({ x: "other", incasso: otherValue }) : dataWithPayment

  const { width, ref } = useResizeDetector()

  const chartViewportWidth = width - padding.left - padding.right
  const chartViewportHeight = height - padding.top - padding.bottom

  const xScale = scaleLinear()
    .domain([0, data.length])
    .range([padding.left, chartViewportWidth + padding.left])

  const earningScale = scaleLinear()
    .domain([0, Math.max(...data.map((item) => item.incasso))])
    .nice(6)
    .range([chartViewportHeight + padding.top, padding.top])

  const maxIncasso = Math.max(...data.map((item) => item.incasso))

  const barSizeWithGaps = xScale(1)
  const barSize = barSizeWithGaps * barWidthPercent

  const [tooltip, setTooltip] = useState({
    point: -1,
    visible: false,
    x: 0,
    y: 0,
  })

  const onMouseMove = useCallback(
    (e) => {
      const x = e.nativeEvent.offsetX
      const y = e.nativeEvent.offsetY
      const point = Math.floor(xScale.invert(x))
      if (point >= 0 && point < data.length) {
        setTooltip({
          visible: true,
          point,
          x,
          y,
        })
      } else {
        setTooltip({
          visible: false,
          point: -1,
          x,
          y,
        })
      }
    },
    [xScale, data]
  )

  const onMouseLeave = useCallback((e) => {
    if (e.target.nodeName.toUpperCase() === "SVG") {
      setTooltip({
        point: -1,
        visible: false,
        x: 0,
        y: 0,
      })
    }
  }, [])

  const tooltipFormatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  })

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <svg
        width={width}
        height={height}
        onMouseOut={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0"
              style={{
                stopColor: defaultTheme.palette.secondary.main,
                stopOpacity: 0.4,
              }}
            />
            <stop
              offset="1"
              style={{
                stopColor: defaultTheme.palette.secondary.dark,
                stopOpacity: 0.2,
              }}
            />
          </linearGradient>
        </defs>
        {earningScale.ticks(6).map((value) => (
          <React.Fragment key={value}>
            <text
              x={0}
              y={earningScale(value)}
              fill={"#000"}
              textAnchor="start"
              alignmentBaseline="central"
            >
              {getTypeLabel(value, maxIncasso)}
            </text>
            <line
              x1={padding.left}
              x2={padding.left + chartViewportWidth}
              y1={earningScale(value)}
              y2={earningScale(value)}
              stroke={"#EEEEEE"}
              strokeWidth={1}
            />
          </React.Fragment>
        ))}
        {data.map((datum, i) => {
          const barTop = earningScale(datum.incasso)
          const barHeight = padding.top + chartViewportHeight - barTop
          let label = getLabelPagamento(datum.x)
          if (label.includes(" ")) {
            label = [
              label.substring(0, label.lastIndexOf(" ")),
              label.substring(label.lastIndexOf(" ") + 1),
            ]
          } else {
            label = [label]
          }
          return (
            <React.Fragment key={i}>
              {label.map((labelPart, j) => (
                <text
                  key={j}
                  x={xScale(i + 0.5)}
                  y={padding.top + chartViewportHeight + 7 + 14 * j}
                  fill={defaultTheme.palette.primary.main}
                  fontWeight="bold"
                  textAnchor="middle"
                  alignmentBaseline="hanging"
                >
                  {labelPart}
                </text>
              ))}
              <path
                d={topRoundedRect(
                  xScale(i + 0.5) - barSize / 2,
                  barTop + barHeight,
                  barSize,
                  barHeight,
                  6
                )}
                // stroke={defaultTheme.palette.primary.main}
                // strokeWidth={2}
                fill="url(#grad)"
              />
              <path
                d={topRoundedRectBorder(
                  xScale(i + 0.5) - barSize / 2,
                  barTop + barHeight,
                  barSize,
                  barHeight,
                  6
                )}
                stroke={defaultTheme.palette.secondary.main}
                strokeWidth={2}
                fill="transparent"
              />
            </React.Fragment>
          )
        })}
      </svg>
      <div
        style={{
          position: "absolute",
          top: tooltip.y < height / 2 ? tooltip.y + 10 : tooltip.y - 90,
          left: tooltip.x < width / 2 ? tooltip.x + 10 : tooltip.x - 150,
          display: tooltip.visible ? "block" : "none",
          background: "white",
          border: "1px solid #cccccc",
          padding: 10,
          pointerEvents: "none",
          transition: "top 100ms, left 100ms",
        }}
      >
        {tooltip.point >= 0 && tooltip.point < data.length && (
          <>
            <b>{getLabelPagamento(data[tooltip.point].x)}</b>
            <br />
            <span>Incasso: </span>
            <span>{tooltipFormatter.format(data[tooltip.point].incasso)}</span>
            <br />
          </>
        )}
      </div>
    </div>
  )
}

export default function ChartPagamenti({ data, ...props }) {
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        Non hai abbastanza dati per visualizzare il grafico.
      </div>
    )
  } else {
    return <ChartPagamentiImpl data={data} {...props} />
  }
}
