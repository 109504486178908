import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  ImageField,
  InputField,
  RichTextNewsletterField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import "./ModalFormTemplateNewsletter.css"

const TemplateSchema = Yup.object().shape({
  titolo: Yup.string().required("Titolo è obbligatorio"),
})

const INITIAL_VALUES = {
  titolo: "",
  testo: "",
  immagine: "",
}

export default function ModalFormTemplateNewsletter({
  save,
  isOpen,
  toggle,
  onExited,
  template,
}) {
  return (
    <Dialog
      maxWidth={"md"}
      open={isOpen}
      fullWidth
      onClose={toggle}
      onExited={onExited}
    >
      <DialogTitle onClose={toggle}>
        {template ? "Modifica" : "Inserimento"} template newsletter
      </DialogTitle>
      <Formik
        validationSchema={TemplateSchema}
        initialValues={fixInitialValuesNull(template) ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers style={{ minHeight: 420 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    label={"Titolo"}
                    name="titolo"
                    component={InputField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Field
                    className="TemplateRichText"
                    name="testo"
                    component={RichTextNewsletterField}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    label={"Immagine"}
                    variant='outlined'
                    name="immagine"
                    component={ImageField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {template ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
